import { useEffect, useState } from 'react';
import { withTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoader } from 'modules/users/selectors';
import { selectOrganizationId, selectAuthUser } from 'modules/common/auth/selectors';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { Loader } from 'modules/common/components';
import { isEmpty } from 'modules/common/helpers/object';
import { selectHotelGroups, selectHotels } from 'modules/hotel-set/selectors';
import { CardContent, Grid, Card, Typography, Button } from '@mui/material';
import AddHotelGroup from '../add-hotel-group-view';
import { hotelSetActions } from '../../slice';
import { getHotelGroupFocusOnValue } from '../../functions';
import HotelSetTable from '../hotel-set-table';

const HotelSetView = ({ theme }) => {
  const dispatch = useDispatch();
  //
  const loading = useSelector(selectLoader);
  const organizationId = useSelector(selectOrganizationId);
  const hotelList = useSelector(selectHotels);
  const authUser = useSelector(selectAuthUser);
  // const selectedHotelName = useSelector(selectHotelName);
  const hotelGroups = useSelector(selectHotelGroups);
  //
  const [open, setOpen] = useState(false);
  const [selectedHotelName, setSelectedHotelName] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  //
  useEffect(() => {
    if (authUser && organizationId) {
      const query = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}`;
      dispatch(
        hotelSetActions.getHotelGroupList({
          organizationId,
          query,
        })
      );
      dispatch(
        hotelSetActions.getHotels({
          organizationId,
          query,
        })
      );
      const query2 = `limit=${PAGINATION.AGGREGATION_FILTER_PER_PAGE}`;
      dispatch(
        hotelSetActions.getLoadHotelGroupList({
          organizationId,
          query: query2,
        })
      );
    }
  }, [authUser, organizationId]);
  //
  useEffect(() => {
    if (hotelList?.length > 0 && !isEmpty(hotelGroups)) {
      const hotelGroupDropdownList = getHotelGroupFocusOnValue(hotelGroups, hotelList);
      dispatch(hotelSetActions.setHotelGroupDropdownList(hotelGroupDropdownList));
    }
  }, [hotelList, hotelGroups]);
  //
  return (
    <Loader loading={loading}>
      <Card>
        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
          <Grid container direction="row" justifyContent="space-between" pb={4}>
            <Typography variant="h3">Configure Hotel Sets</Typography>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Add Hotel Set
              </Button>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} justifyContent="space-between">
            <Grid item sx={{ flexGrow: 1, flexBasis: 1 }}>
              {!isEmpty(hotelGroups) && (
                <HotelSetTable
                  setIsEditable={setIsEditable}
                  setSelectedHotelName={setSelectedHotelName}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <AddHotelGroup
        open={open || isEditable}
        onClose={() => setOpen(false)}
        hotelList={hotelList ?? []}
        selectedHotelGroupName={selectedHotelName}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
      />
    </Loader>
  );
};
//
export default withTheme(HotelSetView);
