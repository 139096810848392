/**
 * Hotel feature related request structures are defined here
 */
const API = {
  GET_HOTELS: {
    path: '/organizations/:organizationId/hotels?:query',
    method: 'GET',
  },
  GET_CURRENCY_DATA: {
    path: '/organizations/:organizationId/currency?:query',
    method: 'GET',
  },
  POST_HOTEL: {
    path: '/organizations/:organizationId/hotels',
    method: 'POST',
  },
  DELETE_HOTEL: {
    path: '/organizations/:organizationId/hotels/:hotelId',
    method: 'DELETE',
  },
  PATCH_HOTEL: {
    path: '/organizations/:organizationId/hotels/:hotelId',
    method: 'PATCH',
  },
  GET_HOTEL_USER_DATA: {
    path: '/organizations/:organizationId/hotels/:hotelId/users?:query',
    method: 'GET',
  },
};
//
export default API;
