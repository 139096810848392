import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
/**
 * Getting entire user invites state into the component
 */
const selectDomain = (state) => state['feature/user-invites'] || initialState;
/**
 * Getting the loader state into the component
 */
export const selectLoader = createSelector(
  [selectDomain],
  (userInvitesState) => userInvitesState?.loading
);

/**
 * Getting user invite list list state into the component
 */
export const selectUserInvitesList = createSelector(
  [selectDomain],
  (userInvitesState) => userInvitesState?.userInvitesList
);
/**
 * Getting the users list state into the component
 */
export const selectUsers = createSelector(
  [selectDomain],
  (userInvitesState) => userInvitesState?.usersList
);
/**
 * Getting the roles list state into the component
 */
export const selectRoleList = createSelector(
  [selectDomain],
  (userInvitesState) => userInvitesState?.roleList
);
