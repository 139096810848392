/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'modules/common/helpers/object';
import { signInActions } from '../sign-in/slice';

/**
 * Define the the users feature initial state
 */
export const initialState = {
  loading: false,
  hotels: [],
  hotelGroups: {},
  hotelGroupsList: [],
  hotelGroupsDropdownList: [],
  existedHotelGroup: null,
  hotelGroupName: '',
  hotelGroupQuery: '',
  enableHotelGroups: false,
  isConfigurationNotExist: false,
  hotelName: null,
};
/**
 * Here mentioned all the users feature related actions
 */
export const hotelSetSlice = createSlice({
  name: 'feature/hotel-set',
  initialState,
  reducers: {
    getHotels(state) {
      state.loading = true;
    },
    getHotelsSucceeded(state, action) {
      state.loading = false;
      state.hotels = action?.payload?.results?.map((data) => ({
        label: data?.name,
        // eslint-disable-next-line no-underscore-dangle
        id: data?._id,
        pmsDate: data?.pmsStartDate,
        altName: data?.displayName,
        databricksId: data?.databricksHotelId,
        isFavorite: data?.isFavorite ?? false,
        hotelClassification: data?.hotelClassification,
        hotelType: data?.hotelType,
        chainName: data?.chainName,
        city: data?.city,
        state: data?.state,
        country: data?.country,
        hotel: data?.name,
      }));
    },
    setHotelName(state, action) {
      state.hotelName = action?.payload;
    },
    getHotelsFailed(state) {
      state.loading = false;
    },
    getHotelGroupList(state) {
      state.loading = true;
    },
    getHotelGroupListSucceeded(state, action) {
      state.loading = false;
      state.hotelGroups = action?.payload;
    },
    getHotelGroupListFailed(state, action) {
      state.loading = false;
      if (action?.payload?.message === 'Configuration not found') {
        state.isConfigurationNotExist = true;
      }
    },
    resetConfigurationStatus(state) {
      state.isConfigurationNotExist = false;
    },
    setHotelGroupDropdownList(state, action) {
      state.hotelGroupsDropdownList = action?.payload;
    },
    getLoadHotelGroupList(state) {
      state.loading = false;
    },
    getLoadHotelGroupListSucceeded(state, action) {
      state.loading = false;
      state.hotelGroupsList = action?.payload;
    },
    getLoadHotelGroupListFailed(state) {
      state.loading = false;
    },
    createHotelGroup(state) {
      state.loading = true;
    },
    createHotelGroupSucceeded(state, action) {
      state.loading = false;
      state.hotelGroupsList.results = state?.hotelGroupsList?.results?.map((object) => {
        const updatedGroup = action?.payload?.results?.find(
          (updatedObject) =>
            updatedObject?.hotelGroups?.hotelGroupName === object?.hotelGroups?.hotelGroupName
        );
        if (updatedGroup) return updatedGroup;
        return object;
      });
      state.hotelGroups.results = state?.hotelGroups?.results?.map((object) => {
        const updatedGroup = action?.payload?.results?.find(
          (updatedObject) =>
            updatedObject?.hotelGroups?.hotelGroupName === object?.hotelGroups?.hotelGroupName
        );
        if (updatedGroup) {
          return updatedGroup;
        }
        return object;
      });

      // Add new groups if not found in the state
      const existingGroupNames = state.hotelGroups?.results?.map(
        (obj) => obj?.hotelGroups?.hotelGroupName
      );
      action?.payload?.results?.forEach((updatedObject) => {
        if (!existingGroupNames?.includes(updatedObject?.hotelGroups?.hotelGroupName)) {
          state?.hotelGroups?.results?.push(updatedObject);
        }
      });
    },
    createHotelGroupFailed(state) {
      state.loading = false;
    },
    getHotelGroupNameValidation(state) {
      state.loading = true;
    },
    getHotelGroupNameValidationSucceeded(state, action) {
      state.loading = false;
      state.existedHotelGroup = action?.payload;
    },
    getHotelGroupNameValidationFailed(state) {
      state.loading = false;
    },
    resetHotelGroupNameValidationStatus(state) {
      state.existedHotelGroup = null;
    },
    setSelectedHotelGroup(state, action) {
      if (state && state.hotelName && 'hotels' in state.hotelName) {
        if (!isEmpty(state.hotelName) && action?.payload?.hotelGroupDropdownList?.length === 0) {
          state.hotelName = action?.payload?.hotelList[0];
        } else if (state.hotelName && action?.payload?.hotelGroupDropdownList?.length > 0) {
          const newGroup = action?.payload?.hotelGroupDropdownList?.find(
            (a) => a?.id === state.hotelName?.id
          );
          if (newGroup) {
            state.hotelName = newGroup;
          } else {
            state.hotelName = action?.payload?.hotelList[0];
          }
        } else {
          state.hotelName = action?.payload?.hotelList[0];
        }
      }
    },
    setSelectedHotelGroupName(state, action) {
      state.hotelGroupName = action.payload;
    },
    deleteHotelGroup(state) {
      state.loading = true;
    },
    deleteHotelGroupSucceeded(state, action) {
      state.loading = false;
      const [, hotelGroupName] = action.payload.query.split('=');
      state.hotelGroupsList.results = state?.hotelGroupsList?.results?.filter(
        (object) => object?.hotelGroups?.hotelGroupName !== hotelGroupName
      );
      state.hotelGroups.results = state?.hotelGroups?.results?.filter(
        (object) => object?.hotelGroups?.hotelGroupName !== hotelGroupName
      );
    },
    deleteHotelGroupFailed(state) {
      state.loading = false;
    },
    setHotelGroupQuery(state, action) {
      state.hotelGroupQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});
//
export const { actions: hotelSetActions } = hotelSetSlice;
