import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio } from '@mui/material';
import { CustomStack } from 'modules/common/components';
import BookingDatePicker from 'modules/transactions/components/inner-filter/components/booking-date-picker';
import styles from 'modules/transactions/components/inner-filter/inner-filter.module.scss';
/**
 * Booking window component common for the dashboard component
 * @param {Boolean} vOTB - State to enable custom booking
 * @param {Boolean} customBooking - State to enable custom booking
 * @param {Boolean} handleOTBChange - Function to call when the radio button selection changes
 * @param {Date} latestDate - Latest date value
 * @param {*} otbValue - OTB value
 * @returns
 */
const BookingWindow = ({ vOTB, customBooking, handleOTBChange, latestDate, otbValue, id = '' }) => (
  <CustomStack cssClassName={styles.innerFilter}>
    <FormControl>
      <FormLabel>Booking Window</FormLabel>
      <RadioGroup value={vOTB} name="radio-buttons-group" onChange={handleOTBChange}>
        <FormControlLabel
          sx={{
            my: -1,
          }}
          value={otbValue}
          control={<Radio />}
          label="OTB"
          id={`${id}-otb`}
        />
        <FormControlLabel
          sx={{
            my: -1,
          }}
          value
          control={<Radio />}
          label="Custom Booking Window"
          id={`${id}-custom-window`}
        />
      </RadioGroup>
    </FormControl>
    {customBooking && <BookingDatePicker latestDate={latestDate} id={`${id}-date-picker`} />}
  </CustomStack>
);
//
export default BookingWindow;
