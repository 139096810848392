import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { transactionActions } from 'modules/transactions/slice';
import {
  selectBreakdown,
  selectTransactionOtb,
  selectIsDetailed,
  selectTransactionSegmentFocusOn,
  selectVisualFilters,
  selectBreakdownDetails,
  selectAllHotelList,
  selectIsDeAggregate,
} from 'modules/transactions/selectors';
import { getSelectedBreakdown } from 'modules/transactions/functions';
import {
  RESET_BOOKING_DATE,
  SUMMARY_DATE_SELECTION,
  TAGS,
  TRANSACTION_STAY_DATE,
} from 'modules/transactions/constants';
import {
  getFocusOnFilter,
  getSubBreakdownFilter,
} from 'modules/transactions/components/inner-filter/functions';
import styles from 'modules/transactions/components/inner-filter/inner-filter.module.scss';
import { CustomStack } from '../../../common/components';
import ActionButton from '../../../common/components/action-button';
import { BookingWindow, BreakdownWindow } from '../inner-filter';
import DeAggregateToggle from '../inner-filter/components/de-aggegrated-toggle';

/**
 * Filters for Transaction widget
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @returns
 */
const TransactionFilter = ({ latestDate }) => {
  const dispatch = useDispatch();
  // Selected filter values are obtained from redux store. Refer each selector function for details.
  const chartBreakdown = useSelector(selectBreakdown);
  const visualFilters = useSelector(selectVisualFilters);
  const focusOn = useSelector(selectTransactionSegmentFocusOn);
  const customBooking = useSelector(selectTransactionOtb);
  const isDetailed = useSelector(selectIsDetailed);
  const breakdownData = useSelector(selectBreakdownDetails);
  const allHotels = useSelector(selectAllHotelList);
  const isDeAggregate = useSelector(selectIsDeAggregate);
  //
  const [chartBreakdownList, setChartBreakdownList] = useState([]);
  const [focusOnList, setFocusOnList] = useState([]);
  const [selectedBreakdown, setSelectedBreakdown] = useState();

  // To select otb or custom booking window
  const handleOTBChange = (event) => {
    const { value } = event.target;
    dispatch(transactionActions.setTransactionOtb(value !== 'false'));
    dispatch(transactionActions.setTransactionBookingDate(RESET_BOOKING_DATE));
  };

  // Triggered when visualFilters value changes
  useEffect(() => {
    // Set pace filter list and breakdown list
    const subBreakdownFilter = getSubBreakdownFilter(visualFilters);
    if (subBreakdownFilter) {
      setChartBreakdownList(subBreakdownFilter);
    }
  }, [visualFilters]);

  // Triggered when the values of isDetailed, chartBreakdown or visualFilters change
  useEffect(() => {
    // Assign values to selected breakdown on chart breakdown or isDetails toggle change
    const breakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    setSelectedBreakdown(breakdown);
  }, [isDetailed, chartBreakdown, visualFilters]);
  //
  useEffect(() => {
    // set breakdown focus on filter list
    if (chartBreakdown) {
      const focusOnFilter = getFocusOnFilter(breakdownData, selectedBreakdown);
      setFocusOnList(focusOnFilter);
    }
  }, [chartBreakdown, visualFilters, breakdownData, selectedBreakdown]);

  // Reset button function to set filter panel values to default
  const clearSearch = () => {
    dispatch(transactionActions.setTransactionBookingDate(RESET_BOOKING_DATE));
    dispatch(transactionActions.setTransactionOtb(false));
    dispatch(transactionActions.setTransactionDateType(SUMMARY_DATE_SELECTION[0]));
    dispatch(
      transactionActions.setBreakdown(
        visualFilters?.filter(
          (filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN) && filter?.isDefault === true
        )[0]?.id
      )
    );
    dispatch(transactionActions.setTransactionSegmentFocusOn([]));
    dispatch(transactionActions.setIsDetailed(false));
    const allHotelObject = {};
    Object.keys(allHotels[0]).forEach((key) => {
      allHotelObject[key] = 'All';
    });
    dispatch(transactionActions.setHotelName(allHotels[0]));
    dispatch(transactionActions.setTargetDate(TRANSACTION_STAY_DATE));
    dispatch(transactionActions.setIsDeAggregate(false));
  };
  //
  return (
    <>
      <DeAggregateToggle
        value={isDeAggregate}
        onChange={(event) => dispatch(transactionActions.setIsDeAggregate(event.target.checked))}
      />
      <br />
      <BookingWindow
        vOTB={customBooking}
        customBooking={customBooking}
        handleOTBChange={handleOTBChange}
        latestDate={latestDate}
        otbValue={false}
      />
      <br />
      <BreakdownWindow
        isDetailed={isDetailed}
        chartBreakdown={chartBreakdown}
        chartBreakdownList={chartBreakdownList}
        handleIsDetailedChange={(event) =>
          dispatch(transactionActions.setIsDetailed(event.target.checked))
        }
        handleBreakdownChange={(event) => {
          dispatch(transactionActions.setTransactionSegmentFocusOn([]));
          dispatch(transactionActions.setBreakdown(event.target.value));
        }}
        isFocusOnAvailable
        focusOnList={focusOnList}
        focusOn={focusOn}
        focusOnLabel={focusOn?.length ? 'Focused on' : 'Select Focus on'}
        handleFocusOnChange={(event, value) => {
          dispatch(transactionActions.setTransactionSegmentFocusOn(value));
        }}
      />
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <ActionButton onClick={clearSearch} label="RESET" />
      </CustomStack>
    </>
  );
};
//
export default TransactionFilter;
