/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from '../sign-in/slice';
/**
 * Initial states of visual function are defined here
 */
export const initialState = {
  //
  loading: false,
  visuals: [],
  visualById: [],
  reports: [],
  pageNames: [],
  isSessionTimedOut: false,
};
/**
 * All actions related to visual feature are defined here
 */
export const visualSlice = createSlice({
  name: 'feature/visual',
  initialState,
  reducers: {
    getVisuals(state) {
      state.loading = true;
    },
    getVisualsSucceeded(state, action) {
      state.loading = false;
      state.visuals = action?.payload.results;
    },
    getVisualsFailed(state) {
      state.loading = false;
    },
    createVisuals(state) {
      state.loading = true;
    },
    createVisualsSucceeded(state, action) {
      state.loading = false;
      state.visuals = [...state.visuals, action?.payload];
    },
    createVisualsFailed(state) {
      state.loading = false;
    },
    updateVisual(state) {
      state.loading = true;
    },
    updateVisualSucceeded(state, action) {
      state.loading = false;
      state.visuals = state?.visuals?.map((object) =>
        object?.id === action?.payload?.id ? { ...object, ...action.payload } : object
      );
    },
    updateVisualFailed(state) {
      state.loading = false;
    },
    deleteVisual(state) {
      state.loading = true;
    },
    deleteVisualSucceeded(state, action) {
      state.loading = false;
      state.visuals = state?.visuals?.filter((object) => object?.id !== action?.payload?.data?.id);
    },
    deleteVisualFailed(state) {
      state.loading = false;
    },
    getVisualById(state) {
      state.loading = true;
    },
    getVisualByIdSucceeded(state, action) {
      state.loading = false;
      state.visualById = action?.payload;
    },
    getVisualByIdFailed(state) {
      state.loading = false;
    },
    getReports(state) {
      state.loading = true;
    },
    getReportsSucceeded(state, action) {
      state.loading = false;
      state.reports = action?.payload;
    },
    getReportsFailed(state) {
      state.loading = false;
    },
    getReportPages(state) {
      state.loading = true;
    },
    getReportPagesSucceeded(state, action) {
      state.loading = false;
      state.reportPages = action?.payload;
    },
    getReportPagesFailed(state) {
      state.loading = false;
    },
    resetReportPages(state) {
      state.reportPages = [];
    },
    setSessionTimeOut(state, action) {
      state.isSessionTimedOut = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});
//
export const { actions: visualActions } = visualSlice;
