/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from '../sign-in/slice';

/**
 * Define the the users feature initial state
 */
export const initialState = {
  loading: false,
  usersList: {},
  roleList: {},
  hotelsList: {},
  hotelSelectionList: {},
  updatedUser: {},
  loggedUserHotelsList: {},
  hotelGroups: [],
  assignedHotelGroups: [],
};
/**
 * Here mentioned all the users feature related actions
 */
export const usersSlice = createSlice({
  name: 'feature/users',
  initialState,
  reducers: {
    usersList(state) {
      state.loading = true;
    },
    usersListSucceeded(state, action) {
      state.loading = false;
      state.usersList = action?.payload;
    },
    usersListFailed(state) {
      state.loading = false;
    },
    createAdminUsers(state) {
      state.loading = true;
    },
    createAdminUsersSucceeded(state, action) {
      state.loading = false;
      state.usersList.results = [...state.usersList.results, action?.payload];
      state.updatedUser = action?.payload;
    },
    createAdminUsersFailed(state) {
      state.loading = false;
    },
    createGeneralUsers(state) {
      state.loading = true;
    },
    createGeneralUsersSucceeded(state, action) {
      state.loading = false;
      state.usersList.results = [...state.usersList.results, action?.payload];
      state.updatedUser = action?.payload;
    },
    createGeneralUsersFailed(state) {
      state.loading = false;
    },
    createUsers(state) {
      state.loading = true;
    },
    createUsersSucceeded(state, action) {
      state.loading = false;
      state.usersList.results = [...state.usersList.results, action?.payload];
      state.updatedUser = action?.payload;
    },
    createUsersFailed(state) {
      state.loading = false;
    },
    updateUser(state) {
      state.loading = true;
    },
    updateUserSucceeded(state, action) {
      state.loading = false;
      state.usersList.results = state?.usersList?.results.map((object) =>
        object?.id === action?.payload?.id ? { ...object, ...action.payload } : object
      );
    },
    updateUserFailed(state) {
      state.loading = false;
    },
    updateRole(state) {
      state.loading = true;
    },
    updateRoleSucceeded(state) {
      state.loading = false;
    },
    updateRoleFailed(state) {
      state.loading = false;
    },
    getRoles(state) {
      state.loading = true;
    },
    getRolesSucceeded(state, action) {
      state.loading = false;
      state.roleList = action?.payload?.results;
    },
    getRolesFailed(state) {
      state.loading = false;
    },
    deleteUser(state) {
      state.loading = true;
    },
    deleteUserSucceeded(state, action) {
      state.loading = false;
      state.usersList.results = state?.usersList?.results.filter(
        (object) => object?.id !== action?.payload?.data?.userId
      );
    },
    deleteUserFailed(state) {
      state.loading = false;
    },
    getHotelsList(state) {
      state.loading = true;
    },
    getHotelsListSucceeded(state, action) {
      state.loading = false;
      state.hotelsList = action?.payload;
    },
    getHotelsListFailed(state) {
      state.loading = false;
    },
    getSelectionHotelsList(state) {
      state.loading = true;
    },
    getSelectionHotelsListSucceeded(state, action) {
      state.loading = false;
      state.hotelSelectionList = action?.payload;
    },
    getSelectionHotelsListFailed(state) {
      state.loading = false;
    },
    updateHotelsList(state) {
      state.loading = true;
    },
    updateHotelsListSucceeded(state) {
      state.loading = false;
      state.updatedUser = initialState.updatedUser;
    },
    updateHotelsListFailed(state) {
      state.loading = false;
    },
    getLoggedUserHotelsList(state) {
      state.loading = true;
    },
    getLoggedUserHotelsListSucceeded(state, action) {
      state.loading = false;
      state.loggedUserHotelsList = action?.payload;
    },
    getLoggedUserHotelsListFailed(state) {
      state.loading = false;
    },
    inviteUser(state) {
      state.loading = true;
    },
    inviteUserSucceeded(state) {
      state.loading = false;
    },
    inviteUserFailed(state) {
      state.loading = false;
    },
    changeUserPassword(state) {
      state.loading = true;
    },
    changeUserPasswordSucceeded(state) {
      state.loading = false;
    },
    changeUserPasswordFailed(state) {
      state.loading = false;
    },
    getHotelGroupList(state) {
      state.loading = true;
    },
    getHotelGroupListSucceeded(state, action) {
      state.loading = false;
      state.hotelGroups = action?.payload?.results
        ?.map((a) => ({
          id: a?.hotelGroups?.hotelGroupName,
          label: a?.hotelGroups?.hotelGroupName,
          query: a?.hotelGroups?.hotelQuery,
        }))
        .sort((a, b) => a?.label?.localeCompare(b.label));
    },
    getHotelGroupListFailed(state) {
      state.loading = false;
    },
    getAssignedHotelGroupList(state) {
      state.loading = true;
    },
    getAssignedHotelGroupListSucceeded(state, action) {
      state.loading = false;
      state.assignedHotelGroups =
        action?.payload?.map((a) => ({
          id: a?.hotelGroups?.hotelGroupName,
          label: a?.hotelGroups?.hotelGroupName,
          query: a?.hotelGroups?.hotelQuery,
        })) ?? [];
    },
    getAssignedHotelGroupListFailed(state) {
      state.loading = false;
      state.assignedHotelGroups = [];
    },
    assignHotelGroups(state) {
      state.loading = true;
    },
    assignHotelGroupsSucceeded(state) {
      state.loading = false;
      state.updatedUser = initialState.updatedUser;
    },
    assignHotelGroupsFailed(state) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});
//
export const { actions: usersActions } = usersSlice;
