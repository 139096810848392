import { useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { notificationActions } from 'modules/common/notifications/slice';
import ERROR_TYPES from 'modules/common/constants/error-types';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import { hotelActions } from 'modules/hotels/slice';
import { addHotelValidation } from 'modules/hotels/validation/hotel-form-validation';
import { Alert, TextField } from '../style';
import '../index.scss';
/**
 * Form to create hotel
 * @param {*} param0
 * @returns
 */
const AddHotelForm = ({ open, onClose }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const organizationId = useSelector(selectOrganizationId);
  //
  const modalAction = (action) => {
    if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
    onClose(action);
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      modalAction(false);
    }
  }, [notification]);
  //
  const initialValues = {
    name: '',
    databricksHotelId: '',
  };
  //
  const onSubmitHotel = async (values) => {
    const valueObj = {
      name: values?.name,
      databricksHotelId: values?.databricksHotelId,
    };
    //
    dispatch(hotelActions.createHotel({ ...valueObj, organizationId }));
  };
  //
  return (
    <Dialog open={open} onClose={() => modalAction(false)}>
      <DialogTitle>Add Hotel</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={addHotelValidation}
          onSubmit={onSubmitHotel}
        >
          {({ handleSubmit, handleBlur, handleChange, isSubmitting, touched, values, errors }) => (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <TextField
                name="name"
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                label="Name"
                size="small"
              />
              <TextField
                name="databricksHotelId"
                value={values.databricksHotelId}
                error={Boolean(touched.databricksHotelId && errors.databricksHotelId)}
                helperText={touched.databricksHotelId && errors.databricksHotelId}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                label="Databricks Hotel ID"
                size="small"
              />
              <DialogActions>
                <Button onClick={() => modalAction(false)} variant="contained" color="primary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="success" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
//
export default AddHotelForm;
