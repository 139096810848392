import { ListItemButton, Avatar as Logo, Drawer as MuiDrawer } from '@mui/material';
import styled from '@emotion/styled';
//
export const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;
//
export const LargeAvatar = styled(Logo)`
  width: 170px;
  height: 60px;
  text-align: left;
`;
//
export const SmallAvatar = styled(Logo)`
  margin-top: 15px;
  width: 100px;
  text-align: left;
`;
//
export const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;
  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }
  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;
