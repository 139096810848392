import { useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Autocomplete,
} from '@mui/material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { notificationActions } from 'modules/common/notifications/slice';
import { addVisualValidation } from 'modules/visual/validation/visual-form-validation';
import ERROR_TYPES from 'modules/common/constants/error-types';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import { visualActions } from 'modules/visual/slice';
import { TAG_LIST } from 'modules/visual/constants';
import { selectReportPagesDropdownList, selectReportsDropdownList } from 'modules/visual/selectors';
import { Alert, TextField } from '../style';
/**
 * Form to create visual
 * @param {*} param0
 * @returns
 */
const AddVisualForm = ({ open, onClose }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const organizationId = useSelector(selectOrganizationId);
  const reportsDropDownList = useSelector(selectReportsDropdownList);
  const reportPagesDropDownList = useSelector(selectReportPagesDropdownList);
  //
  const modalAction = (action) => {
    if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
    onClose(action);
  };
  //
  const loadPageNames = (value) => {
    //
    dispatch(
      visualActions.getReportPages({
        organizationId,
        reportId: value.id,
      })
    );
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      modalAction(false);
    }
  }, [notification]);
  const initialValues = {
    name: '',
    pageName: '',
    reportId: '',
    tags: [],
    order: '',
  };
  //
  const onSubmitAddVisual = async (values) => {
    const valueObj = {
      name: values.name,
      reportId: values.report.id,
      reportName: values.report.label,
      pageId: values.page.id,
      pageName: values.page.label,
      tags: values.tags,
      order: values.order,
    };
    dispatch(visualActions.createVisuals({ ...valueObj, organizationId }));
  };
  //
  const closeAddVisualModal = () => {
    dispatch(visualActions.resetReportPages());
    modalAction(false);
  };
  //
  return (
    <Dialog open={open} onClose={() => modalAction(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Add Visual</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={addVisualValidation}
          onSubmit={onSubmitAddVisual}
        >
          {({
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
            isSubmitting,
            touched,
            values,
            errors,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <TextField
                name="name"
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                label="Visual Name"
                size="small"
              />
              <Autocomplete
                size="small"
                value={values.report}
                fullWidth
                disableClearable
                name="report"
                limitTags={3}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={reportsDropDownList || []}
                onChange={(e, value) => {
                  setFieldValue('report', value);
                  loadPageNames(value);
                }}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(touched.report && errors.report)}
                    helperText={touched.report && errors.report}
                    label="Report"
                    size="small"
                    {...params}
                    fullWidth
                    my={2}
                  />
                )}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: 0,
                    height: 'fit-content',
                  },
                }}
              />
              <Autocomplete
                size="small"
                value={values.report}
                fullWidth
                disableClearable
                name="page"
                limitTags={3}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={reportPagesDropDownList || []}
                onChange={(e, value) => {
                  setFieldValue('page', value);
                }}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(touched.page && errors.page)}
                    helperText={touched.page && errors.page}
                    label="Page Name"
                    size="small"
                    {...params}
                    fullWidth
                    my={2}
                  />
                )}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: 0,
                    height: 'fit-content',
                  },
                }}
              />
              <Autocomplete
                size="small"
                value={values.tags}
                fullWidth
                multiple
                disableClearable
                disableCloseOnSelect
                name="tags"
                limitTags={3}
                isOptionEqualToValue={(option, value) => option === value}
                options={TAG_LIST || []}
                onChange={(e, value) => setFieldValue('tags', value)}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(touched.tags && errors.tags)}
                    helperText={touched.tags && errors.tags}
                    label="Tags"
                    size="small"
                    {...params}
                    fullWidth
                  />
                )}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: 0,
                    height: 'fit-content',
                  },
                  marginY: 2,
                }}
              />
              <TextField
                type="number"
                name="order"
                value={values.order}
                error={Boolean(touched.order && errors.order)}
                helperText={touched.order && errors.order}
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                label="Order"
                my={2}
                size="small"
              />
              <DialogActions>
                <Button onClick={closeAddVisualModal} variant="contained" color="primary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="success" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
//
export default AddVisualForm;
