import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'modules/common/constants/route';
import useIsAuthenticated from 'modules/common/auth/hooks/use-is-authenticated';
import { useDispatch } from 'react-redux';
import { authActions } from 'modules/common/auth/slice';
/**
 * Check is authenticated or not and if not authenticate then route to login
 * @returns
 */
const useAuthorization = () => {
  const { isAuthenticated, isActiveToken } = useIsAuthenticated();
  //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //
  useEffect(() => {
    if (!isAuthenticated() || !isActiveToken()) {
      dispatch(authActions.removeAuth());
      navigate(ROUTES.LOGIN);
    }
  }, [isAuthenticated]);
  //
  return { isAuthenticated, isActiveToken };
};
//
export default useAuthorization;
