/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { auditLogsActions } from './slice';
import API from './constants';

/**
 * Users list generator function to get users list
 * @param {*} param0
 */
export function* auditLogsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_AUDIT_LOGS, payload);
    yield put(auditLogsActions.auditLogsSucceeded(response));
  } catch (error) {
    yield put(auditLogsActions.auditLogsFailed(error?.message));
  }
}
/**
 * Users list generator function to get users list
 * @param {*} param0
 */
export function* usersListGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USER_LIST, payload);
    yield put(auditLogsActions.usersListSucceeded(response));
  } catch (error) {
    yield put(auditLogsActions.usersListFailed(error?.message));
  }
}
//
export function* auditLogsSaga() {
  yield takeLatest(auditLogsActions.auditLogs.type, auditLogsGenerator);
  yield takeLatest(auditLogsActions.usersList.type, usersListGenerator);
}
//
export default auditLogsSaga;
