/**
 * Transaction feature related request structures are defined here
 */
export const API = {
  POST_TOKEN_INFO: {
    path: '/auth/introspect-tokens',
    method: 'POST',
  },
  GET_EMBED_TOKEN: {
    path: '/organizations/:organizationId/visuals-embed/token',
    method: 'GET',
  },
  GET_VISUALS: {
    path: '/organizations/:organizationId/visuals?:query',
    method: 'GET',
  },
  GET_VISUAL_FILTERS: {
    path: '/organizations/:organizationId/visual-filters?:query',
    method: 'GET',
  },
  GET_ALL_HOTELS: {
    path: '/organizations/:organizationId/hotels?:query',
    method: 'GET',
  },
  GET_ALL_BREAKDOWNS: {
    path: '/organizations/:organizationId/breakdowns?:query',
    method: 'GET',
  },
};
/**
 * Tags for Visual Filter classification are defined here
 */
export const TAGS = {
  TARGET_DATE: 'TARGET_DATE',
  BOOKING_DATE: 'BOOKING_DATE',
  DATE_COMPARISON: 'DATE_COMPARISON',
  HOTEL_NAME: 'HOTEL_NAME',
  SEGMENT_BREAKDOWN: 'SEGMENT_BREAKDOWN',
  DETAILED: 'DETAILED',
  SUMMARY_SEGMENT_BREAKDOWN: 'SUMMARY_SEGMENT_BREAKDOWN',
  HOTEL_ID: 'HOTEL_ID',
};
/**
 * Operators to generate Power BI schema are defined here
 */
export const OPERATORS = {
  NOT_IN: 'NotIn',
  ALL: 'All',
  GREATER_OR_EQUAL: 'GreaterThanOrEqual',
  LESS_OR_EQUAL: 'LessThanOrEqual',
  AND: 'And',
  IN: 'In',
};
/**
 * Default booking date is defined here
 */
export const BOOKING_DATE = '2015/01/01';

/**
 * Report type of each widget is defined here
 */
export const REPORT_TYPE = {
  TRANSACTION: 'Summary',
  TRANSACTION_HOTEL: 'Summary-Hotel',
  ADMIN_TRANSACTION: 'Admin-Dashboard',
  ADMIN_TRANSACTION_DEAGGREGATE: 'Admin-Dashboard-De-aggregate',
};

/**
 * Custom Keys are defined here
 */
export const KEYS = {
  START_DATE: 'start',
  END_DATE: 'end',
  CUSTOM: 'Custom',
};

/**
 * Reset booking date for dashboard component is defined here
 */
export const RESET_BOOKING_DATE = {
  startDate: new Date(BOOKING_DATE).toISOString(),
  endDate: new Date().toISOString(),
  key: 'selection',
};

/**
 * Column names used for transaction segment filter to be sent for AT visuals
 */
export const TRANSACTION_SEGMENT_FILTER = {
  TABLE_NAME: 'Fact_Transactions',
  CHANNEL_RAW: 'channel',
  CHANNEL: 'AssignedChannel',
  MARKET_SEGMENT: 'AssignedMarketSegment',
  MARKET_SEGMENT_RAW: 'marketSegmentCode',
  RATE_CATEGORY: 'AssignedRateCategory',
  RATE_CATEGORY_RAW: 'rateCategoryCode',
  REGION: 'AssignedRegion',
  REGION_RAW: 'region_cleaned',
};
/**
 * Column names used for transaction segment filter to be sent for AT visuals
 */
export const TRANSACTION_DATES_FILTER = {
  STAY_DATE: 'Date',
  ARRIVAL_DATE: 'arrivalDate',
  DEPARTURE_DATE: 'departureDate',
};

//
export const VISUAL_DATE_TYPES = {
  STAY: 'STAY',
  ARRIVAL: 'ARRIVAL',
  DEPARTURE: 'DEPARTURE',
};
//
export const SUMMARY_DATE_SELECTION = [
  { label: 'Stay Dates', id: VISUAL_DATE_TYPES.STAY },
  { label: 'Arrival Dates', id: VISUAL_DATE_TYPES.ARRIVAL },
  { label: 'Departure Dates', id: VISUAL_DATE_TYPES.DEPARTURE },
];
//
export const TRANSACTION_STAY_DATE = {
  key: 'selection',
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
};
