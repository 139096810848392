import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBreakdown,
  selectIsDetailed,
  selectTransactionBookingDate,
  selectTransactionFilterList,
  selectTransactionSegmentFocusOn,
  selectHotelName,
  selectTransactionDateType,
  selectTargetDate,
  selectVisualFilters,
} from 'modules/transactions/selectors';
import { transactionActions } from 'modules/transactions/slice';
import {
  getBookingDateSchema,
  getChartBreakdownFilterList,
  getHotelIdSchema,
  getSegmentFocusOnSchema,
  getTargetDateSchema,
} from 'modules/transactions/components/inner-filter/functions';
import { ENVIRONMENT } from 'config';
import { getSelectedBreakdown } from 'modules/transactions/functions';
/**
 * Custom hook to generate transaction widget filter schemas
 */
const useTransactionWidget = () => {
  const dispatch = useDispatch();
  //
  const chartBreakdown = useSelector(selectBreakdown);
  const bookingDate = useSelector(selectTransactionBookingDate);
  const targetDate = useSelector(selectTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const isDetailed = useSelector(selectIsDetailed);
  const transactionFilterList = useSelector(selectTransactionFilterList);
  const focusOn = useSelector(selectTransactionSegmentFocusOn);
  const selectedDateType = useSelector(selectTransactionDateType);
  //
  const [previousHotel, setPreviousHotel] = useState(null);
  //
  const serializedState = JSON.parse(localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`));
  const persistValues = Object.values(serializedState)[1];
  const persistedHotelName = JSON.parse(persistValues).hotelName;
  //
  useEffect(() => {
    // Persist hotel focus on value on browser refresh
    setPreviousHotel(persistedHotelName);
    if (previousHotel !== null) {
      // dispatch(transactionActions.setHotelName(hotelDropdownList[0]));
    }
  }, [hotelId]);

  // Triggered when pmsSyncToggle,chartBreakdown,bookingDate,visualFilters,dateComparison,alignment,targetDate,hotelId,isDetailed,endDate,startDate value changes
  useEffect(() => {
    if (!chartBreakdown || !bookingDate || !targetDate || !visualFilters || !hotelId) return;
    // Generate filter list for transaction widget top 8 visuals
    const targetDateFilter = getTargetDateSchema(
      visualFilters,
      selectedDateType?.id,
      targetDate?.startDate,
      targetDate?.endDate
    );
    //
    const hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    const hotelFilters = [hotelIdFilter];
    //
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );

    const chartBreakdownFilter = getChartBreakdownFilterList(
      visualFilters,
      chartBreakdown,
      isDetailed
    );
    const selectedBreakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    const segmentFocusOnFilter = getSegmentFocusOnSchema(
      selectedBreakdown,
      focusOn?.map((item) => item?.label)
    );
    if (
      chartBreakdownFilter &&
      bookingDateFilter &&
      targetDateFilter &&
      hotelFilters &&
      segmentFocusOnFilter
    ) {
      const filters = [
        chartBreakdownFilter,
        bookingDateFilter,
        targetDateFilter,
        ...hotelFilters,
        segmentFocusOnFilter,
      ];
      //
      if (JSON.stringify(filters) !== JSON.stringify(transactionFilterList)) {
        dispatch(transactionActions.updateTransactionFilterList(filters));
      }
    }
  }, [
    chartBreakdown,
    bookingDate,
    visualFilters,
    targetDate,
    hotelId,
    isDetailed,
    focusOn,
    selectedDateType,
  ]);
  //
};
//
export default useTransactionWidget;
