/* eslint-disable import/no-cycle */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'root-saga';
import customMiddleware from 'modules/common/middleware/custom-middleware';
import { authSlice } from 'modules/common/auth/slice';
import { signInSlice } from 'modules/sign-in/slice';
import { persistReducer, persistStore } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import storage from 'redux-persist/lib/storage';
import { ENVIRONMENT } from 'config';
import { forgotPasswordSlice } from 'modules/forgot-password/slice';
import { resetPasswordSlice } from 'modules/reset-password/slice';
import { notificationSlice } from 'modules/common/notifications/slice';
import { verifyCallbackSlice } from 'modules/verify-callback/slice';
import logger from 'modules/common/utils/logger';
import { visualSlice } from 'modules/visual/slice';
import { visualFilterSlice } from 'modules/visual-filter/slice';
import { hotelSlice } from 'modules/hotels/slice';
import { configureEventCategoriesSlice } from 'modules/event-configuration/slice';
import { usersSlice } from 'modules/users/slice';
import { userInvitesSlice } from 'modules/user-invitation/slice';
import { hotelSetSlice } from 'modules/hotel-set/slice';
import { transactionSlice } from 'modules/transactions/slice';
import { auditLogsSlice } from '../modules/audit-logs/slice';

export const sagaMiddleware = createSagaMiddleware();
/**
 * Setup encryption using specific key
 */
const encryption = encryptTransform({
  secretKey: ENVIRONMENT.PERSIST_ENCRYPTED_SECRET_KEY,
  onError(error) {
    logger.error(error);
  },
});
/**
 * White listed slices which needs to be persist with encryption
 */
const persistConfig = {
  version: ENVIRONMENT.PERSIST_VERSION,
  key: ENVIRONMENT.PERSIST_KEY,
  storage,
  stateReconciler: hardSet,
  transforms: [encryption],
  whitelist: ['feature/base-auth'],
  writeFailHandler(error) {
    logger.error(error);
  },
};
/**
 * Combine all the persist and non persist reducers
 */
const reducers = combineReducers({
  'feature/base-auth': authSlice.reducer,
  'feature/sign-in': signInSlice.reducer,
  'feature/forgot-password': forgotPasswordSlice.reducer,
  'feature/reset-password': resetPasswordSlice.reducer,
  'feature/notification': notificationSlice.reducer,
  'feature/verify-callback': verifyCallbackSlice.reducer,
  'feature/visual': visualSlice.reducer,
  'feature/visual-filter': visualFilterSlice.reducer,
  'feature/configure-event-categories': configureEventCategoriesSlice.reducer,
  'feature/users': usersSlice.reducer,
  'feature/user-invites': userInvitesSlice.reducer,
  'feature/audit-logs': auditLogsSlice.reducer,
  'feature/hotels': hotelSlice.reducer,
  'feature/hotel-set': hotelSetSlice.reducer,
  'feature/transaction': transactionSlice.reducer,
});
/**
 * Define what are the slice which needs to be persist
 */
const persistedReducer = persistReducer(persistConfig, reducers);
/**
 * Register all the slices into the main store with encryption
 */
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    sagaMiddleware,
    customMiddleware,
  ],
});
sagaMiddleware.run(rootSaga);
persistStore(store);
//
export default store;
