import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Autocomplete,
  Checkbox,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectOrganizationId, selectAuthUser } from 'modules/common/auth/selectors';
import {
  selectUpdatedUser,
  selectHotelsSelectionList,
  selectHotelGroups,
} from 'modules/users/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import ROLE_NAMES from 'modules/common/constants/user-roles';
import {
  ADMIN_ROLE,
  DEMO_ROLE,
  PREFERRED_ROLE,
  SUPER_ADMIN_ROLE,
  USER_ROLE,
} from 'modules/common/constants/roles';
import { notificationActions } from 'modules/common/notifications/slice';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { isEmpty } from 'modules/common/helpers/object';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import { addUserValidation } from '../../validation/add-user-form-validation';
import { Alert, TextField } from './style';
import { usersActions } from '../../slice';

const AddUserFormDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const currentUser = useSelector(selectAuthUser);
  const hotelList = useSelector(selectHotelsSelectionList);
  const updatedUser = useSelector(selectUpdatedUser);
  const hotelGroupList = useSelector(selectHotelGroups);
  //
  const [showPassword, setShowPassword] = useState(false);
  //
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  //
  const [role, setRole] = useState(ROLE_NAMES[0].label);
  const [userHotels, setUserHotels] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  //
  const modalAction = (action) => {
    if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
    onClose(action);
  };
  //
  const allSelected = hotelList?.length === selectedOptions?.length ?? false;
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      modalAction(false);
    }
  }, [notification]);
  //
  useEffect(() => {
    if (!isEmpty(updatedUser)) {
      const hotelIds = allSelected ? [] : userHotels?.map((selectedHotel) => selectedHotel.id);
      dispatch(
        usersActions.updateHotelsList({
          hotels: hotelIds,
          organizationId,
          userId: updatedUser?.id,
        })
      );
    }
  }, [updatedUser]);
  //
  const handleToggleOption = (selectedOps) => {
    setSelectedOptions(selectedOps);
  };
  const handleClearOptions = () => setSelectedOptions([]);
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      if (!isEmpty(hotelList)) {
        setSelectedOptions(hotelList);
      }
    } else {
      handleClearOptions();
    }
  };
  const handleToggleSelectAll = () => handleSelectAll && handleSelectAll(!allSelected);
  const filter = createFilterOptions();
  //
  const onSubmitAddUser = async (values) => {
    setUserHotels([...values.hotels]);
    const valueObj = {
      email: values.email,
      username: values.username,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      hotelGroup: [...values.hotelSet]?.map((hotel) => hotel?.label),
    };
    if (role === USER_ROLE) {
      dispatch(usersActions.createGeneralUsers({ ...valueObj, organizationId }));
    } else if (role === ADMIN_ROLE) {
      dispatch(usersActions.createAdminUsers({ ...valueObj, organizationId }));
    } else {
      dispatch(
        usersActions.createUsers({
          ...valueObj,
          roleName: role,
          organizationId,
        })
      );
    }
  };
  //
  const initialValues = {
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    password: '',
    hotels: [],
    hotelSet: [],
  };
  //
  return (
    <Dialog open={open} onClose={() => modalAction(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add User</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={addUserValidation}
          onSubmit={onSubmitAddUser}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
            resetForm,
          }) => {
            useEffect(() => {
              if (open) {
                resetForm();
                setRole(ROLE_NAMES[0].label);
                setSelectedOptions([]);
              }
            }, [open, resetForm]);
            //
            return (
              <form noValidate onSubmit={handleSubmit}>
                {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                  <Alert mt={2} mb={3} severity={notification?.type}>
                    {notification?.message}
                  </Alert>
                )}
                <TextField
                  size="small"
                  type="email"
                  name="email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                  label="Email Address"
                />
                <TextField
                  size="small"
                  name="username"
                  value={values.username}
                  error={Boolean(touched.username && errors.username)}
                  helperText={touched.username && errors.username}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                  label="Username"
                />
                <TextField
                  size="small"
                  name="firstName"
                  value={values.firstName}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                  label="First Name"
                />
                <TextField
                  size="small"
                  name="lastName"
                  value={values.lastName}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                  label="Last Name"
                  my={2}
                />
                <TextField
                  size="small"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                  label="Password"
                  my={2}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  size="small"
                  select
                  name="role"
                  disabled={
                    currentUser.roles[0].name === USER_ROLE ||
                    currentUser.roles[0].name === DEMO_ROLE ||
                    currentUser.roles[0].name === PREFERRED_ROLE
                  }
                  value={role}
                  label="Role"
                  onChange={(event) => setRole(event.target.value)}
                  fullWidth
                  sx={{ marginY: 2 }}
                  InputLabelProps={{ shrink: true }}
                >
                  {ROLE_NAMES?.filter((userRole) => userRole.key !== SUPER_ADMIN_ROLE).map(
                    (type) => (
                      <MenuItem key={type.key} value={type.key}>
                        {type.label}
                      </MenuItem>
                    )
                  )}
                </TextField>
                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  disableClearable
                  disableCloseOnSelect
                  filterSelectedOptions
                  name="hotels"
                  limitTags={2}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: '90%',
                        marginLeft: '10%',
                      },
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      padding: 0,
                      height: 'fit-content',
                    },
                    marginY: 2,
                  }}
                  onBlur={handleBlur}
                  options={hotelList ?? []}
                  value={selectedOptions}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return [{ id: 0, label: 'Select All' }, ...filtered];
                  }}
                  renderOption={(props, option, { selected }) => {
                    const selectAllProps =
                      option.label === 'Select All' ? { checked: allSelected } : {};
                    return (
                      <li {...props}>
                        <Checkbox checked={selected} {...selectAllProps} />
                        {option.label}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={Boolean(touched.hotels && errors.hotels)}
                      helperText={touched.hotels && errors.hotels}
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: 'auto',
                          overflow: 'auto',
                          maxHeight: 100,
                        },
                      }}
                      {...params}
                      label="Hotels"
                    />
                  )}
                  onChange={(event, selectedOps, reason) => {
                    if (reason === 'selectOption' || reason === 'removeOption') {
                      if (
                        selectedOps.find((option) => option.label === 'Select All') &&
                        selectedOps?.length === hotelList.length + 1
                      ) {
                        handleClearOptions();
                        setFieldValue('hotels', []);
                      } else if (selectedOps.find((option) => option.label === 'Select All')) {
                        handleToggleSelectAll();
                        if (!isEmpty(hotelList)) {
                          setFieldValue('hotels', hotelList);
                        }
                      } else {
                        handleToggleOption(selectedOps);
                        setFieldValue('hotels', selectedOps);
                      }
                    } else if (reason === 'clear') {
                      handleClearOptions();
                      setFieldValue('hotels', []);
                    }
                  }}
                />
                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  disableClearable
                  disableCloseOnSelect
                  filterSelectedOptions
                  limitTags={2}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      padding: 0,
                      height: 'fit-content',
                    },
                    marginY: 4,
                  }}
                  onBlur={handleBlur}
                  name="hotelSet"
                  options={hotelGroupList ?? []}
                  getOptionLabel={(option) => (option.label ? option.label : '')}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, value) => setFieldValue('hotelSet', value)}
                  disabled={hotelGroupList?.length < 1}
                  renderInput={(params) => (
                    <TextField {...params} label="Hotel Sets" size="small" color="primary" />
                  )}
                />
                <DialogActions>
                  <Button onClick={() => modalAction(false)} variant="contained" color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="success" disabled={isSubmitting}>
                    Save
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
//
export default AddUserFormDialog;
