/* eslint-disable */
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, CssBaseline } from '@mui/material';
import GlobalStyle from 'modules/common/style/global';
import { Footer, Navbar, SessionTimeOutPopup, TokenRevokedPopup } from 'modules/common/components';
import useUpdateToken from 'modules/common/hooks/use-update-token';
import Sidebar from 'modules/common/components/sidebar/side-bar';
import getDashboardItems from 'modules/common/components/sidebar/dashboard-Items';
import { getAuthToken } from 'modules/common/helpers/token';
import { drawerWidth, Root, Drawer, AppContent, MainContent } from './style';
import useUpdatePbiToken from 'modules/common/hooks/use-update-pbi-token';
//
const DashboardLayout = ({ children }) => {
  useUpdatePbiToken();
  useUpdateToken();
  //
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />

      <Drawer>
        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={getDashboardItems(getAuthToken())}
          />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={getDashboardItems(getAuthToken())}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isLgUp ? 12 : 5}>
          {children}
          <Outlet />
          <SessionTimeOutPopup />
          <TokenRevokedPopup />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default DashboardLayout;
