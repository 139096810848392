import { Grid, TextField, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHotelName,
  selectAllHotelList,
  selectTransactionDateType,
} from 'modules/transactions/selectors';
import { SUMMARY_DATE_SELECTION } from 'modules/transactions/constants';
import { transactionActions } from 'modules/transactions/slice';
import { CompareHotelsWindow, StayDatePicker } from 'modules/transactions/components/inner-filter';
import styles from 'modules/transactions/components/inner-filter/inner-filter.module.scss';

/**
 * Stay date-range picker for each widget with scrollable date navigation
 * @param {String} reportType - Report type of active tab
 * @param {String} adornment - Text to add as start adornment in date picker textfield
 * @param {String} label - Label for in date picker textfield
 * @returns
 */
const DashboardHeader = ({ adornment, label }) => {
  const dispatch = useDispatch();
  const allHotels = useSelector(selectAllHotelList);
  const hotelFocusOn = useSelector(selectHotelName);
  const transactionDateType = useSelector(selectTransactionDateType);
  //
  return (
    <Grid
      container
      direction={{ sm: 'column', md: 'row', lg: 'row' }}
      alignItems="center"
      justifyContent="flex-end"
      spacing={2}
      sx={{ padding: 2, flexWrap: { sm: 'nowrap', md: 'nowrap', lg: 'nowrap' } }}
    >
      <CompareHotelsWindow
        hotelList={allHotels ?? []}
        focusOn={hotelFocusOn ?? null}
        handleCompareHotelsChange={(event, value) => {
          dispatch(transactionActions.setHotelName(value));
        }}
      />
      <Autocomplete
        sx={{ width: 200, maxWidth: 200, paddingLeft: 2 }}
        size="small"
        value={transactionDateType ?? {}}
        id="tags-outlined"
        className={styles.headerDropdown}
        options={SUMMARY_DATE_SELECTION}
        getOptionLabel={(option) => (option.label ? option.label : '')}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(e, value) => {
          dispatch(transactionActions.setTransactionDateType(value));
        }}
        disableClearable
        renderInput={(params) => (
          <TextField label="Select Date Type" {...params} size="small" color="primary" />
        )}
      />
      <StayDatePicker adornment={adornment} label={label} />
    </Grid>
  );
};
//
export default DashboardHeader;
