/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { visualActions } from './slice';
import API from './constants';
/**
 * Power BI visual generator function to obtain registered visuals
 * @param {*} param0
 */
export function* getVisualsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_VISUALS, payload);
    yield put(visualActions.getVisualsSucceeded(response));
  } catch (error) {
    yield put(visualActions.getVisualsFailed(error?.message));
  }
}
/**
 * Visual generator function to create visual
 * @param {*} param0
 */
export function* createVisualGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_VISUALS, payload);
    yield put(visualActions.createVisualsSucceeded(response));
  } catch (error) {
    yield put(visualActions.createVisualsFailed(error?.message));
  }
}
/**
 * Delete visual generator function to delete visual
 * @param {*} param0
 */
export function* deleteVisualGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_VISUAL, payload);
    yield put(visualActions.deleteVisualSucceeded({ response, data: payload }));
  } catch (error) {
    yield put(visualActions.deleteVisualFailed(error?.message));
  }
}
/**
 * Update visual generator function to update visual
 * @param {*} param0
 */
export function* updateVisualGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_VISUAL, payload);
    yield put(visualActions.updateVisualSucceeded(response));
  } catch (error) {
    yield put(visualActions.updateVisualFailed(error?.message));
  }
}
/**
 * Get visual generator function to get visual by id
 * @param {*} param0
 */
export function* getVisualByIdGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_VISUAL_BY_ID, payload);
    yield put(visualActions.getVisualByIdSucceeded(response));
  } catch (error) {
    yield put(visualActions.getVisualByIdFailed(error?.message));
  }
}
/**
 * Power BI reports generator function to obtain registered reports
 * @param {*} param0
 */
export function* getReportsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_REPORTS, payload);
    yield put(visualActions.getReportsSucceeded(response));
  } catch (error) {
    yield put(visualActions.getReportsFailed(error?.message));
  }
}
/**
 * Power BI reports generator function to obtain registered reports
 * @param {*} param0
 */
export function* getReportPagesGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_REPORT_PAGES, payload);
    yield put(visualActions.getReportPagesSucceeded(response));
  } catch (error) {
    yield put(visualActions.getReportPagesFailed(error?.message));
  }
}
//
export function* visualSaga() {
  yield takeLatest(visualActions.getVisuals.type, getVisualsGenerator);
  yield takeLatest(visualActions.createVisuals.type, createVisualGenerator);
  yield takeLatest(visualActions.deleteVisual.type, deleteVisualGenerator);
  yield takeLatest(visualActions.updateVisual.type, updateVisualGenerator);
  yield takeLatest(visualActions.getVisualById.type, getVisualByIdGenerator);
  yield takeLatest(visualActions.getReports.type, getReportsGenerator);
  yield takeLatest(visualActions.getReportPages.type, getReportPagesGenerator);
}
//
export default visualSaga;
