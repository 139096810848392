import styled from '@emotion/styled';
import { Alert as MuiAlert, TextField as MuiTextField } from '@mui/material';
import { spacing } from '@mui/system';
/**
 * Custom style for the custom aggregation component is defined here
 */
export const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  textAlign: 'center',
}));
//
export const Alert = styled(MuiAlert)(spacing);
//
export const TextField = styled(MuiTextField)(spacing);
