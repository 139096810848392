/**
 * Here mentioned user invitation feature request structure
 */
const API = {
  GET_USER_INVITES: {
    path: '/organizations/:organizationId/invites?:query',
    method: 'GET',
  },
  GET_ROLES: {
    path: '/organizations/:organizationId/roles',
    method: 'GET',
  },
  GET_USER_LIST: {
    path: '/organizations/:organizationId/users?:query',
    method: 'GET',
  },
  REINVITE_USER: {
    path: '/organizations/:organizationId/invites/:invitationId/re-send',
    method: 'POST',
  },
};
//
export default API;
/**
 * Invitation statuses are defined here
 */
export const INVITATION_STATUS = {
  PENDING: 'PENDING',
  EXPIRED: 'EXPIRED',
};
