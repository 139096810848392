/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { userInvitesActions } from './slice';
import API from './constants';
/**
 * Get user invites generator function to get all user invites
 * @param {*} param0
 */
export function* getUserInvitesGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USER_INVITES, payload);
    yield put(userInvitesActions.getUserInvitesSucceeded(response));
  } catch (error) {
    yield put(userInvitesActions.getUserInvitesFailed(error?.message));
  }
}
/**
 * Users list generator function to get users list
 * @param {*} param0
 */
export function* usersListGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USER_LIST, payload);
    yield put(userInvitesActions.usersListSucceeded(response));
  } catch (error) {
    yield put(userInvitesActions.usersListFailed(error?.message));
  }
}
/**
 * Get roles generator function to get roles
 * @param {*} param0
 */
export function* getRolesGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ROLES, payload);
    yield put(userInvitesActions.getRolesSucceeded(response));
  } catch (error) {
    yield put(userInvitesActions.getRolesFailed(error?.message));
  }
}
/**
 * Resend invite generator function to resend user invitation
 * @param {*} param0
 */
export function* resendInviteGenerator({ payload }) {
  try {
    const response = yield call(request, API.REINVITE_USER, payload);
    yield put(userInvitesActions.resendInviteSucceeded(response));
  } catch (error) {
    yield put(userInvitesActions.resendInviteFailed(error?.message));
  }
}
//
export function* userInvitesSaga() {
  yield takeLatest(userInvitesActions.getUserInvites.type, getUserInvitesGenerator);
  yield takeLatest(userInvitesActions.usersList.type, usersListGenerator);
  yield takeLatest(userInvitesActions.getRoles.type, getRolesGenerator);
  yield takeLatest(userInvitesActions.resendInvite.type, resendInviteGenerator);
}
//
export default userInvitesSaga;
