import { Autocomplete, TextField, Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
/**
 * Dropdown component with check lists
 * @param {*} options - result set
 * @param {*} values - selected values of the dropdown
 * @param {*} label - select dropdown label
 * @param {*} onchange - trigger event when option select
 * @param {*} disabledOption - for disable specific items
 * @param {*} disableClearable - for disable the clear all button
 * @param {*} isOptionEqualTo - for selected value is equal to available option
 * @param {*} sx - for custom styling purpose
 * @returns
 */
const CheckboxDropdown = ({
  options,
  values,
  label,
  onchange,
  disabledOption,
  disableClearable,
  isOptionEqualTo,
  sx,
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <Autocomplete
      size="small"
      multiple
      limitTags={1}
      id="tags-outlined"
      options={options ?? []}
      isOptionEqualToValue={isOptionEqualTo}
      onChange={onchange}
      disableClearable={disableClearable ?? true}
      disableCloseOnSelect
      fullWidth
      value={values}
      renderInput={(params) => <TextField {...params} label={label} size="small" color="primary" />}
      getOptionLabel={(option) => option?.label ?? option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option?.label ?? option}
        </li>
      )}
      componentsProps={{
        paper: {
          sx: {
            width: '100%',
            whiteSpace: 'unset',
            wordBreak: 'break-all',
          },
        },
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          padding: 0,
          height: 'fit-content',
        },
        marginY: 2,
        ...sx,
      }}
      getOptionDisabled={disabledOption}
    />
  );
};
//
export default CheckboxDropdown;
