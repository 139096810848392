import * as yup from 'yup';
/**
 * Add visual form validation rules are mentioned here
 */
export const addVisualValidation = yup.object().shape({
  name: yup.string().trim().required('Visual name is a required field'),
  report: yup
    .object()
    .shape({
      id: yup.string().required('id is required'),
      label: yup.string().required('label is required'),
    })
    .nullable()
    .required('Report is a required field'),
  page: yup
    .object()
    .shape({
      id: yup.string().required('id is required'),
      label: yup.string().required('label is required'),
    })
    .nullable()
    .required('Page is a required field'),
  order: yup.number().required('Order is a required field').min(0),
  tags: yup.array().min(1, 'At least one tag is required'),
});
/**
 * Update visual form validation rules are mentioned here
 */
export const updateVisualValidation = yup.object().shape({
  name: yup.string().trim().required('Visual name is a required field'),
  order: yup.number().required('Order is a required field').min(0),
  tags: yup.array().min(1, 'At least one tag is required'),
});
