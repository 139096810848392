import {
  ADMIN_ROLE,
  USER_ROLE,
  SUPER_ADMIN_ROLE,
  DEMO_ROLE,
  PREFERRED_ROLE,
  PREFERRED_ADMIN_ROLE,
} from 'modules/common/constants/roles';

const ROLE_NAMES = [
  { label: 'Admin', key: ADMIN_ROLE },
  { label: 'Demo', key: DEMO_ROLE },
  { label: 'General', key: USER_ROLE },
  { label: 'Preferred', key: PREFERRED_ROLE },
  { label: 'Super Admin', key: SUPER_ADMIN_ROLE },
  { label: 'Preferred Admin', key: PREFERRED_ADMIN_ROLE },
];
//
export default ROLE_NAMES;
