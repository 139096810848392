/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from '../sign-in/slice';
/**
 * Define the the Event category configuration feature initial state
 */
export const initialState = {
  loading: false,
  eventCategories: {},
  minorEventCategories: {},
  updatedMinorEvent: false,
  hotelSelectionList: {},
};
/**
 * All the event category configuration feature related actions are defined here
 */
export const configureEventCategoriesSlice = createSlice({
  name: 'feature/configure-event-categories',
  initialState,
  reducers: {
    configureEventCategories(state) {
      state.loading = true;
    },
    configureEventCategoriesSucceeded(state, action) {
      state.eventCategories = action?.payload;
      state.loading = false;
    },
    configureEventCategoriesFailed(state) {
      state.loading = false;
    },
    addMinorEventCategories(state) {
      state.loading = true;
      state.updatedMinorEvent = false;
    },
    addMinorEventCategoriesSucceeded(state) {
      state.loading = false;
      state.updatedMinorEvent = true;
    },
    addMinorEventCategoriesFailed(state) {
      state.loading = false;
    },
    addMajorEventCategories(state) {
      state.loading = true;
    },
    addMajorEventCategoriesSucceeded(state, action) {
      state.loading = false;
      state.eventCategories.results = [...state.eventCategories.results, action?.payload];
    },
    addMajorEventCategoriesFailed(state) {
      state.loading = false;
    },
    getMinorEventCategories(state) {
      state.loading = true;
    },
    getMinorEventCategoriesSucceeded(state, action) {
      state.loading = false;
      state.minorEventCategories = action?.payload;
    },
    getMinorEventCategoriesFailed(state) {
      state.loading = false;
    },
    updateMinorEventCategories(state) {
      state.loading = true;
    },
    updateMinorEventCategoriesSucceeded(state, action) {
      state.loading = false;
      state.minorEventCategories.results = state?.minorEventCategories?.results.map((object) =>
        object?.id === action?.payload[0]?.id ? { ...object, ...action.payload[0] } : object
      );
    },
    updateMinorEventCategoriesFailed(state) {
      state.loading = false;
    },
    deleteMinorEventCategories(state) {
      state.loading = true;
    },
    deleteMinorEventCategoriesSucceeded(state, action) {
      state.loading = false;
      state.minorEventCategories.results = state?.minorEventCategories?.results.filter(
        (object) => object?.id !== action?.payload?.data?.minorEventCategoryId
      );
    },
    deleteMinorEventCategoriesFailed(state) {
      state.loading = false;
    },
    getSelectionHotelsList(state) {
      state.loading = true;
    },
    getSelectionHotelsListSucceeded(state, action) {
      state.loading = false;
      state.hotelSelectionList = action?.payload;
    },
    getSelectionHotelsListFailed(state) {
      state.loading = false;
    },
    updateMajorEventCategories(state) {
      state.loading = true;
    },
    updateMajorEventCategoriesSucceeded(state, action) {
      state.loading = false;
      state.eventCategories.results = state?.eventCategories?.results.map((object) =>
        object?.id === action?.payload?.id ? { ...object, ...action.payload } : object
      );
    },
    updateMajorEventCategoriesFailed(state) {
      state.loading = false;
    },
    deleteMajorEventCategories(state) {
      state.loading = true;
    },
    deleteMajorEventCategoriesSucceeded(state, action) {
      state.loading = false;
      state.eventCategories.results = state?.eventCategories?.results.filter(
        (object) => object?.id !== action?.payload?.data?.majorEventCategoryId
      );
    },
    deleteMajorEventCategoriesFailed(state) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});
//
export const { actions: configureEventCategoriesActions } = configureEventCategoriesSlice;
