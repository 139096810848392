/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { hotelSetActions } from './slice';
import { API } from './constants';

/**
 * Get hotel generator function to obtain registered hotels
 * @param {*} param0
 */
export function* getHotelsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_HOTELS, payload);
    yield put(hotelSetActions.getHotelsSucceeded(response));
  } catch (error) {
    yield put(hotelSetActions.getHotelsFailed(error?.message));
  }
}

/**
 * Get hotel groups generator function to get all hotel groups
 * @param {*} param0
 */
export function* getAllHotelGroupsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALL_HOTEL_GROUPS, payload);
    yield put(hotelSetActions.getHotelGroupListSucceeded(response));
  } catch (error) {
    yield put(hotelSetActions.getHotelGroupListFailed(error?.message));
  }
}
/**
 * Create hotel group generator function to obtain registered visuals
 * @param {*} param0
 */
export function* createHotelGroupGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_HOTEL_GROUP, payload);
    yield put(hotelSetActions.createHotelGroupSucceeded(response));
  } catch (error) {
    yield put(hotelSetActions.createHotelGroupFailed(error?.message));
  }
}
/**
 * delete hotel group generator function to delete hotel group
 * @param {*} param0
 */
export function* deleteHotelGroupGenerator({ payload }) {
  try {
    yield call(request, API.DELETE_HOTEL_GROUP, payload);
    yield put(hotelSetActions.deleteHotelGroupSucceeded(payload));
  } catch (error) {
    yield put(hotelSetActions.deleteHotelGroupFailed(error?.message));
  }
}
/**
 * validate hotel group name generator function to check group  name already exist or not
 * @param {*} param0
 */
export function* validateHotelGroupNameGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALL_HOTEL_GROUPS, payload);
    yield put(hotelSetActions.getHotelGroupNameValidationSucceeded(response));
  } catch (error) {
    yield put(hotelSetActions.getHotelGroupNameValidationFailed(error?.message));
  }
}
/**
 * get hotel groups list generator function to load hotel groups
 * @param {*} param0
 */
export function* getHotelGroupsListGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALL_HOTEL_GROUPS, payload);
    yield put(hotelSetActions.getLoadHotelGroupListSucceeded(response));
  } catch (error) {
    yield put(hotelSetActions.getLoadHotelGroupListFailed(error?.message));
  }
}
//
export function* hotelSetSaga() {
  yield takeLatest(hotelSetActions.getHotels.type, getHotelsGenerator);
  yield takeLatest(hotelSetActions.getHotelGroupList.type, getAllHotelGroupsGenerator);
  yield takeLatest(hotelSetActions.getLoadHotelGroupList.type, getHotelGroupsListGenerator);
  yield takeLatest(hotelSetActions.createHotelGroup.type, createHotelGroupGenerator);
  yield takeLatest(hotelSetActions.deleteHotelGroup.type, deleteHotelGroupGenerator);
  yield takeLatest(
    hotelSetActions.getHotelGroupNameValidation.type,
    validateHotelGroupNameGenerator
  );
}
//
export default hotelSetSaga;
