/* eslint-disable import/prefer-default-export */
import { ENVIRONMENT } from 'config';
import { AUTH_URL, REDIRECT_URI, RESPONSE_TYPE, SCOPE } from './keycloak';
/**
 * Keycloak domain
 */
const domain = ENVIRONMENT.KC_DOMAIN;
/**
 * Keycloak realm
 */
const realm = ENVIRONMENT.KC_REALM;
/**
 * Keycloak client id
 */
const clientId = ENVIRONMENT.KC_CLIENT_ID;
/**
 * Keycloak login redirect URL
 */
const redirectURL = `${ENVIRONMENT.APP_URL}/${REDIRECT_URI}`;
/**
 * Keycloak PKCE challenge method
 */
const pkceChallengeMethod = ENVIRONMENT.KC_PKCE_CHALLENGE_METHOD;

/**
 * Built authentication URL
 */
export const authenticationUrl = `${domain}/realms/${realm}/${AUTH_URL}?client_id=${clientId}&response_type=${RESPONSE_TYPE}&redirect_uri=${redirectURL}&scope=${SCOPE}&code_challenge_method=${pkceChallengeMethod}`;
