import { Grid, Typography } from '@mui/material';
import { ENVIRONMENT } from 'config';
import ROUTES from 'modules/common/constants/route';
import { BigAvatar, Wrapper } from './style';
import useSafeLocation from '../../hooks/use-safe-location';
//
const AuthWrapper = ({ children }) => {
  const { pathname } = useSafeLocation();
  //
  return (
    <>
      {pathname === ROUTES.LOGIN && (
        <Wrapper>
          <Grid container item xs={12} justifyContent="center" alignItems="center">
            <BigAvatar variant="square" alt={ENVIRONMENT.APP_NAME} src="/static/assets/logo.png" />
          </Grid>

          <Typography component="h3" variant="h4" align="center" gutterBottom>
            Admin Portal
          </Typography>
          <Typography component="h2" variant="body1" align="center" gutterBottom>
            Sign in to your account to continue
          </Typography>
          {children}
        </Wrapper>
      )}
      {pathname === ROUTES.FORGOT_PASSWORD && (
        <Wrapper>
          <Grid container item xs={12} justifyContent="center" alignItems="center">
            <BigAvatar variant="square" alt={ENVIRONMENT.APP_NAME} src="/static/assets/logo.png" />
          </Grid>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Forgot Password
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Enter your email to reset your password
          </Typography>
          {children}
        </Wrapper>
      )}
      {pathname === ROUTES.RESET_PASSWORD && (
        <Wrapper>
          <Grid>
            <BigAvatar variant="square" alt={ENVIRONMENT.APP_NAME} src="/static/assets/logo.png" />
          </Grid>

          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Reset Password
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Enter your new password to reset your password
          </Typography>
          {children}
        </Wrapper>
      )}
    </>
  );
};
//
export default AuthWrapper;
