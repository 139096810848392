/* eslint-disable camelcase */
import { getAuthToken } from 'modules/common/helpers/token';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import ROUTE from 'modules/common/constants/route';
import { useDispatch } from 'react-redux';
import { authActions } from '../../slice';

const PrivateRoute = ({ component: Component, roles }) => {
  /* determine if authorized, from context or however you're doing it */
  const token = getAuthToken();
  //
  const dispatch = useDispatch();
  //
  const isAuthorized = () => {
    if (token && roles) {
      const user = jwt_decode(token);
      const tokenRoles = user?.realm_access?.roles || [];
      return roles.some((r) => tokenRoles.includes(r));
    }
    return false;
  };
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  if (isAuthorized()) {
    return <Component />;
  }
  //
  return dispatch(authActions.removeAuth(), <Navigate to={ROUTE.LOGIN} />);
};
//
export default PrivateRoute;
