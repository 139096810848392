/* eslint-disable no-underscore-dangle */
import { DataGrid } from '@mui/x-data-grid';
import { withTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectHotels } from 'modules/hotels/selectors';
import { TABLE_HEADER_FONT_COLOR } from 'modules/common/constants/style';

const columnVisibilityModel = {
  id: false,
};
//
const columns = [
  {
    field: 'id',
    headerName: 'ID',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Name',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'displayName',
    headerName: 'Display Name',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'chainName',
    headerName: 'Chain Name',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'roomCapacity',
    headerName: 'Room Capacity',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'timezone',
    headerName: 'Time Zone',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'reportingCurrency',
    headerName: 'Reporting Currency',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'country',
    headerName: 'Country',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'state',
    headerName: 'State',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'city',
    headerName: 'City',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
];
//
const HotelTableView = ({ isShow, setSelectedHotelId }) => {
  const hotels = useSelector(selectHotels);
  //
  const [filteredHotels, setFilteredHotels] = useState([]);
  //
  useEffect(() => {
    const formattedHotels = hotels?.map((hotel) => ({
      id: hotel?.id,
      name: hotel?.name,
      roomCapacity: hotel?.roomCapacity,
      displayName: hotel?.displayName,
      timezone: hotel?.timezone,
      reportingCurrency: hotel?.reportingCurrency,
      chainName: hotel?.chainName,
      city: hotel?.city,
      state: hotel?.state,
      country: hotel?.country
    }));
    setFilteredHotels(formattedHotels);
  }, [hotels]);
  //
  const onSelectedRowChange = (ids) => {
    if (ids?.length) {
      setSelectedHotelId(ids[0]);
      isShow(true);
    }
  };
  //
  return (
    <div style={{ height: 650, flex: 1 }}>
      <DataGrid
        sx={{
          '& .super-app-theme--header': {
            backgroundColor: 'white',
            color: TABLE_HEADER_FONT_COLOR,
          },
          '& .MuiDataGrid-main': {
            backgroundColor: 'white',
          },
          '.MuiDataGrid-footerContainer': {
            backgroundColor: 'white',
          },
        }}
        columnVisibilityModel={columnVisibilityModel}
        rowHeight={50}
        rows={filteredHotels || []}
        columns={columns}
        onSelectionModelChange={onSelectedRowChange}
      />
    </div>
  );
};
//
export default withTheme(HotelTableView);
