import { Button } from '@mui/material';
/**
 * Action button implementation
 * @param {String} label - button label
 * @param {Boolean} disabled - manage button accessability
 * @param {Function} onClick - trigger when button action occurred
 * @param {String} variant - color variant of the button
 * @param {String} type - type of the button (succuss,warning,danger)
 * @param {String} color - specific color of the button
 * @returns
 */
const ActionButton = ({
  label,
  onClick,
  disabled,
  variant = 'contained',
  type = 'clear',
  color = 'primary',
  size = 'small',
  sx = {},
  id = '',
}) => (
  <Button
    type={type}
    variant={variant}
    color={color}
    onClick={onClick}
    disabled={disabled}
    size={size}
    sx={sx}
    id={id}
  >
    {label.toUpperCase()}
  </Button>
);
//
export default ActionButton;
