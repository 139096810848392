import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken, selectAccessTokenExpired, selectRefreshToken } from '../auth/selectors';
import { authActions } from '../auth/slice';
import TIME_OUTS from '../constants/time-outs';
import { selectIsSessionTimedOut } from '../../visual/selectors';
import useIsTokenValid from '../auth/hooks/use-access-token-validity';
import useIsAuthenticated from '../auth/hooks/use-is-authenticated';
import { visualActions } from '../../visual/slice';

const useUpdateToken = () => {
  const dispatch = useDispatch();
  //
  const isSessionTimedOut = useSelector(selectIsSessionTimedOut);
  const selectExpiredTime = useSelector(selectAccessTokenExpired);
  const accessToken = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const { checkAuthentication } = useIsAuthenticated();
  const { doNeedRefresh } = useIsTokenValid();
  //
  const tryUpdateToken = useCallback(async () => {
    dispatch(authActions.refreshToken({ refreshToken }));
  }, [refreshToken]);
  //
  useEffect(() => {
    const interval = setInterval(async () => {
      tryUpdateToken();
    }, selectExpiredTime * 1000 - TIME_OUTS.SESSION_REFRESH_TIME * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [selectExpiredTime]);
  //
  useEffect(() => {
    // session observer
    const interval = setInterval(() => {
      if (checkAuthentication(refreshToken)) {
        // check whether access token need to refresh
        if (doNeedRefresh(accessToken)) {
          if (!isSessionTimedOut) {
            tryUpdateToken();
          }
        }

        // session has been timed out
      } else if (!isSessionTimedOut) {
        dispatch(visualActions.setSessionTimeOut(true));
      }
    }, TIME_OUTS.SESSION_OBSERVER_TIME);
    return () => {
      clearInterval(interval);
    };
  }, [isSessionTimedOut, accessToken, refreshToken]);
};
//
export default useUpdateToken;
