import { withTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TABLE_HEADER_FONT_COLOR } from 'modules/common/constants/style';
import { Grid } from '@mui/material';
import { isEmpty } from 'modules/common/helpers/object';
import { selectVisualFilters } from 'modules/visual-filter/selectors';
/**
 *  Data grid to display all regisered visual filters
 * @param {*} param0
 * @returns
 */
const columnVisibilityModel = {
  id: false,
};
/**
 * Column names for the data grid is defined here
 */
const columns = [
  {
    field: 'id',
    headerName: 'ID',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'tags',
    headerName: 'Tags',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Type',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'tableName',
    headerName: 'Table Name',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'columnName',
    headerName: 'Column Name',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'isDefault',
    headerName: 'Default',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'values',
    headerName: 'Values',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
];
//
const VisualFilterTable = ({ isShow, setSelectedVisualId }) => {
  const visualFilters = useSelector(selectVisualFilters);
  //
  const [formattedFilters, setformattedFilters] = useState([]);
  //
  useEffect(() => {
    if (!isEmpty(visualFilters)) {
      const filters = visualFilters?.map((filter) => {
        const value = filter?.values;
        if (typeof value[0] === 'object' && !Array.isArray(value[0]) && value[0] !== null) {
          const filterValues = value.map((item) => item?.label);
          return {
            id: filter?.id,
            isDefault: filter?.isDefault,
            values: filterValues,
            tableName: filter?.tableName,
            columnName: filter?.columnName,
            tags: filter?.tags ? filter?.tags.toString() : '',
            type: filter?.type,
          };
        }
        return {
          id: filter?.id,
          isDefault: filter?.isDefault,
          values: filter?.values ? filter?.values.toString() : '',
          tableName: filter?.tableName,
          columnName: filter?.columnName,
          tags: filter?.tags ? filter?.tags.toString() : '',
          type: filter?.type,
        };
      });
      setformattedFilters(filters);
    }
  }, [visualFilters]);
  //
  const onSelectedRowChange = (id) => {
    if (id?.length) {
      setSelectedVisualId(id[0]);
      isShow(true);
    }
  };
  //
  return (
    <Grid container sx={{ height: 600 }}>
      <DataGrid
        sx={{
          '& .super-app-theme--header': {
            backgroundColor: 'white',
            color: TABLE_HEADER_FONT_COLOR,
          },
          '& .MuiDataGrid-main': {
            backgroundColor: 'white',
          },
          '.MuiDataGrid-footerContainer': {
            backgroundColor: 'white',
          },
        }}
        columnVisibilityModel={columnVisibilityModel}
        rowHeight={50}
        rows={formattedFilters || []}
        columns={columns}
        onSelectionModelChange={onSelectedRowChange}
      />
    </Grid>
  );
};
//
export default withTheme(VisualFilterTable);
