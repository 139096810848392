import { Grid, Toolbar } from '@mui/material';
import CollapseIcon from 'modules/common/components/collapse-icon';
import { getVisualName } from 'modules/transactions/functions';
import styles from 'modules/common/powerbi/components/tab-container.module.scss';
import { InformationPopper } from 'modules/common/components';
/**
 * Visual Grid component for visual embedding iframe
 * @param {Boolean} isDisplay - To determine the display state of the visual
 * @param {Number} order - The order of the embedding visual
 * @param {Array} visualsList - Visual list of current tab
 * @param {Object} visual - Embedded visual
 * @param {Function} onClick - Action to trigger on click event
 * @param {Boolean} extendedForecast - To determine the state to show/ hide extended forecast toggle
 * @param {Component} extendedForecastFragment - Extended Forecast grid component
 * @returns
 */
const VisualGrid = ({
  isDisplay,
  order = null,
  visualsList = [],
  visual,
  onClick,
  information = null,
  toggleFragment,
  visualName = null,
  style = styles.paceVisualGrid,
  displayHotelName = false,
}) =>
(
  <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="flex-start"
    className={styles.mainGrid}
    mb={2}
  >
    <Grid bgcolor='white'>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height={40}
        className={styles.paceVisualTitle}
      >
        <Grid item pl={2}>
          {visualName ?? getVisualName(visualsList, order)}
        </Grid>
        <Grid item>
          <Grid item container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item mt={2}>
              {toggleFragment}
            </Grid>
            {information && (
              <Grid item mt={1} ml={4}>
                <InformationPopper information={information} placement="bottom-end" />
              </Grid>
            )}
            <CollapseIcon isDisplay={isDisplay} onClick={onClick} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    {isDisplay &&
      (style !== styles.paceVisualGrid ? (
        <Grid container sx={{ mt: displayHotelName ? 14 : 9.25 }}>
          {visual}
        </Grid>
      ) : (
        <Grid>{visual}</Grid>
      ))}
    {!isDisplay && <Toolbar />}
  </Grid>
);

//
export default VisualGrid;
