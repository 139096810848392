export const SUPER_ADMIN_ROLE = 'super-admin';
//
export const ADMIN_ROLE = 'admin';
//
export const USER_ROLE = 'user';
//
export const GUEST_ROLE = 'guest';
//
export const DEMO_ROLE = 'demo-user';
//
export const PREFERRED_ROLE = 'preferred-user';
//
export const PREFERRED_ADMIN_ROLE = 'preferred-admin';
