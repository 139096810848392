/* eslint-disable import/prefer-default-export  */
import * as yup from 'yup';

/**
 * Here mentioned users feature invite user validations
 */
export const inviteUserValidation = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email Address is required')
    .email('Email address must be valid'),
  role: yup.string().required('User role is required'),
  hotels: yup.array().required().min(1, 'At least one hotel is required'),
});
