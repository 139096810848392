/**
 * Evaluates a single condition against the given data.
 * @param {Array} condition - An array representing single condition [field, operator, value].
 * @param {Array} data - The data array to filter based on the condition.
 * @returns {Array} - An array of items that satisfy the condition.
 */
const evaluateCondition = (condition, data) => {
  const [field, operator, value] = condition;
  //
  return data.filter((item) => {
    const itemValue = (item[field] || '').toLowerCase(); // Convert to lowercase if defined
    const cleanedSearchValue = (value || '').replace(/^['"](.*)['"]$/, '$1').toLowerCase(); // Convert to lowercase if defined
    switch (operator) {
      case 'EQUAL':
        return itemValue === cleanedSearchValue;
      case 'NOT EQUAL':
        return itemValue !== cleanedSearchValue;
      case 'LIKE': {
        // Check if itemValue matches the pattern in cleanedSearchValue
        const pattern = new RegExp(`^${cleanedSearchValue?.replace(/%/g, '.*')}$`);
        return pattern?.test(itemValue);
      }
      default:
        return false;
    }
  });
};

/**
 * Applies a logical expression to filter data based on multiple conditions and logical operators.
 * @param {Array} expression - An array representing a logical expression.
 * @param {Array} data - The data array to filter based on the logical expression.
 * @returns {Array} - An array of items that satisfy the logical expression.
 */
const applyLogicalExpression = (expression, data) => {
  if (!expression || !expression.length) {
    return data;
  }
  const [firstCondition, ...rest] = expression;
  let result = evaluateCondition(firstCondition, data);
  // Check the remaining conditions in pairs (operator and nextCondition)
  for (let i = 0; i < rest?.length; i += 2) {
    const operator = rest[i];
    const nextCondition = rest[i + 1];
    //
    if (operator === 'AND') {
      // Filter the result array to include only items that satisfy the next condition
      result = result?.filter((item) => evaluateCondition(nextCondition, [item])?.length > 0);
    } else if (operator === 'OR') {
      // Evaluate the next condition and concatenate it with the current result
      const nextResult = evaluateCondition(nextCondition, data);
      result = [...result, ...nextResult];
    }
  }
  return result;
};
//
export default applyLogicalExpression;
