/**
 * Parses individual conditions into field, operator and value
 * @param {string} field - The field of the condition.
 * @param {Array<string>} tokens - The tokens representing the condition.
 * @returns {Array<string>} - An array containing the field, operator, and value of the condition.
 */
const parseCondition = (field, tokens) => {
  let operator = tokens.shift();
  let value;
  //
  if (operator === 'NOT' && tokens[0] === 'EQUAL') {
    // Treat "NOT EQUAL" as a single operator
    operator = 'NOT EQUAL';
    tokens.shift(); // consume 'EQUAL'
    value = tokens.shift();
  } else {
    value = tokens.shift();
  }
  if (value !== undefined) {
    // Remove single quotes from the value if present
    value = value.replace(/^'(.*)'$/, '$1');
  }
  //
  return [field, operator, value];
};

/**
 * Parses an expression from an array of tokens, creating a structured representation.
 * @param {Array<string>} tokens - The array of tokens representing the logical expression.
 * @returns {Array<string>} - An array representing the structured expression.
 */
const parseExpression = (tokens) => {
  const result = [];
  let currentToken = tokens.shift();

  while (currentToken) {
    if (currentToken === 'AND' || currentToken === 'OR') {
      result.push(currentToken);
    } else {
      const condition = parseCondition(currentToken, tokens);
      result.push(condition);
    }
    currentToken = tokens.shift();
  }
  return result;
};

/**
 * Parses a logical expression from an input string, creating a structured representation.
 * @param {string} inputString - The input string representing the logical expression.
 * @returns {Array<string>} - An array representing the structured expression.
 */
const parseLogicalExpression = (inputString) => {
  const tokens = inputString.match(/'[^']*'|\S+/g) || [];
  return parseExpression(tokens);
};
//
export default parseLogicalExpression;
