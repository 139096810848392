/* eslint-disable camelcase */
import { useCallback } from 'react';
import jwt_decode from 'jwt-decode';
import { getAuthToken } from 'modules/common/helpers/token';
/**
 * Based on the access token expiration check the token expired or not
 * @returns
 */
const useIsTokenValid = () => {
  /* Determine if authorized, from context or however you're doing it */
  const token = getAuthToken();
  //
  const isValid = useCallback(() => {
    if (token) {
      const { exp } = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      return exp >= currentTime;
    }
    return false;
  }, [token]);

  const doNeedRefresh = useCallback((accessToken) => {
    if (accessToken) {
      const { exp } = jwt_decode(accessToken);
      const currentTime = Date.now() / 1000 + 10;
      //
      return exp <= currentTime;
    }
    return false;
  }, []);

  return { isValid, doNeedRefresh };
};
//
export default useIsTokenValid;
