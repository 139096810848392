// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import {
  selectAccessToken,
  selectIdToken,
  selectRefreshToken,
} from 'modules/common/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'modules/common/auth/slice';

export const getAuthToken = () => useSelector(selectAccessToken);

export const getRefreshToken = () => useSelector(selectRefreshToken);

export const getIdToken = () => useSelector(selectIdToken);

export const removeAuthToken = () => {
  const dispatch = useDispatch();
  dispatch(authActions.removeAuth());
};

/**
 * Check that input roles are included or not in the access token
 * @param accessToken
 * @param {*} roles
 * @returns
 */
export const isAllowForRoles = (accessToken, roles) => {
  /* Determine if authorized, from context or however you're doing it */
  if (accessToken && roles) {
    const user = jwt_decode(accessToken);
    const tokenRoles = user?.realm_access?.roles || [];
    return roles.some((r) => tokenRoles.includes(r));
  }
  return false;
};
