/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { API } from 'modules/transactions/constants';
import { transactionActions } from './slice';
/**
 * Embed token generator function for Power BI embedding
 * @param {*} param0
 */
export function* getEmbedTokenGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_EMBED_TOKEN, payload);
    yield put(transactionActions.getEmbedTokenSucceeded(response));
  } catch (error) {
    yield put(transactionActions.getEmbedTokenFailed(error?.message));
  }
}
/**
 * Power BI visual generator function to obtain registered visuals
 * @param {*} param0
 */
export function* getVisualsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_VISUALS, payload);
    yield put(transactionActions.getVisualsSucceeded(response));
  } catch (error) {
    yield put(transactionActions.getVisualsFailed(error?.message));
  }
}
/**
 * Power BI visual filter generator function to obtain registered visual filters
 * @param {*} param0
 */
export function* getVisualFiltersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_VISUAL_FILTERS, payload);
    yield put(transactionActions.getVisualFiltersSucceeded(response));
  } catch (error) {
    yield put(transactionActions.getVisualFiltersFailed(error?.message));
  }
}
/**
 * Breakdown data generator function to obtain data for breakdown dropdown
 * @param {*} param0
 */
export function* getBreakdownGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALL_BREAKDOWNS, payload);
    yield put(transactionActions.getBreakdownSucceeded(response));
  } catch (error) {
    yield put(transactionActions.getBreakdownFailed(error?.message));
  }
}
/**
 * All hotels force fetch generator function to obtain the list of all registered hotels
 * @param {*} param0
 */
export function* getAllHotelsListGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALL_HOTELS, payload);
    yield put(transactionActions.getAllHotelListSucceeded(response));
  } catch (error) {
    yield put(transactionActions.getAllHotelListFailed(error?.message));
  }
}
/**
 * Get latest updated hotels generator function to obtain the list of latest updated hotel list
 * @param {*} param0
 */
export function* getLatestUpdatedAHotelsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALL_HOTELS, payload);
    yield put(transactionActions.getLatestUpdatedHotelListSucceeded(response));
  } catch (error) {
    yield put(transactionActions.getLatestUpdatedHotelListFailed(error?.message));
  }
}
//
export function* transactionSaga() {
  yield takeLatest(transactionActions.getEmbedToken.type, getEmbedTokenGenerator);
  yield takeLatest(transactionActions.getVisuals.type, getVisualsGenerator);
  yield takeLatest(transactionActions.getVisualFilters.type, getVisualFiltersGenerator);
  yield takeLatest(transactionActions.getBreakdown.type, getBreakdownGenerator);
  yield takeLatest(transactionActions.getAllHotelList.type, getAllHotelsListGenerator);
  yield takeLatest(
    transactionActions.getLatestUpdatedHotelList.type,
    getLatestUpdatedAHotelsGenerator
  );
}
export default transactionSaga;
