/* eslint-disable  */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'modules/common/components';
import { selectLoader } from 'modules/visual-filter/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { Card, CardContent, Grid, Typography, Button } from '@mui/material';
import { withTheme } from '@emotion/react';
import { visualFilterActions } from 'modules/visual-filter/slice';
import { isEmpty } from 'modules/common/helpers/object';
import VisualFilterTable from '../visual-filter-table';
import UpdateVisualFilterForm from '../update-visual-filter';
import AddVisualFilterForm from '../add-visual-filter';

/**
 * Visual filter view to add, edit and delete visual filters
 * @param {*} param0
 * @returns
 */
const VisualFilterView = ({ theme }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const loading = useSelector(selectLoader);
  //
  const [selectedFilterId, setSelectedFilterId] = useState({});
  const [open, setOpen] = useState(false);
  const [show, isShow] = useState(false);
  //
  useEffect(() => {
    const query = `limit=${PAGINATION.VISUAL_FILTERS_PER_PAGE_COUNT}`;
    dispatch(
      visualFilterActions.getVisualFilters({
        organizationId,
        query,
      })
    );
  }, [organizationId]);
  //
  useEffect(() => {
    if (!isEmpty(selectedFilterId)) {
      dispatch(
        visualFilterActions.getVisualFilterById({
          organizationId,
          selectedFilterId,
        })
      );
    }
  }, [organizationId, selectedFilterId]);
  //
  return (
    <Loader loading={loading}>
      <Card>
        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
          <Grid container direction="row" justifyContent="space-between" pb={4}>
            <Typography variant="h3">Configure Visual Filters</Typography>
            <Button
              disabled={show}
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Add Visual Filter
            </Button>
          </Grid>
          <Grid container direction="row" spacing={2} justifyContent="space-between">
            <Grid item sx={{ flexGrow: 1, flexBasis: 1 }}>
              <VisualFilterTable isShow={isShow} setSelectedVisualId={setSelectedFilterId} />
            </Grid>
            {!isEmpty(selectedFilterId) && (
              <UpdateVisualFilterForm
                show={show}
                isShow={isShow}
                visualFilterId={selectedFilterId}
              />
            )}
          </Grid>
        </CardContent>
        <AddVisualFilterForm open={open} onClose={() => setOpen(false)} />
      </Card>
    </Loader>
  );
};
//
export default withTheme(VisualFilterView);
