import { ENVIRONMENT } from 'config';

/**
 * Function that converts a given string to base64url format as required by PKCE
 * @param {String} str - String to be converted
 * @returns Base64-url encoded string
 */
const base64UrlEncode = (str) =>
  str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
/**
 * Function that generates the code verifier required for PKCE
 * @returns Code verifier
 */
export const generateCodeVerifier = () => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //
  for (let i = 0; i < ENVIRONMENT.KC_PKCE_VERIFIER_LENGTH; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};
/**
 * Function that generates a code challenge by using the SHA-256 hashing algorithm on the provided verifier
 * @param {String} codeVerifier - Code verifier to be hashed
 * @returns Base64URL-encoded code challenge
 */
export const generateCodeChallenge = async (codeVerifier) => {
  const digest = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier));
  return base64UrlEncode(window.btoa(String.fromCharCode(...new Uint8Array(digest))));
};
