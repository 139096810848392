/* eslint-disable import/no-cycle */
import { call, put, takeLatest, select } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { selectRefreshToken } from 'modules/common/auth/selectors';
import { authActions } from 'modules/common/auth/slice';
import API from 'modules/common/auth/constants';

export function* authFetchUserGenerator({ payload: { userId } }) {
  try {
    const response = yield call(request, API.POST_FETCH_USER, { userId }, true);
    yield put(authActions.fetchUserSucceeded(response));
  } catch (err) {
    yield put(authActions.fetchUserFailed('User request failed'));
  }
}
//
export function* authSignOutUserGenerator() {
  try {
    const refreshToken = yield select(selectRefreshToken);
    const response = yield call(request, API.POST_SIGN_OUT, { refreshToken: refreshToken ?? '' });
    yield put(authActions.signOutSucceeded(response));
  } catch (err) {
    yield put(authActions.signOutFailed(err?.message));
  }
}
//
export function* getRefreshTokens({ payload }) {
  try {
    const response = yield call(request, API.POST_REFRESH_TOKENS, payload);
    yield put(authActions.refreshTokenSucceeded(response));
  } catch (err) {
    yield put(authActions.refreshTokenFailed(err?.message));
  }
}
//
export function* authSaga() {
  yield takeLatest(authActions.fetchUser.type, authFetchUserGenerator);
  yield takeLatest(authActions.signOut.type, authSignOutUserGenerator);
  yield takeLatest(authActions.refreshToken.type, getRefreshTokens);
}
export default authSaga;
