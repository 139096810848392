/* eslint-disable no-underscore-dangle */
import { DataGrid } from '@mui/x-data-grid';
import { withTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { TABLE_HEADER_FONT_COLOR } from 'modules/common/constants/style';
import { selectHotelGroupDropdownList } from '../../selectors';

const columnVisibilityModel = {
  id: false,
};
//
const columns = [
  {
    field: 'id',
    headerName: 'ID',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Name',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'hotels',
    headerName: 'Hotels',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    sortable: false,
  },
  {
    field: 'query',
    headerName: 'Query',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    sortable: false,
  },
];
//
const HotelSetTableView = ({ setIsEditable, setSelectedHotelName }) => {
  const hotels = useSelector(selectHotelGroupDropdownList);
  //
  const [filteredHotels, setFilteredHotels] = useState([]);
  //
  useEffect(() => {
    const formattedHotels = hotels?.map((hotel) => ({
      id: hotel?.id,
      name: hotel?.label,
      hotels: hotel?.hotels?.map((filter) => filter?.label),
      query: hotel?.query,
    }));
    setFilteredHotels(formattedHotels);
  }, [hotels]);
  //
  const onSelectedRowChange = (ids) => {
    if (ids?.length) {
      setSelectedHotelName(ids[0]);
      setIsEditable(true);
    }
  };
  //
  return (
    <div style={{ height: 700, flex: 1 }}>
      <DataGrid
        sx={{
          '& .super-app-theme--header': {
            backgroundColor: 'white',
            color: TABLE_HEADER_FONT_COLOR,
          },
          '& .MuiDataGrid-main': {
            backgroundColor: 'white',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: 'white',
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
        columnVisibilityModel={columnVisibilityModel}
        getRowHeight={() => 'auto'}
        rows={filteredHotels || []}
        columns={columns}
        onSelectionModelChange={onSelectedRowChange}
      />
    </div>
  );
};
//
export default withTheme(HotelSetTableView);
