export const duplicateElements = (array, times) =>
  array.reduce((res, current) => res.concat(Array(times).fill(current)), []);

/**
 * Sorts an array of objects based on a specified field while ensuring uniqueness.
 * @param {Array<Object>} dataArray - The array of objects to be sorted.
 * @param {string} sortField - The field based on which the array should be sorted.
 * @returns {Array<Object>} - A new array of sorted and unique objects.
 */
export const sortAndDistinctByField = (dataArray, sortField) =>
  Array.from(new Set(dataArray?.map((obj) => JSON.stringify(obj))))
    ?.map(JSON.parse)
    ?.sort((a, b) => a?.[sortField]?.localeCompare(b?.[sortField]));
