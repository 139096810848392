/* eslint-disable no-underscore-dangle */
import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
//
const selectDomain = (state) => state['feature/hotels'] || initialState;
/**
 * Getting loader state to hotel component
 */
export const selectLoader = createSelector([selectDomain], (hotelState) => hotelState.loading);
/**
 * Getting hotel state to hotel component
 */
export const selectHotels = createSelector([selectDomain], (hotelState) => hotelState.hotels);
/**
 * Getting the filtered hotel by hotel id state into the component
 */
const getFirstParam = (_, param) => param;
export const selectHotelById = createSelector(
  [selectHotels, getFirstParam],
  (selectedHotels, hotelId) => selectedHotels?.filter((hotel) => hotel.id === hotelId)[0]
);
/**
 * Getting currency data state to hotel component
 */
export const selectCurrencyData = createSelector(
  [selectDomain],
  (hotelState) => hotelState.currencyData
);
/**
 * Getting users assigned to a specific hotel to the component
 */
export const selectHotelusers = createSelector(
  [selectDomain],
  (hotelState) => hotelState.hotelUsers
);
