import { Grid, Box } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEmbedToken,
  selectIsDeAggregate,
  selectTransactionDeAggregatedVisual,
  selectTransactionFilterList,
  selectTransactionIsDisplay,
  selectTransactionVisual,
} from 'modules/transactions/selectors';
import ExportToCSV from 'modules/common/components/export-visual-data/export-csv';
import styles from 'modules/common/powerbi/components/tab-container.module.scss';
import { transactionActions } from 'modules/transactions/slice';
import VisualGrid from 'modules/common/powerbi/components/visual-grid';
import VisualCard from 'modules/common/powerbi/components/visual-card';
import useTransactionWidget from 'modules/transactions/hooks/use-transaction-widget';
import TransactionFilter from '../filter-panel';
/**
 * Transaction widget report with filters
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @returns
 */
const TransactionWidget = ({ latestDate }) => {
  useTransactionWidget();
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const transactionFilterList = useSelector(selectTransactionFilterList);
  const transactionSetIsDisplay = useSelector(selectTransactionIsDisplay);
  const tokenDetails = useSelector(selectEmbedToken);
  const accessToken = tokenDetails?.accessToken;
  const visuals = useSelector(selectTransactionVisual);
  const isDeAggregate = useSelector(selectIsDeAggregate);
  const deAggregatedVisuals = useSelector(selectTransactionDeAggregatedVisual);
  //
  const [transactionVisualReport, setTransactionVisualReport] = useState({});
  const [isTransactionVisualReportLoaded, setIsTransactionVisualReport] = useState({});
  // To get visual details based on index
  const getVisual = (index) => {
    let found;
    if (isDeAggregate) {
      found = deAggregatedVisuals?.find((visual) => visual?.order === index);
    } else {
      found = visuals?.find((visual) => visual?.order === index);
    }
    //
    return found;
  };
  // To get visual name based on index
  const getVisualName = (index) => getVisual(index)?.name;
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    filter = transactionFilterList
  ) => {
    const found = getVisual(index);
    //
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };
  //
  return (
    <Grid
      container
      flexDirection="row"
      className={styles.mainGrid}
      justifyContent="space-between"
      alignItems="flex-start"
      bgcolor="transparent"
    >
      <Grid item xs={2}>
        <TransactionFilter latestDate={latestDate} />
      </Grid>
      <Grid item xs={10}>
        <Box sx={{ flexGrow: 1 }}>
          {transactionFilterList?.length > 0 && (
            <VisualGrid
              isDisplay={transactionSetIsDisplay}
              visualName={getVisualName(0)}
              visual={getWidget(
                0,
                styles.paceVisual,
                setTransactionVisualReport,
                setIsTransactionVisualReport
              )}
              onClick={() => {
                dispatch(transactionActions.setTransactionSetIsDisplay(!transactionSetIsDisplay));
              }}
              toggleFragment={
                <ExportToCSV
                  report={transactionVisualReport}
                  isLoaded={isTransactionVisualReportLoaded}
                  fileName={isDeAggregate ? 'Transactions_DeAgg' : 'Transactions_Agg'}
                />
              }
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
//
export default TransactionWidget;
