import { Grid } from '@mui/material';
import SidebarNav from './sidebar-nav';
import Footer from '../footer';
import { Brand, Drawer, LargeAvatar, SmallAvatar } from './style/style';
//
const Sidebar = ({ items, showFooter = false, ...rest }) => (
  <Drawer variant="permanent" {...rest}>
    <Brand>
      <Grid
        item
        sx={{
          display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' },
        }}
      >
        <LargeAvatar variant="square" src="/static/assets/logo-light.png" />
      </Grid>
      <Grid
        item
        sx={{
          display: { xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'none' },
        }}
      >
        <SmallAvatar variant="square" src="/static/assets/logo-light.png" />
      </Grid>
    </Brand>

    <SidebarNav items={items} />
    {!!showFooter && <Footer />}
  </Drawer>
);
//
export default Sidebar;
