import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Grid,
  Switch,
} from '@mui/material';
import { CheckboxDropdown, CustomStack } from 'modules/common/components';
import styles from 'modules/transactions/components/inner-filter/inner-filter.module.scss';
/**
 * Breakdown window component common for the dashboard component
 * @param {Boolean} isDetailed - State of Detailed toggle
 * @param {*} chartBreakdown - Selected chart breakdown value
 * @param {Array} chartBreakdownList - chart breakdown list
 * @param {Function} handleIsDetailedChange -  Function to set isDetailed state change
 * @param {Function} handleBreakdownChange -  Function to set breakdown state change
 * @param {Boolean} isFocusOnAvailable - state of focus on section hide or show
 * @param {Array} focusOnList - focus on list
 * @param {*} focusOn - Selected focus on value
 * @param {*} focusOnLabel - focus on section label
 *  @param {Function} handleFocusOnChange -  Function to set focus on state change
 * @returns
 */
const BreakdownWindow = ({
  isDetailed,
  chartBreakdown,
  chartBreakdownList,
  handleIsDetailedChange,
  handleBreakdownChange,
  isFocusOnAvailable,
  focusOnList = [],
  focusOn = [],
  focusOnLabel = '',
  handleFocusOnChange = () => {},
  id = '',
}) => (
  <CustomStack cssClassName={styles.innerFilter}>
    <FormControl>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <FormLabel>Breakdown</FormLabel>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={isDetailed}
                onChange={handleIsDetailedChange}
                id={`${id}-is-detailed`}
              />
            }
            sx={{ '& .css-1cn7wjw-MuiTypography-root': { fontSize: 12 } }}
            label="Details"
          />
        </FormControl>
      </Grid>
      <RadioGroup
        value={chartBreakdown ?? null}
        onChange={handleBreakdownChange}
        id={`${id}-${chartBreakdown}`}
      >
        {chartBreakdownList
          ?.sort((a, b) => a.label.localeCompare(b.label))
          .map((item) => (
            <FormControlLabel
              key={item?.id}
              value={item?.id}
              control={<Radio />}
              label={item?.label}
              sx={{
                my: -1,
              }}
            />
          ))}
      </RadioGroup>
    </FormControl>
    {isFocusOnAvailable && (
      <FormControl id={id ? `${id}-focus-on` : ''}>
        <CheckboxDropdown
          options={focusOnList}
          values={focusOn}
          label={focusOnLabel}
          onchange={handleFocusOnChange}
          isOptionEqualTo={(option, value) => option?.id === value?.id}
        />
      </FormControl>
    )}
  </CustomStack>
);
//
export default BreakdownWindow;
