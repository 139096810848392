/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'modules/sign-in/slice';
import { verifyCallbackActions } from 'modules/verify-callback/slice';

export const initialState = {
  user: {},
  tokens: {},
  organization: {},
  loading: false,
  resetPasswordToken: '',
  isTokenRevoked: false,
};
export const authSlice = createSlice({
  name: 'feature/base-auth',
  initialState,
  reducers: {
    fetchUser(state) {
      state.loading = true;
    },
    fetchUserSucceeded(state, action) {
      state.loading = false;
      state.user = action?.payload;
    },
    fetchUserFailed(state) {
      state.loading = false;
    },
    signOut(state) {
      state.loading = true;
    },
    signOutSucceeded(state) {
      state.loading = false;
      state.user = {};
      state.tokens = {};
    },
    signOutFailed(state) {
      state.loading = false;
    },
    removeAuth(state) {
      state.user = {};
      state.tokens = {};
      state.loading = false;
    },
    refreshToken(state) {
      state.loading = true;
    },
    refreshTokenSucceeded(state, action) {
      state.loading = false;
      state.tokens = action?.payload;
    },
    refreshTokenFailed(state) {
      state.loading = false;
    },
    setTokenRevocationError(state, action) {
      state.isTokenRevoked = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(signInActions.signInSucceeded, (state, action) => ({
        ...state,
        tokens: action?.payload?.tokens,
        user: action?.payload?.user,
      }))
      .addCase(signInActions.signInFailed, (state) => ({
        ...state,
        tokens: {},
        user: {},
      }))
      .addCase(verifyCallbackActions.verifyTokenSucceeded, (state, action) => ({
        ...state,
        resetPasswordToken: action?.payload?.token,
      }))
      .addCase(verifyCallbackActions.verifyTokenFailed, (state) => ({
        ...state,
        resetPasswordToken: '',
      }));
  },
});

export const { actions: authActions } = authSlice;
