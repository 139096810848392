/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from '../sign-in/slice';

/**
 * Define the user invites feature initial state
 */
export const initialState = {
  loading: false,
  auditLogs: [],
  usersList: [],
};
/**
 * Here mentioned all the user invites feature related actions
 */
export const auditLogsSlice = createSlice({
  name: 'feature/audit-logs',
  initialState,
  reducers: {
    auditLogs(state) {
      state.loading = true;
    },
    auditLogsSucceeded(state, action) {
      state.loading = false;
      state.auditLogs = action?.payload?.results;
    },
    auditLogsFailed(state) {
      state.loading = false;
    },
    usersList(state) {
      state.loading = true;
    },
    usersListSucceeded(state, action) {
      state.loading = false;
      state.usersList = action?.payload?.results;
    },
    usersListFailed(state) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});
//
export const { actions: auditLogsActions } = auditLogsSlice;
