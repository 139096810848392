import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, IconButton, Tooltip, TextField } from '@mui/material';
import { X, Trash, Save, PlusCircle } from 'react-feather';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { selectNotification } from 'modules/common/notifications/selectors';
import {
  selectMinorEventCategories,
  selectMinorEventUpdateState,
} from 'modules/event-configuration/selectors';
import { configureEventCategoriesActions } from 'modules/event-configuration/slice';
import { Modal } from 'modules/common/components';
import { isEmpty } from 'modules/common/helpers/object';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { Alert, Typography } from './style';
import AddMinorCategoryDialog from '../add-minor-category';
import MinorCategoriesGrid from '../minor-category-table';
/**
 * Drawer Item to display Minor event categories.
 */
const MinorEventDataDrawerView = ({ show, isShow, majorEventCategoryId }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const minorEventCategories = useSelector(selectMinorEventCategories);
  const updatedMinorEvent = useSelector(selectMinorEventUpdateState);
  //
  const [openAddMinorCategory, setOpenAddMinorCategory] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [isMajorCategoryUpdated, setMajorCategoryUpdated] = useState(false);
  const [majorCategoryName, setMajorCategoryName] = useState('');
  //
  useEffect(() => {
    if (!isEmpty(majorEventCategoryId)) {
      const query = `limit=${PAGINATION.MINOR_EVENT_CATEGORIES_PER_PAGE}`;
      dispatch(
        configureEventCategoriesActions.getMinorEventCategories({
          organizationId,
          majorEventCategoryId,
          query,
        })
      );
    }
  }, [updatedMinorEvent, majorEventCategoryId]);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      isShow(false);
    }
  }, [notification]);
  //
  useEffect(() => {
    if (minorEventCategories) {
      setMajorCategoryName(minorEventCategories?.majorEventCategoryName);
    }
    setMajorCategoryUpdated(false);
  }, [minorEventCategories]);
  //
  useEffect(() => {
    if (
      minorEventCategories?.majorEventCategoryName !== majorCategoryName &&
      majorCategoryName.length > 0
    ) {
      setMajorCategoryUpdated(true);
    } else {
      setMajorCategoryUpdated(false);
    }
  }, [majorCategoryName]);
  //
  const handleSave = () => {
    if (isMajorCategoryUpdated) {
      dispatch(
        configureEventCategoriesActions.updateMajorEventCategories({
          organizationId,
          majorEventCategoryId,
          name: majorCategoryName,
        })
      );
    }
    isShow(false);
    setUpdate(false);
  };
  //
  const deleteMajorCategory = (payload) => {
    dispatch(configureEventCategoriesActions.deleteMajorEventCategories(payload));
    setDelete(false);
    isShow(false);
  };
  //
  return (
    <Box
      xs={12}
      md={5}
      ml={4}
      mt={2}
      component={Grid}
      container
      item
      display="flex"
      direction="column"
      sx={{
        backgroundColor: 'white',
        flexShrink: 0,
        display: show ? 'flex' : 'none',
      }}
    >
      <Grid container direction="row" justifyContent="space-between" px={2}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 600, overflow: 'hidden', textOverflow: 'ellipsis', width: 300 }}
        >
          {minorEventCategories?.majorEventCategoryName}
        </Typography>
        <Grid>
          {isMajorCategoryUpdated && (
            <Tooltip title="Update">
              <IconButton
                disabled={false}
                color="inherit"
                size="small"
                onClick={() => setUpdate(true)}
              >
                <Save />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Delete">
            <IconButton color="error" size="small" onClick={() => setDelete(true)}>
              <Trash />
            </IconButton>
          </Tooltip>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => {
              setMajorCategoryName(minorEventCategories?.majorEventCategoryName);
              isShow(false);
            }}
          >
            <X />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" px={2}>
        <Grid mb={4} container pr={2}>
          <TextField
            name="majorName"
            label="Major category name"
            value={majorCategoryName ?? ''}
            onChange={(event) => {
              setMajorCategoryName(event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            my={2}
          />
        </Grid>
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 600, overflow: 'hidden', textOverflow: 'ellipsis', width: 300 }}
        >
          Minor categories
        </Typography>
        <Grid>
          <Tooltip title="Add">
            <IconButton
              disabled={false}
              color="inherit"
              size="small"
              onClick={() => setOpenAddMinorCategory(true)}
            >
              <PlusCircle />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="column" display="flex">
        {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
          <Alert mt={2} mb={3} severity={notification?.type}>
            {notification?.message}
          </Alert>
        )}
        {show && !isEmpty(minorEventCategories) && (
          <MinorCategoriesGrid
            minorEventCategories={minorEventCategories}
            majorEventCategoryId={majorEventCategoryId}
          />
        )}
        <Modal
          open={isUpdate}
          handleClose={() => setUpdate(false)}
          title="Update Major Category"
          content="Are you sure you want to update the major event category?"
          handleSuccess={handleSave}
          closeLabel="Cancel"
          successLabel="Update"
          variant="contained"
          color="primary"
        />
        <Modal
          open={isDelete}
          handleClose={() => setDelete(false)}
          title="Delete Major Category"
          content="Are you sure you want to delete the major category?"
          handleSuccess={() =>
            deleteMajorCategory({
              organizationId,
              majorEventCategoryId,
            })
          }
          closeLabel="Cancel"
          successLabel="Delete"
          variant="contained"
          color="error"
        />
        <AddMinorCategoryDialog
          open={openAddMinorCategory}
          onClose={() => setOpenAddMinorCategory(false)}
          majorEventCategoryId={majorEventCategoryId}
        />
      </Grid>
    </Box>
  );
};
//
export default MinorEventDataDrawerView;
