import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

/**
 * Getting entire users state into the component
 */
const selectDomain = (state) => state['feature/hotel-set'] || initialState;
/**
 *  Getting selected hotel groups state to dashboard component
 */
export const selectHotelGroups = createSelector([selectDomain], (state) => state.hotelGroups);
/**
 *  Getting hotel group list state to dashboard component
 */
export const selectHotelGroupDropdownList = createSelector(
  [selectDomain],
  (state) => state.hotelGroupsDropdownList
);
/**
 *  Getting hotel group list state to dashboard component
 */
export const selectHotelGroupsList = createSelector(
  [selectDomain],
  (state) => state.hotelGroupsList
);
/**
 *  Getting existed hotel group state to dashboard component
 */
export const selectExistedHotelGroup = createSelector(
  [selectDomain],
  (state) => state.existedHotelGroup
);
/**
 *  Getting selected hotel group name state to dashboard component
 */
export const selectHotelGroupName = createSelector([selectDomain], (state) => state.hotelGroupName);
/**
 *  Getting selected hotel group query state to dashboard component
 */
export const selectHotelGroupQuery = createSelector(
  [selectDomain],
  (state) => state.hotelGroupQuery
);
/**
 *  Getting is organization configuration not found state to the component
 */
export const selectIsConfigurationNotExist = createSelector(
  [selectDomain],
  (state) => state.isConfigurationNotExist
);
/**
 * Getting selected hotel name state to dashboard component
 */
export const selectHotelName = createSelector([selectDomain], (state) => state.hotelName);
/**
 * Getting hotel state to hotel component
 */
export const selectHotels = createSelector([selectDomain], (hotelState) => hotelState.hotels);
