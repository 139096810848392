/* eslint-disable no-underscore-dangle */
import { isEmpty } from 'modules/common/helpers/object';
import dataHandler from '../common/utils/dataHandler';
import replaceField from '../common/utils/query-modifier';
/**
 * Generate query based on selected breakdown sub categories
 * @param {Array} subCategories
 * @returns
 */
export const generateQuery = (field, subCategories) => {
  if (isEmpty(subCategories)) return null;
  const categoryLabel = subCategories?.map((element) => {
    if (element.label.indexOf(' ') >= 0) return `'${element.label}'`;
    return element.label;
  });
  return categoryLabel?.map((element) => `${field} EQUAL ${element}`).join(' OR ');
};

/**
 * To get Hotel List and focus on values
 * @param {Boolean} isDemoUser - Whether current user is a demo user
 * @param {Object} payload - Payload of getting hotels list action
 * @returns
 */
export const getHotelGroupFocusOnValue = (payload, hotelList) => {
  const hotelGroupDropdownList =
    payload?.results
      ?.filter((a) => a?.hotelGroups?.hotelQuery)
      ?.map((data) => ({
        id: data?.hotelGroups?.hotelGroupName,
        label: data?.hotelGroups?.hotelGroupName,
        query: data?.hotelGroups?.hotelQuery,
        hotels: dataHandler(replaceField(data?.hotelGroups?.hotelQuery), hotelList),
      }))
      ?.sort(
        (element1, element2) =>
          element1?.label && element2?.label && element1?.label?.localeCompare(element2?.label)
      ) ?? [];
  //
  return hotelGroupDropdownList;
};
