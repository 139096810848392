/**
 * Replaces all occurrences of the first word outside quotes in a query with a specified field.
 * @param {string} query - The input query to modify.
 * @param {string} field - The field to replace the first word with.
 * @returns {string} - The modified query with the first word replaced by the specified field.
 */

const replaceField = (query, field = 'label') => {
  const words = query.split(/\s+/);
  const firstWord = words[0];
  let withinQuotes = false;
  // Replace all occurrences of the first word (outside quotes) with "label"
  for (let i = 0; i < words.length; i += 1) {
    const currentWord = words[i];
    if (currentWord.startsWith('"')) {
      withinQuotes = true;
    }
    if (currentWord.endsWith('"')) {
      withinQuotes = false;
    }
    if (!withinQuotes && currentWord === firstWord) {
      words[i] = field;
    }
  }
  const modifiedquery = words.join(' ');
  //
  return modifiedquery;
};
//
export default replaceField;
