import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'modules/transactions/slice';
//
const selectDomain = (state) => state['feature/transaction'] || initialState;

/**
 * Getting transaction widget booking date state to transaction widget component
 */
export const selectTransactionBookingDate = createSelector(
  [selectDomain],
  (state) => state.transactionBookingDate
);
/**
 * Getting chart breakdown state to transaction widget component
 */
export const selectBreakdown = createSelector([selectDomain], (state) => state.chartBreakdown);

/**
 * Getting transaction report filter list state to transaction widget component
 */
export const selectTransactionFilterList = createSelector(
  [selectDomain],
  (state) => state.transactionFilterList
);

/**
 * Getting transaction otb state for transaction widget component
 */
export const selectTransactionOtb = createSelector(
  [selectDomain],
  (state) => state?.transactionOtb
);

/**
 *Getting is detailed in transaction tab state for transaction widget component
 */
export const selectIsDetailed = createSelector([selectDomain], (state) => state?.isDetailed);

/**
 *Getting transaction visual state for the component
 */
export const selectTransactionVisual = createSelector(
  [selectDomain],
  (state) => state?.transactionVisuals
);
/**
 *Getting is transaction hotel comparison state for the component
 */
export const selectTransactionHotelComparison = createSelector(
  [selectDomain],
  (state) => state?.isTransactionHotelComparison
);
/**
 *  Getting trend segment view focus on state to trend  component
 */
export const selectTransactionSegmentFocusOn = createSelector(
  [selectDomain],
  (state) => state.transactionSegmentFocusOn
);
/**
 *  Getting is transaction view is on the arrival/departure or stay date component
 */
export const selectTransactionDateType = createSelector(
  [selectDomain],
  (state) => state.transactionDateType
);
/**
 * Getting selected hotel name state to transaction component
 */
export const selectHotelName = createSelector([selectDomain], (state) => state.hotelName);
/**
 *  Getting all hotel list state to transaction component
 */
export const selectAllHotelList = createSelector([selectDomain], (state) => state.allHotelList);
/**
 * Getting embed token state to transaction component
 */
export const selectEmbedToken = createSelector([selectDomain], (state) => state.embedTokenDetails);
/**
 * Getting target date state to dashboard component
 */
export const selectTargetDate = createSelector([selectDomain], (state) => state.targetDate);
/**
 * Getting visual filters state to dashboard component
 */
export const selectVisualFilters = createSelector(
  [selectDomain],
  (state) => state.visualFilters?.results
);
/**
 * Getting dynamic breakdown state for dashboard component
 */
export const selectBreakdownDetails = createSelector([selectDomain], (state) => state.breakdown);
/**
 *  Getting embed token expiry state to dashboard component
 */
export const selectEmbedTokenExpiry = createSelector(
  [selectDomain],
  (state) => state.embedTokenDetails?.expiry
);
/**
 *  Getting latest updated hotel list state to dashboard component
 */
export const selectLatestUpdatedHotelsList = createSelector(
  [selectDomain],
  (state) => state.latestUpdatedHotelList?.results
);
/**
 *  Getting transaction visual display state to the component
 */
export const selectTransactionIsDisplay = createSelector(
  [selectDomain],
  (state) => state.transactionSetIsDisplay
);
/**
 *  Getting is aggregate state to the component
 */
export const selectIsDeAggregate = createSelector([selectDomain], (state) => state.isDeAggregate);
/**
 *Getting transaction de-aggregated visual state for the component
 */
export const selectTransactionDeAggregatedVisual = createSelector(
  [selectDomain],
  (state) => state?.transactionDeAggregatedVisuals
);
