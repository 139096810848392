import { generateCodeChallenge, generateCodeVerifier } from '../helpers/pkce';
import { authenticationUrl } from '../constants/authentication';

export default async () => {
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  localStorage.setItem('loginCodeVerifier', codeVerifier);
  const authUrlWithChallenge = `${authenticationUrl}&code_challenge=${codeChallenge}`;
  setTimeout(() => window.location.replace(authUrlWithChallenge), 700);
};
