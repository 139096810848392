import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { userInvitesActions } from 'modules/user-invitation/slice';
import { notificationActions } from 'modules/common/notifications/slice';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import { Alert } from './style';
/**
 * Modal to resend user invitation
 * @returns
 */
const ReInviteUserDialog = ({ open, onClose, email, status, invitationId }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  //
  const modalAction = (action) => {
    if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
    onClose(action);
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      modalAction(false);
    }
  }, [notification]);
  //
  return (
    <Dialog
      open={open}
      onClose={() => modalAction(false)}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogContent>
        <Formik
          onSubmit={() =>
            dispatch(userInvitesActions.resendInvite({ email, organizationId, invitationId }))
          }
          initialValues=""
        >
          {({ handleSubmit, isSubmitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              {(status === 'Expired' || status === 'Pending') && (
                <>
                  <Typography variant="h6" paddingBottom={3} align="center">
                    {status === 'Pending' && 'User invitation is pending. '}
                    <br />
                    Resend invitation to {email}?
                  </Typography>
                  <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      disabled={isSubmitting}
                    >
                      Yes
                    </Button>
                    <Button onClick={() => modalAction(false)} variant="contained" color="primary">
                      Cancel
                    </Button>
                  </DialogActions>
                </>
              )}
              {status === 'Accepted' && (
                <>
                  <Typography variant="h6" paddingBottom={3} align="center">
                    User has already accepted the invitation
                  </Typography>
                  <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={() => modalAction(false)} variant="contained" color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </>
              )}
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
//
export default ReInviteUserDialog;
