/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from '../sign-in/slice';

/**
 * Define the user invites feature initial state
 */
export const initialState = {
  loading: false,
  userInvitesList: [],
  usersList: [],
  roleList: [],
};
/**
 * Here mentioned all the user invites feature related actions
 */
export const userInvitesSlice = createSlice({
  name: 'feature/user-invites',
  initialState,
  reducers: {
    getUserInvites(state) {
      state.loading = true;
    },
    getUserInvitesSucceeded(state, action) {
      state.loading = false;
      state.userInvitesList = action?.payload?.results;
    },
    getUserInvitesFailed(state) {
      state.loading = false;
    },
    usersList(state) {
      state.loading = true;
    },
    usersListSucceeded(state, action) {
      state.loading = false;
      state.usersList = action?.payload?.results;
    },
    usersListFailed(state) {
      state.loading = false;
    },
    getRoles(state) {
      state.loading = true;
    },
    getRolesSucceeded(state, action) {
      state.loading = false;
      state.roleList = action?.payload?.results;
    },
    getRolesFailed(state) {
      state.loading = false;
    },
    resendInvite(state) {
      state.loading = true;
    },
    resendInviteSucceeded(state) {
      state.loading = false;
    },
    resendInviteFailed(state) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});
//
export const { actions: userInvitesActions } = userInvitesSlice;
