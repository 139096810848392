import { selectEmbedToken, selectEmbedTokenExpiry } from 'modules/transactions/selectors';
import { transactionActions } from 'modules/transactions/slice';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrganizationId } from '../auth/selectors';
import TIME_OUTS from '../constants/time-outs';

const useUpdatePbiToken = () => {
  const dispatch = useDispatch();
  //
  const selectPbiTokenExpiry = useSelector(selectEmbedTokenExpiry);
  const organizationId = useSelector(selectOrganizationId);
  const embedToken = useSelector(selectEmbedToken)
  //
  const tryUpdateToken = useCallback(async () => {
    dispatch(transactionActions.getEmbedToken({ organizationId }));
  }, [organizationId]);
  //
  useEffect(() => {
    let interval;
    if (!embedToken.accessToken) {
      tryUpdateToken()
    }
    if (selectPbiTokenExpiry) {
      interval = setInterval(async () => {
        tryUpdateToken();
      }, new Date(selectPbiTokenExpiry).getTime() - (TIME_OUTS.P_B_TOKEN_REFRESH_TIME * 1000 + new Date().getTime()));
    }
    //
    return () => {
      clearInterval(interval);
    };
  }, [selectPbiTokenExpiry]);
};
//
export default useUpdatePbiToken;
