/* eslint-disable react/no-array-index-key */
import { useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Autocomplete,
  Switch,
  FormControlLabel,
  Stack,
  FormLabel,
} from '@mui/material';
import { FieldArray, Formik } from 'formik';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { notificationActions } from 'modules/common/notifications/slice';
import ERROR_TYPES from 'modules/common/constants/error-types';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import { visualFilterActions } from 'modules/visual-filter/slice';
import { TAG_LIST, TYPE_LIST } from 'modules/visual-filter/constants';
import { addVisualFilterValidation } from 'modules/visual-filter/validation/visual-filter-form-validation';
import { Alert, TextField } from '../style';
import '../index.scss';
/**
 * Form to create visual filter
 * @param {*} param0
 * @returns
 */
const AddVisualFilterForm = ({ open, onClose }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const organizationId = useSelector(selectOrganizationId);
  //
  const modalAction = (action) => {
    if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
    onClose(action);
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      modalAction(false);
    }
  }, [notification]);
  //
  const initialValues = {
    type: 'NAME',
    tableName: '',
    columnName: '',
    startDate: new Date(),
    endDate: new Date(),
    isDefault: false,
    tags: [],
    values: '',
  };
  //
  const onSubmitAddVisual = async (values) => {
    let valueObj;
    let value;
    if (typeof values?.values === 'string') value = [values?.values];
    else value = values?.values;
    switch (values?.type) {
      case 'DATE':
        valueObj = {
          type: values?.type,
          tableName: values?.tableName,
          columnName: values?.columnName,
          startDate: values?.startDate,
          endDate: values?.endDate,
          tags: values?.tags,
        };
        break;
      case 'ALL':
        valueObj = {
          type: values?.type,
          tableName: values?.tableName,
          columnName: values?.columnName,
          tags: values?.tags,
        };
        break;
      case 'NAME':
        valueObj = {
          type: values?.type,
          tableName: values?.tableName,
          columnName: values?.columnName,
          isDefault: values?.isDefault,
          tags: values?.tags,
          values: value,
        };
        break;
      default:
        valueObj = {
          type: values?.type,
          tableName: values?.tableName,
          columnName: values?.columnName,
          isDefault: values?.isDefault,
          tags: values?.tags,
          values: values?.values,
        };
        break;
    }
    dispatch(visualFilterActions.createVisualFilter({ ...valueObj, organizationId }));
  };
  //
  const handleAddMore = (e, value, values, setFieldValue) => {
    const subValueList = [...values.values];
    if (value === 'ALIGNED' || value === 'UNALIGNED') {
      subValueList.push({
        label: '',
        value: '',
        isDefault: false,
        columnName: '',
      });
    } else {
      subValueList.push({
        label: '',
        value: '',
        columnName: '',
      });
    }
    setFieldValue('values', subValueList);
  };
  //
  return (
    <Dialog open={open} onClose={() => modalAction(false)}>
      <DialogTitle>Add Visual Filter</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={addVisualFilterValidation}
          onSubmit={onSubmitAddVisual}
        >
          {({
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
            isSubmitting,
            touched,
            values,
            errors,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <Autocomplete
                size="small"
                value={values.type}
                fullWidth
                disableClearable
                name="type"
                limitTags={3}
                isOptionEqualToValue={(option, value) => option === value}
                options={TYPE_LIST || []}
                onChange={(e, value) => {
                  setFieldValue('type', value);
                  if (value !== 'DATE' && value !== 'ALL' && value !== 'NAME') {
                    handleAddMore(e, value, values, setFieldValue);
                  }
                }}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}
                    label="Type"
                    size="small"
                    {...params}
                    fullWidth
                    my={2}
                  />
                )}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: 0,
                    height: 'fit-content',
                  },
                  marginY: 2,
                  paddingTop: 2,
                }}
              />
              <TextField
                name="tableName"
                value={values.tableName}
                error={Boolean(touched.tableName && errors.tableName)}
                helperText={touched.tableName && errors.tableName}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                label="Table Name"
                size="small"
              />
              <TextField
                name="columnName"
                value={values.columnName}
                error={Boolean(touched.columnName && errors.columnName)}
                helperText={touched.columnName && errors.columnName}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                label="column Name"
                size="small"
              />
              {values.type === 'DATE' && (
                <>
                  <DesktopDatePicker
                    name="startDate"
                    inputFormat="MM/dd/yyyy"
                    label="Start Date"
                    onBlur={handleBlur}
                    value={values.startDate}
                    maxDate={values.endDate}
                    onChange={(value) => {
                      setFieldValue('startDate', value);
                    }}
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                    renderInput={(params) => (
                      <TextField
                        name="startDate"
                        label="Start Date"
                        {...params}
                        fullWidth
                        my={2}
                        error={Boolean(touched.startDate && errors.startDate)}
                        helperText={touched.startDate && errors.startDate}
                        size="small"
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                  />
                  <DesktopDatePicker
                    name="endDate"
                    inputFormat="MM/dd/yyyy"
                    label="End Date"
                    onBlur={handleBlur}
                    value={values.endDate}
                    onChange={(value) => setFieldValue('endDate', value)}
                    error={Boolean(touched.endDate && errors.endDate)}
                    helperText={touched.endDate && errors.endDate}
                    minDate={values.startDate}
                    renderInput={(params) => (
                      <TextField
                        name="endDate"
                        label="End Date"
                        {...params}
                        fullWidth
                        my={2}
                        error={Boolean(touched.endDate && errors.endDate)}
                        helperText={touched.endDate && errors.endDate}
                        size="small"
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                  />
                </>
              )}
              {values.type !== 'DATE' && values.type !== 'ALL' && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  paddingTop={2}
                  paddingLeft={1}
                >
                  Default
                  <FormControlLabel
                    control={
                      <Switch
                        name="isDefault"
                        checked={values.isDefault}
                        onChange={(event) => setFieldValue('isDefault', event.target.checked)}
                      />
                    }
                  />
                </Stack>
              )}
              <Autocomplete
                size="small"
                value={values.tags}
                fullWidth
                multiple
                disableClearable
                disableCloseOnSelect
                name="tags"
                limitTags={3}
                isOptionEqualToValue={(option, value) => option === value}
                options={TAG_LIST || []}
                onChange={(e, value) => setFieldValue('tags', value)}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(touched.tags && errors.tags)}
                    helperText={touched.tags && errors.tags}
                    label="Tags"
                    size="small"
                    {...params}
                    fullWidth
                    my={2}
                  />
                )}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: 0,
                    height: 'fit-content',
                  },
                  marginY: 2,
                }}
              />
              {values.type === 'NAME' && (
                <TextField
                  name="values"
                  value={values.values}
                  error={Boolean(touched.values && errors.values)}
                  helperText={touched.values && errors.values}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                  label="Values"
                  my={2}
                  size="small"
                />
              )}
              {values.type !== 'DATE' && values.type !== 'ALL' && values.type !== 'NAME' && (
                <>
                  <Stack direction="row" justifyContent="flex-end" paddingTop={2} paddingLeft={1}>
                    <Button
                      onClick={(e) => {
                        handleAddMore(e, values.type, values, setFieldValue);
                      }}
                    >
                      Add Values
                    </Button>
                  </Stack>
                  <FieldArray name="values">
                    {(arrayHelpers) =>
                      values.values &&
                      values.values.length > 0 &&
                      values?.values?.map((val, i) => {
                        const valueErrors = (errors.values?.length && errors.values[i]) || {};
                        const valueTouched = (touched.values?.length && touched.values[i]) || {};
                        return (
                          <div key={i}>
                            <FormLabel>Value {i + 1}</FormLabel>
                            <TextField
                              name={`values.${i}.label`}
                              value={values.values[i].label || ''}
                              error={Boolean(valueTouched.label && valueErrors.label)}
                              helperText={valueTouched.label && valueErrors.label}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                              label="Label"
                              my={2}
                              size="small"
                            />
                            <TextField
                              name={`values.${i}.value`}
                              value={values.values[i].value || ''}
                              error={Boolean(valueTouched.value && valueErrors.value)}
                              helperText={valueTouched.value && valueErrors.value}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                              label="Value"
                              my={2}
                              size="small"
                            />
                            <TextField
                              name={`values.${i}.columnName`}
                              value={values.values[i].columnName || ''}
                              error={Boolean(valueTouched.columnName && valueErrors.columnName)}
                              helperText={valueTouched.columnName && valueErrors.columnName}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                              label="Column Name"
                              my={2}
                              size="small"
                            />
                            {(values.type === 'ALIGNED' || values.type === 'UNALIGNED') && (
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                paddingTop={2}
                                paddingLeft={1}
                              >
                                Default Sub Value
                                <FormControlLabel
                                  control={
                                    <Switch
                                      name={`values.${i}.isDefault`}
                                      checked={values.values[i].isDefault}
                                      onChange={(event) =>
                                        setFieldValue(`values.${i}.isDefault`, event.target.checked)
                                      }
                                    />
                                  }
                                />
                              </Stack>
                            )}
                            <Stack
                              direction="row"
                              justifyContent="flex-end"
                              paddingTop={2}
                              paddingLeft={1}
                            >
                              <Button
                                onClick={() => {
                                  arrayHelpers.remove(i);
                                }}
                                disabled={values.values.length === 1}
                              >
                                Remove Value
                              </Button>
                            </Stack>
                          </div>
                        );
                      })
                    }
                  </FieldArray>
                </>
              )}
              <DialogActions>
                <Button onClick={() => modalAction(false)} variant="contained" color="primary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="success" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
//
export default AddVisualFilterForm;
