import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'modules/common/components';
import { selectLoader } from 'modules/user-invitation/selectors';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { withTheme } from '@emotion/react';
import { selectNotification } from 'modules/common/notifications/selectors';
import AuditLogsTable from '../audit-logs-table';
import { auditLogsActions } from '../../slice';
import AuditLogsFilter from '../audit-logs-filter';
import { selectOrganizationId } from '../../../common/auth/selectors';
/**
 * User Invitation view to manage user invitations
 * @returns
 */
const AuditLogsView = ({ theme }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const organizationId = useSelector(selectOrganizationId);
  const loading = useSelector(selectLoader);
  //
  useEffect(() => {
    dispatch(
      auditLogsActions.auditLogs({
        organizationId,
        query: `limit=${PAGINATION.AUDIT_LOGS_PER_PAGE_COUNT}&sortBy=createdAt:desc`,
      })
    );
  }, [notification]);
  //
  return (
    <Loader loading={loading}>
      <Card>
        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
          <Grid container direction="row" justifyContent="space-between" pb={4}>
            <Typography variant="h3">Audit Logs</Typography>
          </Grid>
          <Grid container direction="row" spacing={2} justifyContent="space-between">
            <Grid item sx={{ flexGrow: 1, flexBasis: 1 }}>
              <AuditLogsFilter />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} justifyContent="space-between">
            <Grid item sx={{ flexGrow: 1, flexBasis: 1 }}>
              <AuditLogsTable />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Loader>
  );
};
//
export default withTheme(AuditLogsView);
