/* eslint-disable no-unused-vars */
import {
  FormControl,
  Grid,
  Box,
  IconButton,
  Alert,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Autocomplete,
  Button,
  Checkbox,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
  selectUserById,
  selectRoleList,
  selectUserHotelsList,
  selectHotelsSelectionList,
  selectAssignedHotelGroups,
  selectHotelGroups,
} from 'modules/users/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import {
  ADMIN_ROLE,
  SUPER_ADMIN_ROLE,
  USER_ROLE,
  DEMO_ROLE,
  PREFERRED_ROLE,
  PREFERRED_ADMIN_ROLE,
} from 'modules/common/constants/roles';
import ROLE_NAMES from 'modules/common/constants/user-roles';
import TextField from '@mui/material/TextField';
import { selectOrganizationId, selectAuthUser } from 'modules/common/auth/selectors';
import { selectNotification } from 'modules/common/notifications/selectors';
import { Trash, X, Save } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import PAGINATION from 'modules/common/constants/pagination-limits';
import ORDER_BY from 'modules/common/constants/order-by';
import { isEmpty } from 'modules/common/helpers/object';
import { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { usersActions } from 'modules/users/slice';
import { Modal } from 'modules/common/components';
import { updateUserValidation } from '../../validation/add-user-form-validation';
import { Typography } from './style';
import ChangePasswordDialog from '../change-password';

const FormStyles = { borderRadius: 10 };
//
const UserDataDrawerView = ({ show, isShow, userId }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const currentUser = useSelector(selectAuthUser);
  const hotelList = useSelector(selectHotelsSelectionList);
  const hotels = useSelector(selectUserHotelsList);
  const user = useSelector((state) => selectUserById(state, userId));
  const roleList = useSelector(selectRoleList);
  const assignedHotelGroup = useSelector(selectAssignedHotelGroups);
  const hotelGroupList = useSelector(selectHotelGroups);
  //
  const [isDelete, setDelete] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [isEditUnavailable, setEditUnavailable] = useState(false);
  const [isDeleteUnavailable, setDeleteUnavailable] = useState(false);
  const [isHotelEditUnavailable, setHotelEditUnavailable] = useState(false);
  const [isSuperAdminAvailable, setSuperAdminAvailable] = useState(false);
  const [isRoleEditUnavailable, setRoleEditUnavailable] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    userHotels: [],
    hotelSet: [],
  });
  const formElement = useRef(null);
  const allSelected = hotelList?.length === selectedOptions?.length ?? false;

  const handleToggleOption = (selectedOps) => {
    setSelectedOptions(selectedOps);
  };
  const handleClearOptions = () => setSelectedOptions([]);
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      if (!isEmpty(hotelList)) {
        setSelectedOptions(hotelList);
      }
    } else {
      handleClearOptions();
    }
  };
  const handleToggleSelectAll = () => handleSelectAll && handleSelectAll(!allSelected);
  const filter = createFilterOptions();
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      isShow(false);
    }
  }, [notification]);
  //
  useEffect(() => {
    if (!isEmpty(userId) && organizationId) {
      const query = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&sortBy=name:${ORDER_BY.ASCENDING}`;
      dispatch(
        usersActions.getHotelsList({
          organizationId,
          userId,
          query,
        })
      );
      dispatch(
        usersActions.getAssignedHotelGroupList({
          organizationId,
          userId,
        })
      );
    }
  }, [organizationId, userId]);
  //
  useEffect(() => {
    setEditUnavailable(false);
    setDeleteUnavailable(false);
    dispatch(usersActions.getRoles({ organizationId }));
    if (user) {
      setSuperAdminAvailable(user?.roles[0]?.name === SUPER_ADMIN_ROLE);
      setRoleEditUnavailable(
        (currentUser?.roles[0]?.name === SUPER_ADMIN_ROLE &&
          user?.roles?.[0]?.name === SUPER_ADMIN_ROLE) ||
          (currentUser?.roles[0]?.name === ADMIN_ROLE &&
            user?.roles?.[0]?.name === SUPER_ADMIN_ROLE) ||
          (currentUser?.roles[0]?.name === PREFERRED_ADMIN_ROLE &&
            user?.roles?.[0]?.name === SUPER_ADMIN_ROLE) ||
          currentUser?.roles[0]?.name === USER_ROLE ||
          currentUser?.roles[0]?.name === DEMO_ROLE ||
          currentUser?.roles[0]?.name === PREFERRED_ROLE
      );
      setInitialValues({
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        role: ROLE_NAMES?.filter((userRole) => userRole.key === user?.roles?.[0]?.name).map(
          (roleName) => roleName?.label
        )[0],
        userHotels:
          hotels?.length > 0
            ? hotels?.map((hotelData) => ({
                id: hotelData?.hotel?.id,
                label: hotelData?.hotel?.name,
              }))
            : hotelList,
        hotelSet: assignedHotelGroup,
      });
      if (currentUser?.id !== user?.id) {
        if (currentUser?.roles[0]?.name !== SUPER_ADMIN_ROLE) {
          if (
            (user?.roles[0]?.name === USER_ROLE ||
              user?.roles[0]?.name === DEMO_ROLE ||
              user?.roles[0]?.name === PREFERRED_ROLE) &&
            (currentUser?.roles?.[0]?.name === ADMIN_ROLE ||
              currentUser?.roles?.[0]?.name === PREFERRED_ADMIN_ROLE)
          ) {
            setEditUnavailable(false);
            setDeleteUnavailable(false);
            setHotelEditUnavailable(false);
          } else if (
            (user?.roles[0]?.name === ADMIN_ROLE && currentUser?.roles[0]?.name === ADMIN_ROLE) ||
            (user?.roles[0]?.name === PREFERRED_ADMIN_ROLE &&
              currentUser?.roles[0]?.name === PREFERRED_ADMIN_ROLE)
          ) {
            setEditUnavailable(true);
            setDeleteUnavailable(true);
            setHotelEditUnavailable(true);
          } else {
            setEditUnavailable(true);
            setDeleteUnavailable(true);
            setHotelEditUnavailable(true);
          }
        }
      } else if (
        currentUser?.roles[0]?.name === USER_ROLE ||
        user?.roles[0]?.name === USER_ROLE ||
        currentUser?.roles[0]?.name === DEMO_ROLE ||
        user?.roles[0]?.name === DEMO_ROLE ||
        currentUser?.roles[0]?.name === PREFERRED_ROLE ||
        user?.roles[0]?.name === PREFERRED_ROLE
      ) {
        setDeleteUnavailable(true);
        setHotelEditUnavailable(true);
      } else if (
        currentUser?.roles[0]?.name === ADMIN_ROLE ||
        user?.roles[0]?.name === ADMIN_ROLE ||
        currentUser?.roles[0]?.name === PREFERRED_ADMIN_ROLE ||
        user?.roles[0]?.name === PREFERRED_ADMIN_ROLE
      ) {
        setHotelEditUnavailable(true);
      }
    }
  }, [user, hotels, assignedHotelGroup]);
  //
  const deleteUser = (payload) => {
    dispatch(usersActions.deleteUser(payload));
    setDelete(false);
  };
  //
  const onSubmitUpdateUser = async (values) => {
    const valueObj = {
      email: values?.email,
      firstName: values?.firstName,
      lastName: values?.lastName,
    };
    dispatch(
      usersActions.assignHotelGroups({
        hotelGroup: [...values.hotelSet]?.map((hotel) => hotel?.label),
        organizationId,
        userId,
      })
    );
    //
    const roleName = ROLE_NAMES.filter((userRole) => userRole?.label === values?.role).map(
      (r) => r.key
    );
    const roleId = roleList
      .filter((userRole) => userRole?.name === roleName[0])
      .map((key) => key.id);
    dispatch(usersActions.updateUser({ ...valueObj, organizationId, userId }));
    if (roleId) {
      dispatch(
        usersActions.updateRole({
          roles: roleId,
          organizationId,
          userId,
        })
      );
    }
    const hotelIds = allSelected ? [] : values?.userHotels?.map((h) => h.id);
    dispatch(
      usersActions.updateHotelsList({
        hotels: hotelIds,
        organizationId,
        userId,
      })
    );
  };
  //
  return (
    <Box
      xs={12}
      md={4}
      ml={4}
      mt={2}
      component={Grid}
      container
      item
      display="flex"
      direction="column"
      sx={{
        backgroundColor: 'white',
        flexShrink: 0,
        display: show ? 'flex' : 'none',
      }}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={updateUserValidation}
        onSubmit={onSubmitUpdateUser}
      >
        {({
          errors,
          dirty,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleReset,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container direction="row" justifyContent="space-between" px={2} mb={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                {user?.firstName} {user?.lastName}
              </Typography>
              <Grid>
                {!isEditUnavailable && (
                  <Tooltip title="Update">
                    <IconButton
                      disabled={isSubmitting}
                      onClick={() =>
                        errors.email || errors.firstName || errors.lastName ? '' : setUpdate(true)
                      }
                      color="inherit"
                      size="small"
                    >
                      <Save />
                    </IconButton>
                  </Tooltip>
                )}
                {!isDeleteUnavailable && (
                  <Tooltip title="Delete">
                    <IconButton color="error" size="small" onClick={() => setDelete(true)}>
                      <Trash />
                    </IconButton>
                  </Tooltip>
                )}
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => {
                    if (dirty) {
                      handleReset();
                    }
                    isShow(false);
                  }}
                >
                  <X />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={2} direction="column">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    label="Email"
                    variant="outlined"
                    size="large"
                  />
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    label="First name"
                    variant="outlined"
                    size="large"
                  />
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    label="Last name"
                    variant="outlined"
                    size="large"
                  />
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4}>
                <FormControl sx={FormStyles} fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    value={values.role}
                    label="Role"
                    name="role"
                    onChange={handleChange}
                    // disabled={isRoleEditUnavailable}
                  >
                    {isSuperAdminAvailable
                      ? ROLE_NAMES?.map((type) => (
                          <MenuItem key={type.key} value={type.label}>
                            {type.label}
                          </MenuItem>
                        ))
                      : ROLE_NAMES?.filter((userRole) => userRole.key !== SUPER_ADMIN_ROLE).map(
                          (type) => (
                            <MenuItem key={type.key} value={type.label}>
                              {type.label}
                            </MenuItem>
                          )
                        )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4}>
                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  disableClearable
                  disableCloseOnSelect
                  filterSelectedOptions
                  disabled={isHotelEditUnavailable}
                  name="userHotels"
                  limitTags={2}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: '90%',
                        marginLeft: '10%',
                      },
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      padding: 0,
                      height: 'fit-content',
                    },
                  }}
                  onBlur={handleBlur}
                  options={hotelList ?? []}
                  value={values.userHotels}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return [{ id: 0, label: 'Select All' }, ...filtered];
                  }}
                  renderOption={(props, option, { selected }) => {
                    const selectAllProps =
                      option.label === 'Select All' ? { checked: allSelected } : {};
                    return (
                      <li {...props}>
                        <Checkbox checked={selected} {...selectAllProps} />
                        {option.label}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={Boolean(touched.userHotels && errors.userHotels)}
                      helperText={touched.userHotels && errors.userHotels}
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: 'auto',
                          overflow: 'auto',
                          maxHeight: 100,
                        },
                      }}
                      {...params}
                      label="Hotels"
                    />
                  )}
                  onChange={(event, selectedOps, reason) => {
                    if (reason === 'selectOption' || reason === 'removeOption') {
                      if (
                        selectedOps.find((option) => option.label === 'Select All') &&
                        selectedOps?.length === hotelList.length + 1
                      ) {
                        handleClearOptions();
                        setFieldValue('userHotels', []);
                      } else if (selectedOps.find((option) => option.label === 'Select All')) {
                        handleToggleSelectAll();
                        if (!isEmpty(hotelList)) {
                          setFieldValue('userHotels', hotelList);
                        }
                      } else {
                        handleToggleOption(selectedOps);
                        setFieldValue('userHotels', selectedOps);
                      }
                    } else if (reason === 'clear') {
                      handleClearOptions();
                      setFieldValue('userHotels', []);
                    }
                  }}
                />
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4}>
                <Autocomplete
                  multiple
                  fullWidth
                  value={values.hotelSet}
                  size="small"
                  disableClearable
                  disableCloseOnSelect
                  filterSelectedOptions
                  limitTags={2}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      padding: 0,
                      height: 'fit-content',
                    },
                  }}
                  onBlur={handleBlur}
                  name="hotelSet"
                  options={hotelGroupList ?? []}
                  getOptionLabel={(option) => (option.label ? option.label : '')}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, value) => setFieldValue('hotelSet', value)}
                  disabled={hotelGroupList?.length < 1}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: 'auto',
                          overflow: 'auto',
                          maxHeight: 100,
                        },
                      }}
                      {...params}
                      label="Hotel Sets"
                      size="small"
                      color="primary"
                    />
                  )}
                />
              </Grid>
              <Modal
                open={isDelete}
                handleClose={() => setDelete(false)}
                title="Delete User"
                content="Are you sure you want to delete the user?"
                handleSuccess={() =>
                  deleteUser({
                    organizationId,
                    userId: user?.id,
                  })
                }
                closeLabel="Cancel"
                successLabel="Delete"
                variant="contained"
                color="error"
              />
              <Modal
                open={isUpdate}
                handleClose={() => {
                  if (dirty) {
                    handleReset();
                  }
                  setUpdate(false);
                }}
                title="Update User"
                content="Are you sure you want to update the user ?"
                handleSuccess={() => {
                  formElement.current?.click();
                  setUpdate(false);
                }}
                closeLabel="Cancel"
                successLabel="Update"
                variant="contained"
                color="primary"
              />
            </Grid>
            <input ref={formElement} style={{ visibility: 'hidden' }} type="submit" />
          </form>
        )}
      </Formik>
      {(user?.roles?.[0]?.name === USER_ROLE ||
        user?.roles?.[0]?.name === DEMO_ROLE ||
        user?.roles?.[0]?.name === PREFERRED_ROLE) && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="flex-end"
          paddingRight={4}
          paddingBottom={4}
        >
          <Button variant="contained" onClick={() => setOpen(true)}>
            Change Password
          </Button>
          <ChangePasswordDialog open={open} onClose={() => setOpen(false)} userId={user?.id} />
        </Grid>
      )}
    </Box>
  );
};
//
export default UserDataDrawerView;
