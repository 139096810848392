import { withTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import {
  selectUserInvitesList,
  selectRoleList,
  selectUsers,
} from 'modules/user-invitation/selectors';
import { useEffect, useState } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { TABLE_HEADER_FONT_COLOR } from 'modules/common/constants/style';
import { INVITATION_STATUS } from 'modules/user-invitation/constants';
import { Grid, Tooltip } from '@mui/material';
import { isEmpty } from 'modules/common/helpers/object';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import { differenceInMinutes, format } from 'date-fns';
import { Send } from '@mui/icons-material';
import './index.scss';
import { ENVIRONMENT } from 'config';
import ReInviteUserDialog from '../invite-user/reinvite-modal';
/**
 * Data grid to display all user invitations
 * @returns
 */
const UserInvitaionTable = () => {
  //
  const userInvitesList = useSelector(selectUserInvitesList);
  const rolesList = useSelector(selectRoleList);
  const userList = useSelector(selectUsers);
  //
  const [filteredUserInvites, setFilteredUserInvites] = useState([]);
  const [invitationId, setInvitationId] = useState(null);
  const [emailId, setEmailId] = useState('');
  const [status, setStatus] = useState('');
  const [showInvite, setShowInvite] = useState(false);
  //
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      hide: true,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email Address',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'sentOn',
      headerName: 'Invitation Sent On',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'Role',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'sentBy',
      headerName: 'Invited By',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'remainingClickCount',
      headerName: 'Remaining Click Count',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Resend Invite',
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Resend">
              <Send size={14} />
            </Tooltip>
          }
          label="Edit"
          className="textPrimary"
          onClick={() => {
            setInvitationId(id);

            setShowInvite(true);
          }}
          color="inherit"
        />,
      ],
    },
  ];
  //
  useEffect(() => {
    if (!isEmpty(userInvitesList)) {
      const formattedUserInvites = userInvitesList?.map((userInvites) => {
        const inviteStatus =
          userInvites?.status === INVITATION_STATUS.PENDING &&
          differenceInMinutes(new Date(), new Date(userInvites?.sentOn)) <=
            ENVIRONMENT.USER_INVITATION_EXPIRATION_MINUTES
            ? capitalizeFirst(INVITATION_STATUS.PENDING)
            : capitalizeFirst(INVITATION_STATUS.EXPIRED);
        return {
          id: userInvites?.id,
          email: userInvites?.email,
          sentOn: format(new Date(userInvites?.sentOn), 'MM-dd-yyyy HH:mm:ss OOOO'),
          sentBy: userList
            ? userList?.filter((filter) => filter?.id === userInvites?.sentBy)[0]?.username
            : '',
          status:
            userInvites?.status === INVITATION_STATUS.PENDING
              ? inviteStatus
              : capitalizeFirst(userInvites?.status),
          role: capitalizeFirst(userInvites?.role),
          remainingClickCount: userInvites?.remainingClickCount,
        };
      });
      setFilteredUserInvites(formattedUserInvites);
    }
  }, [userInvitesList, rolesList, userList]);
  //
  useEffect(() => {
    setEmailId(filteredUserInvites?.filter((filter) => filter?.id === invitationId)[0]?.email);
    setStatus(filteredUserInvites?.filter((filter) => filter?.id === invitationId)[0]?.status);
  }, [filteredUserInvites, invitationId]);
  //
  return (
    <Grid container sx={{ height: 600 }}>
      <DataGrid
        sx={{
          overflowWrap: 'break-word',
          '& .super-app-theme--header': {
            backgroundColor: 'white',
            color: TABLE_HEADER_FONT_COLOR,
          },
          '& .MuiDataGrid-main': {
            backgroundColor: 'white',
          },
          '.MuiDataGrid-footerContainer': {
            backgroundColor: 'white',
          },
        }}
        rowHeight={50}
        rows={filteredUserInvites || []}
        columns={columns}
      />
      <ReInviteUserDialog
        open={showInvite}
        onClose={() => setShowInvite(false)}
        email={emailId}
        status={status}
        invitationId={invitationId}
      />
    </Grid>
  );
};
//
export default withTheme(UserInvitaionTable);
