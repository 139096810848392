import styled from '@emotion/styled';
import { AppBar as MuiAppBar, IconButton as MuiIconButton, Avatar } from '@mui/material';

export const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;
//
export const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;
//
export const SmallAvatar = styled(Avatar)`
  width: 100px;
  text-align: left;
`;
