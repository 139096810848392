import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
//
const selectDomain = (state) => state['feature/visual'] || initialState;
/**
 * Getting loader state to visual component
 */
export const selectLoader = createSelector([selectDomain], (visualState) => visualState.loading);
/**
 * Getting visuals state to visual component
 */
export const selectVisuals = createSelector([selectDomain], (visualState) => visualState.visuals);
/**
 * Getting reports state to visual component
 */
export const selectReports = createSelector([selectDomain], (visualState) => visualState.reports);
/**
 * Getting reports drop down list state to visual component
 */
export const selectReportsDropdownList = createSelector([selectDomain], (visualState) =>
  visualState?.reports?.map((data) => ({ label: data.name, id: data.id }))
);
/**
 * Getting reports drop down list state to visual component
 */
export const selectReportPagesDropdownList = createSelector([selectDomain], (visualState) =>
  visualState?.reportPages?.map((data) => ({ label: data.displayName, id: data.name }))
);
/**
 * Getting the visual details by visual id state into visual component
 */
export const selectVisualByID = createSelector(
  [selectDomain],
  (visualState) => visualState.visualById
);
/**
 * Getting is session timed out state for the component
 */
export const selectIsSessionTimedOut = createSelector(
  [selectDomain],
  (visualState) => visualState.isSessionTimedOut
);
