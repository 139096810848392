/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from '../sign-in/slice';
/**
 * Initial states of hotel function are defined here
 */
export const initialState = {
  //
  loading: false,
  hotels: [],
  hotelById: [],
  currencyData: [],
  hotelUsers: [],
};
/**
 * All actions related to hotel feature are defined here
 */
export const hotelSlice = createSlice({
  name: 'feature/hotels',
  initialState,
  reducers: {
    getHotels(state) {
      state.loading = true;
    },
    getHotelsSucceeded(state, action) {
      state.loading = false;
      state.hotels = action?.payload.results;
    },
    getHotelsFailed(state) {
      state.loading = false;
    },
    createHotel(state) {
      state.loading = true;
    },
    createHotelSucceeded(state, action) {
      state.loading = false;
      state.hotels = [...state.hotels, action?.payload];
    },
    createHotelFailed(state) {
      state.loading = false;
    },
    updateHotel(state) {
      state.loading = true;
    },
    updateHotelSucceeded(state, action) {
      state.loading = false;
      state.hotels = state?.hotels?.map((object) =>
        object?.id === action?.payload?.id ? { ...object, ...action.payload } : object
      );
    },
    updateHotelFailed(state) {
      state.loading = false;
    },
    deleteHotel(state) {
      state.loading = true;
    },
    deleteHotelSucceeded(state, action) {
      state.loading = false;
      state.hotels = state?.hotels?.filter((object) => object?.id !== action?.payload?.data?.id);
    },
    deleteHotelFailed(state) {
      state.loading = false;
    },
    getHotelById(state) {
      state.loading = true;
    },
    getHotelByIdSucceeded(state, action) {
      state.loading = false;
      state.hotelById = action?.payload;
    },
    getHotelByIdFailed(state) {
      state.loading = false;
    },
    getCurrencyData(state) {
      state.loading = true;
    },
    getCurrencyDataSucceeded(state, action) {
      state.loading = false;
      state.currencyData = action?.payload.results;
    },
    getCurrencyDataFailed(state) {
      state.loading = false;
    },
    getHotelUserData(state) {
      state.loading = true;
    },
    getHotelUserDataSucceeded(state, action) {
      state.loading = false;
      state.hotelUsers = action?.payload
        ?.filter((user) => user?.username !== null)
        ?.reduce((acc, current) => {
          if (!acc?.some((user) => user?.username === current?.username)) {
            acc?.push(current);
          }
          return acc;
        }, [])
        ?.sort((a, b) => a?.username?.localeCompare(b?.username));
    },
    getHotelUserDataFailed(state) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});
//
export const { actions: hotelActions } = hotelSlice;
