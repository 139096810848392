import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trash, Edit } from 'react-feather';
import { Tooltip, Box } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { TABLE_HEADER_FONT_COLOR } from 'modules/common/constants/style';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { selectHotelsSelectionList } from 'modules/event-configuration/selectors';
import { configureEventCategoriesActions } from 'modules/event-configuration/slice';
import { Modal } from 'modules/common/components';
import EditMinorCategoryDialog from '../edit-minor-category';

/**
 * Editable Table component to list, edit, delete minor event categories
 */
const MinorCategoriesGrid = (props) => {
  const { minorEventCategories, majorEventCategoryId } = props;
  //
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const hotelList = useSelector(selectHotelsSelectionList);
  //
  const [deleteID, setDeleteID] = useState(null);
  const [editId, setEditId] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [openEditMinorCategory, setOpenEditMinorCategory] = useState(false);
  //
  useEffect(() => {
    setRows([]);
    if (minorEventCategories?.results) {
      minorEventCategories?.results.forEach((minorEvent) => {
        const transformMinorEventCategory = {};
        transformMinorEventCategory.id = minorEvent?.id;
        transformMinorEventCategory.name = minorEvent?.name;
        transformMinorEventCategory.applicableHotels = minorEvent?.applicableHotels
          ? minorEvent?.applicableHotels
              ?.map((category) => hotelList.filter((a) => a.id === category).map((b) => b.label)[0])
              .join(',')
          : '';
        setRows((prevRows) => [...prevRows, transformMinorEventCategory]);
      });
    }
  }, [minorEventCategories]);
  //
  const handleDelete = () => {
    dispatch(
      configureEventCategoriesActions.deleteMinorEventCategories({
        organizationId,
        majorEventCategoryId,
        minorEventCategoryId: deleteID,
      })
    );
    setDeleteID(null);
    setOpenDeleteModal(false);
  };
  //
  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'applicableHotels',
      headerName: 'Hotels',
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Cancel">
              <Edit size={14} />
            </Tooltip>
          }
          label="Edit"
          className="textPrimary"
          onClick={() => {
            setEditId(id);
            setOpenEditMinorCategory(true);
          }}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Delete">
              <Trash size={14} />
            </Tooltip>
          }
          label="Delete"
          onClick={() => {
            setDeleteID(id);
            setOpenDeleteModal(true);
          }}
          color="error"
        />,
      ],
    },
  ];
  //
  return (
    <>
      <Box sx={{ height: 500 }} px={4} my={4}>
        <DataGrid
          rows={rows}
          sx={{
            '& .super-app-theme--header': {
              backgroundColor: 'white',
              color: TABLE_HEADER_FONT_COLOR,
            },
            '& .MuiDataGrid-main': {
              backgroundColor: 'white',
            },
            '.MuiDataGrid-footerContainer': {
              backgroundColor: 'white',
            },
          }}
          columns={columns}
        />
      </Box>
      <Modal
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        title="Delete Minor Event Category"
        content="Are you sure you want to delete the minor event category?"
        handleSuccess={handleDelete}
        closeLabel="Cancel"
        successLabel="Delete"
        variant="contained"
        color="error"
      />
      <EditMinorCategoryDialog
        open={openEditMinorCategory}
        onClose={() => setOpenEditMinorCategory(false)}
        majorEventCategoryId={majorEventCategoryId}
        minorCategoryId={editId}
      />
    </>
  );
};
//
export default MinorCategoriesGrid;
