import { FormControl, FormControlLabel, Switch } from '@mui/material';
import { CustomStack } from 'modules/common/components';
import styles from 'modules/transactions/components/inner-filter/inner-filter.module.scss';
/**
 * De-aggregated switch component common for the transaction component
 * @param {Boolean} value - Toggle value
 * @param {Function} onChange - Function to call when the toggle value changes
 * @returns
 */
const DeAggregateToggle = ({ value, onChange }) => (
  <CustomStack cssClassName={styles.innerFilter}>
    <FormControl sx={{ paddingTop: 3, paddingBottom: 3 }}>
      <FormControlLabel
        control={<Switch checked={value} onChange={onChange} />}
        label="De-aggregated"
        sx={{
          width: 'fit-content',
          height: 10,
        }}
      />
    </FormControl>
  </CustomStack>
);
//
export default DeAggregateToggle;
