import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'modules/common/components';
import { selectLoader } from 'modules/user-invitation/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { withTheme } from '@emotion/react';
import { userInvitesActions } from 'modules/user-invitation/slice';
import { selectNotification } from 'modules/common/notifications/selectors';
import UserInvitationTable from '../user-invitation-table';
/**
 * User Invitation view to manage user invitations
 * @returns
 */
const UserInvitationView = ({ theme }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectLoader);
  //
  useEffect(() => {
    const query = `limit=${PAGINATION.USER_INVITES_PER_PAGE_COUNT}`;
    dispatch(
      userInvitesActions.getUserInvites({
        organizationId,
        query,
      })
    );
    dispatch(userInvitesActions.getRoles({ organizationId }));
    dispatch(
      userInvitesActions.usersList({
        organizationId,
        query: `limit=${PAGINATION.USERS_PER_PAGE_COUNT}`,
      })
    );
  }, [organizationId, notification]);
  //
  return (
    <Loader loading={loading}>
      <Card>
        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
          <Grid container direction="row" justifyContent="space-between" pb={4}>
            <Typography variant="h3">User Invitations</Typography>
          </Grid>
          <Grid container direction="row" spacing={2} justifyContent="space-between">
            <Grid item sx={{ flexGrow: 1, flexBasis: 1 }}>
              <UserInvitationTable />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Loader>
  );
};
//
export default withTheme(UserInvitationView);
