import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
//
const selectDomain = (state) => state['feature/visual-filter'] || initialState;
/**
 * Getting loader state to visual filter component
 */
export const selectLoader = createSelector(
  [selectDomain],
  (visualFilterState) => visualFilterState.loading
);
/**
 * Getting visual filters state to visual filter component
 */
export const selectVisualFilters = createSelector(
  [selectDomain],
  (visualFilterState) => visualFilterState.visualFilters
);
/**
 * Getting the visual filter details by visual filter id state into visual filter component
 */
export const selectVisualFilterByID = createSelector(
  [selectDomain],
  (visualFilterState) => visualFilterState.visualFilterById
);
