/**
 * Hotel set feature related request structures are defined here
 */

export const API = {
  GET_ALL_HOTEL_GROUPS: {
    path: '/organizations/:organizationId/configurations/hotel-group?:query',
    method: 'GET',
  },
  POST_HOTEL_GROUP: {
    path: '/organizations/:organizationId/configurations/hotel-group',
    method: 'POST',
  },
  DELETE_HOTEL_GROUP: {
    path: '/organizations/:organizationId/configurations/hotel-group?:query',
    method: 'DELETE',
  },
  GET_HOTELS: {
    path: '/organizations/:organizationId/hotels?:query',
    method: 'GET',
  },
};
//
export const LABELS = {
  GROUP_HOTEL: 'Hotel Set',
};
//
export const FIELD = [
  'hotel',
  'hotelClassification',
  'chainName',
  'hotelType',
  'city',
  'state',
  'country',
];
