import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'modules/common/components';
import { selectLoader } from 'modules/visual/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { Card, CardContent, Grid, Typography, Button } from '@mui/material';
import { withTheme } from '@emotion/react';
import { visualActions } from 'modules/visual/slice';
import { isEmpty } from 'modules/common/helpers/object';
import VisualTable from '../visual-table';
import AddVisualForm from '../add-visual';
import UpdateVisualForm from '../update-visual';
/**
 * Visual view to add, edit and delete Power BI visuals
 * @returns
 */
const VisualView = ({ theme }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const loading = useSelector(selectLoader);
  //
  const [selectedVisualId, setSelectedVisualId] = useState({});
  const [open, setOpen] = useState(false);
  const [show, isShow] = useState(false);
  //
  useEffect(() => {
    const query = `limit=${PAGINATION.VISUALS_PER_PAGE_COUNT}`;
    dispatch(
      visualActions.getVisuals({
        organizationId,
        query,
      })
    );
    //
    dispatch(
      visualActions.getReports({
        organizationId,
      })
    );
  }, [organizationId]);
  //
  useEffect(() => {
    if (!isEmpty(selectedVisualId)) {
      dispatch(
        visualActions.getVisualById({
          organizationId,
          selectedVisualId,
        })
      );
    }
  }, [organizationId, selectedVisualId]);
  //
  return (
    <Loader loading={loading}>
      <Card>
        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
          <Grid container direction="row" justifyContent="space-between" pb={4}>
            <Typography variant="h3">Configure Visuals</Typography>
            <Button
              disabled={show}
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Add Visual
            </Button>
          </Grid>
          <Grid container direction="row" spacing={2} justifyContent="space-between">
            <Grid item sx={{ flexGrow: 1, flexBasis: 1 }}>
              <VisualTable isShow={isShow} setSelectedVisualId={setSelectedVisualId} />
            </Grid>
            {!isEmpty(selectedVisualId) && (
              <UpdateVisualForm show={show} isShow={isShow} visualId={selectedVisualId} />
            )}
          </Grid>
        </CardContent>
        <AddVisualForm open={open} onClose={() => setOpen(false)} />
      </Card>
    </Loader>
  );
};
//
export default withTheme(VisualView);
