import * as yup from 'yup';

/**
 * Here mentioned users feature add user validations
 */

const forbiddenUsernames = ['user', 'users', 'admin', 'admins', 'root', 'preferred'];
//
export const addUserValidation = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email Address is a required field')
    .email('Email address must be valid'),
  username: yup
    .string()
    .trim()
    .required('Username is required')
    .matches(/^[a-zA-Z0-9_.@]*$/, 'Can contain only Alphabets, numbers . and @')
    .max(20, 'Username should not have more than 20 characters')
    .test('is-not-forbidden', (value) => {
      if (value && forbiddenUsernames.includes(value.toLowerCase())) {
        return new yup.ValidationError(`Username cannot be '${value}'`, null, 'username');
      }
      return true;
    }),
  firstName: yup.string().trim().required('First Name is a required field'),
  lastName: yup.string().trim().required('Last Name is a required field'),
  hotels: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        label: yup.string(),
      })
    )
    .min(1, 'Hotels is a required field'),
  password: yup
    .string()
    .trim()
    .required('Password is a required field')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Password should contain at least 8 characters with One Uppercase, One Lowercase and One Number'
    ),
});
/**
 * Here mentioned users feature update user validations
 */
export const updateUserValidation = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email Address is a required field')
    .email('Email address must be valid'),
  firstName: yup.string().trim().required('First Name is a required field'),
  lastName: yup.string().trim().required('Last Name is a required field'),
  userHotels: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        label: yup.string(),
      })
    )
    .min(1, 'User Hotels is a required field'),
});
