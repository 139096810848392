import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
/**
 * Getting entire user invites state into the component
 */
const selectDomain = (state) => state['feature/audit-logs'] || initialState;
/**
 * Getting the loader state into the component
 */
export const selectLoader = createSelector(
  [selectDomain],
  (auditLogsState) => auditLogsState?.loading
);

/**
 * Getting the audit logs state into the component
 */
export const selectAuditLogs = createSelector(
  [selectDomain],
  (auditLogsState) => auditLogsState?.auditLogs
);

/**
 * Getting the users list state into the component
 */
export const selectUsernameList = createSelector([selectDomain], (auditLogsState) =>
  auditLogsState.usersList?.map((data) => ({
    id: data?.username,
    value: data?.username,
  }))
);
