import ROUTES from 'modules/common/constants/route';
import { isAllowForRoles } from 'modules/common/helpers/token';
import { ADMIN_ROLE, PREFERRED_ADMIN_ROLE, SUPER_ADMIN_ROLE } from 'modules/common/constants/roles';
import {
  GroupAddRounded,
  BarChart,
  FilterAlt,
  ManageAccounts,
  EventNote,
  LineWeight,
  Hotel,
  Apartment,
  Paid,
} from '@mui/icons-material';

/**
 * Sidebar nav items are defined here
 */
const getDashboardItems = (accessToken) => {
  const pagesSection = [];
  if (isAllowForRoles(accessToken, [SUPER_ADMIN_ROLE, ADMIN_ROLE])) {
    pagesSection.push(
      {
        href: ROUTES.DASHBOARD,
        icon: BarChart,
        title: 'Visuals',
      },
      {
        href: ROUTES.VISUAL_FILTER,
        icon: FilterAlt,
        title: 'Visual Filters',
      }
    );
  }
  if (isAllowForRoles(accessToken, [SUPER_ADMIN_ROLE, ADMIN_ROLE, PREFERRED_ADMIN_ROLE])) {
    pagesSection.push({
      href: ROUTES.USERS,
      icon: ManageAccounts,
      title: 'Users',
    });
  }

  if (isAllowForRoles(accessToken, [SUPER_ADMIN_ROLE, ADMIN_ROLE, PREFERRED_ADMIN_ROLE])) {
    pagesSection.push({
      href: ROUTES.USER_INVITATION,
      icon: GroupAddRounded,
      title: 'User Invitations',
    });
  }

  if (isAllowForRoles(accessToken, [SUPER_ADMIN_ROLE, ADMIN_ROLE])) {
    pagesSection.push({
      href: ROUTES.HOTELS,
      icon: Hotel,
      title: 'Hotels',
    });
  }

  if (isAllowForRoles(accessToken, [SUPER_ADMIN_ROLE, ADMIN_ROLE, PREFERRED_ADMIN_ROLE])) {
    pagesSection.push({
      href: ROUTES.TRANSACTIONS,
      icon: Paid,
      title: 'Transactions',
    });
  }

  if (isAllowForRoles(accessToken, [SUPER_ADMIN_ROLE, ADMIN_ROLE, PREFERRED_ADMIN_ROLE])) {
    pagesSection.push({
      href: ROUTES.HOTEL_SET,
      icon: Apartment,
      title: 'Hotel Sets',
    });
  }

  if (isAllowForRoles(accessToken, [SUPER_ADMIN_ROLE, ADMIN_ROLE])) {
    pagesSection.push({
      href: ROUTES.EVENT_CONFIGURATION,
      icon: EventNote,
      title: 'Event Configuration',
    });
  }

  if (isAllowForRoles(accessToken, [SUPER_ADMIN_ROLE])) {
    pagesSection.push({
      href: ROUTES.AUDIT_LOGS,
      icon: LineWeight,
      title: 'Audit Logs',
    });
  }

  return [
    {
      title: 'Dashboard',
      pages: pagesSection,
    },
  ];
};

export default getDashboardItems;
