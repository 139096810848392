/**
 * Here mentioned audit logs feature request structure
 */
const API = {
  GET_AUDIT_LOGS: {
    path: '/organizations/:organizationId/audit-logs?:query',
    method: 'GET',
  },
  GET_USER_LIST: {
    path: '/organizations/:organizationId/users?:query',
    method: 'GET',
  },
};
//
export default API;
