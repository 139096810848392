/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';
/**
 * Add hotel form validation rules are mentioned here
 */
export const addHotelValidation = yup.object().shape({
  name: yup.string().required('Hotel Name is required'),
  databricksHotelId: yup
    .number()
    .typeError('Please enter a valid numeric Databricks Hotel Id.')
    .required('Databricks Hotel Id is required.'),
});
