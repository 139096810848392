/* eslint-disable import/no-cycle */
import reduceChildRoutes from './reduce-child-routes';
import useSafeLocation from '../../hooks/use-safe-location';

const SidebarNavList = (props) => {
  const { pages, depth } = props;
  const { pathname } = useSafeLocation();
  const currentRoute = pathname;

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth }),
    []
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{childRoutes}</>;
};

export default SidebarNavList;
