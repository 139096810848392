import * as yup from 'yup';
/**
 * Add minor event category configuration feature add minor event validations
 */
export const addMinorCategoryValidation = yup.object().shape({
  minorName: yup.string().trim().required('This is a required field'),
  hotels: yup.array().nullable().required().min(1, 'At least one hotel is required'),
});
//
export const addMajorCategoryValidation = yup.object().shape({
  majorName: yup.string().trim().required('This is a required field'),
});
