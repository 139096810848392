/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { visualFilterActions } from './slice';
import API from './constants';
/**
 * Power BI visual filter generator function to obtain registered visual filters
 * @param {*} param0
 */
export function* getVisualFiltersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_VISUAL_FILTERS, payload);
    yield put(visualFilterActions.getVisualFiltersSucceeded(response));
  } catch (error) {
    yield put(visualFilterActions.getVisualFiltersFailed(error?.message));
  }
}
/**
 * Visual filter generator function to create visual filter
 * @param {*} param0
 */
export function* createVisualFilterGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_VISUAL_FILTER, payload);
    yield put(visualFilterActions.createVisualFilterSucceeded(response));
  } catch (error) {
    yield put(visualFilterActions.createVisualFilterFailed(error?.message));
  }
}
/**
 * Delete visual generator function to delete visual filter
 * @param {*} param0
 */
export function* deleteVisualFilterGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_VISUAL_FILTER, payload);
    yield put(visualFilterActions.deleteVisualFilterSucceeded({ response, data: payload }));
  } catch (error) {
    yield put(visualFilterActions.deleteVisualFilterFailed(error?.message));
  }
}
/**
 * Update visual generator function to update visual filter
 * @param {*} param0
 */
export function* updateVisualFilterGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_VISUAL_FILTER, payload);
    yield put(visualFilterActions.updateVisualFilterSucceeded(response));
  } catch (error) {
    yield put(visualFilterActions.updateVisualFilterFailed(error?.message));
  }
}
/**
 * Get visual generator function to get visual filter by id
 * @param {*} param0
 */
export function* getVisualFilterByIdGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_VISUAL_FILTER_BY_ID, payload);
    yield put(visualFilterActions.getVisualFilterByIdSucceeded(response));
  } catch (error) {
    yield put(visualFilterActions.getVisualFilterByIdFailed(error?.message));
  }
}
//
export function* visualFilterSaga() {
  yield takeLatest(visualFilterActions.getVisualFilters.type, getVisualFiltersGenerator);
  yield takeLatest(visualFilterActions.createVisualFilter.type, createVisualFilterGenerator);
  yield takeLatest(visualFilterActions.deleteVisualFilter.type, deleteVisualFilterGenerator);
  yield takeLatest(visualFilterActions.updateVisualFilter.type, updateVisualFilterGenerator);
  yield takeLatest(visualFilterActions.getVisualFilterById.type, getVisualFilterByIdGenerator);
}
//
export default visualFilterSaga;
