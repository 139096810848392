/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Define the the sign in feature initial state
 */
export const initialState = {
  loading: false,
  error: '',
};
/**
 * Here mentioned all the sign in feature related actions
 */
export const signInSlice = createSlice({
  name: 'feature/sign-in',
  initialState,
  reducers: {
    signIn(state) {
      state.loading = true;
      state.error = '';
    },
    signInSucceeded(state) {
      state.loading = false;
      state.error = '';
    },
    signInFailed(state, action) {
      state.loading = false;
      state.error = action?.payload?.message;
    },
  },
});
//
export const { actions: signInActions } = signInSlice;
