import { withTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { selectVisuals } from 'modules/visual/selectors';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TABLE_HEADER_FONT_COLOR } from 'modules/common/constants/style';
import { Grid } from '@mui/material';
import { isEmpty } from 'modules/common/helpers/object';
/**
 * Data grid to display all regisered visuals
 * @param {*} param0
 * @returns
 */
const columnVisibilityModel = {
  id: false,
};
/**
 * Column names for the data grid is defined here
 */
const columns = [
  {
    field: 'id',
    headerName: 'ID',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Visual Name',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'reportId',
    headerName: 'Report ID',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'reportName',
    headerName: 'Report Name',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'pageDisplayName',
    headerName: 'Page Display Name',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'pageName',
    headerName: 'Page Name',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'datasetId',
    headerName: 'Dataset ID',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'tags',
    headerName: 'Tags',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'order',
    headerName: 'Order',
    headerClassName: 'super-app-theme--header',
    flex: 0.5,
  },
];
/**
 * Data grid to display all registered visuals
 * @param {*} param0
 * @returns
 */
const VisualTableView = ({ isShow, setSelectedVisualId }) => {
  const visuals = useSelector(selectVisuals);
  //
  const [filteredVisuals, setFilteredVisuals] = useState([]);
  //
  useEffect(() => {
    if (!isEmpty(visuals)) {
      const formattedVisual = visuals?.map((visual) => ({
        id: visual?.id,
        name: visual?.name,
        pageName: visual?.pageName,
        pageDisplayName: visual?.pageDisplayName,
        reportId: visual?.reportId,
        reportName: visual?.reportName,
        datasetId: visual?.datasetId,
        tags: visual?.tags ? visual?.tags.toString() : '',
        order: visual?.order,
      }));
      setFilteredVisuals(formattedVisual);
    }
  }, [visuals]);
  //
  const onSelectedRowChange = (id) => {
    if (id?.length) {
      setSelectedVisualId(id[0]);
      isShow(true);
    }
  };
  //
  return (
    <Grid container sx={{ height: 600 }}>
      <DataGrid
        sx={{
          '& .super-app-theme--header': {
            backgroundColor: 'white',
            color: TABLE_HEADER_FONT_COLOR,
          },
          '& .MuiDataGrid-main': {
            backgroundColor: 'white',
          },
          '.MuiDataGrid-footerContainer': {
            backgroundColor: 'white',
          },
        }}
        columnVisibilityModel={columnVisibilityModel}
        rowHeight={50}
        rows={filteredVisuals || []}
        columns={columns}
        onSelectionModelChange={onSelectedRowChange}
      />
    </Grid>
  );
};
//
export default withTheme(VisualTableView);
