/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { hotelActions } from './slice';
import API from './constants';
/**
 * Get hotel generator function to obtain registered hotels
 * @param {*} param0
 */
export function* getHotelsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_HOTELS, payload);
    yield put(hotelActions.getHotelsSucceeded(response));
  } catch (error) {
    yield put(hotelActions.getHotelsFailed(error?.message));
  }
}
/**
 * Create hotel generator function to create hotel
 * @param {*} param0
 */
export function* createHotelGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_HOTEL, payload);
    yield put(hotelActions.createHotelSucceeded(response));
  } catch (error) {
    yield put(hotelActions.createHotelFailed(error?.message));
  }
}
/**
 * Delete hotel function to delete hotel
 * @param {*} param0
 */
export function* deleteHotelGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_HOTEL, payload);
    yield put(hotelActions.deleteHotelSucceeded({ response, data: payload }));
  } catch (error) {
    yield put(hotelActions.deleteHotelFailed(error?.message));
  }
}
/**
 * Update hotel generator function to update hotel
 * @param {*} param0
 */
export function* updateHotelGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_HOTEL, payload);
    yield put(hotelActions.updateHotelSucceeded(response));
  } catch (error) {
    yield put(hotelActions.updateHotelFailed(error?.message));
  }
}
/**
 * Get currency  generator function to obtain currency data
 * @param {*} param0
 */
export function* getCurrencyDataGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_CURRENCY_DATA, payload);
    yield put(hotelActions.getCurrencyDataSucceeded(response));
  } catch (error) {
    yield put(hotelActions.getCurrencyDataFailed(error?.message));
  }
}
/**
 * Get users assigned to hotel  generator function to obtain list of users assigned to each hotel
 * @param {*} param0
 */
export function* getUsersAssignedToHotel({ payload }) {
  try {
    const response = yield call(request, API.GET_HOTEL_USER_DATA, payload);
    yield put(hotelActions.getHotelUserDataSucceeded(response));
  } catch (error) {
    yield put(hotelActions.getHotelUserDataFailed(error?.message));
  }
}

//
export function* hotelSaga() {
  yield takeLatest(hotelActions.getHotels.type, getHotelsGenerator);
  yield takeLatest(hotelActions.createHotel.type, createHotelGenerator);
  yield takeLatest(hotelActions.deleteHotel.type, deleteHotelGenerator);
  yield takeLatest(hotelActions.updateHotel.type, updateHotelGenerator);
  yield takeLatest(hotelActions.getCurrencyData.type, getCurrencyDataGenerator);
  yield takeLatest(hotelActions.getHotelUserData.type, getUsersAssignedToHotel);
}
//
export default hotelSaga;
