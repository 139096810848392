import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { notificationActions } from 'modules/common/notifications/slice';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import { configureEventCategoriesActions } from 'modules/event-configuration/slice';
import { addMajorCategoryValidation } from '../../validation/add-event-category-form-validation';
import { Alert, TextField } from './style';
/**
 * Modal component to add a major event category
 */
const AddMajorCategoryDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  //
  const modalAction = (action) => {
    if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
    onClose(action);
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      modalAction(false);
    }
  }, [notification]);
  //
  const onSubmitAddMinorEvent = async (values) => {
    dispatch(
      configureEventCategoriesActions.addMajorEventCategories({
        organizationId,
        name: values?.majorName,
      })
    );
    modalAction(false);
  };
  //
  const initialValues = {
    majorName: '',
  };
  //
  return (
    <Dialog open={open} onClose={() => modalAction(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add Major Category</DialogTitle>
      <DialogContent sx={{ minWidth: 400, maxWidth: 400 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={addMajorCategoryValidation}
          onSubmit={onSubmitAddMinorEvent}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <TextField
                name="majorName"
                label="Major category name"
                value={values.majorName}
                error={Boolean(touched.majorName && errors.majorName)}
                helperText={touched.majorName && errors.majorName}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
              />
              <DialogActions sx={{ justifyContent: 'flex-end', px: 0 }}>
                <Button
                  sx={{ px: 10 }}
                  onClick={() => modalAction(false)}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  sx={{ px: 10 }}
                  type="submit"
                  variant="contained"
                  color="success"
                  disabled={isSubmitting}
                >
                  Add
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
//
export default AddMajorCategoryDialog;
