import { Stack, IconButton, Tooltip } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronsLeft, ChevronsRight, ChevronLeft, ChevronRight } from 'react-feather';
import { StayDateRangePicker } from 'modules/common/components';
import { ENVIRONMENT } from 'config';
import { selectTargetDate } from 'modules/transactions/selectors';
import {
  formatDateLabel,
  getNextMonthStayDate,
  getNextPeriodStayDate,
  getPreviousMonthStayDate,
  getPreviousPeriodStayDate,
} from 'modules/transactions/functions';
import { differenceInDays, addDays, subDays, addSeconds, differenceInMonths } from 'date-fns';
import { STAY_DATE_END_DATE, STAY_DATE_START_DATE } from 'modules/common/constants/date-range';
import styles from 'modules/transactions/components/inner-filter/inner-filter.module.scss';
import { transactionActions } from 'modules/transactions/slice';

/**
 * Stay date-range picker for each widget with scrollable date navigation
 * @param {String} adornment - Text to add as start adornment in date picker textfield
 * @param {String} label - Label for in date picker textfield
 * @returns
 */
const StayDatePicker = ({ adornment, label }) => {
  const dispatch = useDispatch();

  // Current date values for each tab is selected here. Refer each selector function for details
  const targetDate = useSelector(selectTargetDate);
  // References for scroll navigation buttons to execute ui control flow
  const prevRangeRef = useRef(null);
  const prevMonthRef = useRef(null);
  const nextRangeRef = useRef(null);
  const nextMonthRef = useRef(null);

  // Local states to maintain selected date values and to disable scroll navigation icons are defined here
  const [dates, setDates] = useState(targetDate);
  const [triggerTime, setTriggerTime] = useState();
  const [disableGetPreviousMonth, setDisableGetPreviousMonth] = useState(false);
  const [disableGetNextMonth, setDisableGetNextMonth] = useState(false);
  const [disableGetPreviousPeriod, setDisableGetPreviousPeriod] = useState(false);
  const [disableGetNextPeriod, setDisableGetNextPeriod] = useState(false);
  //
  const triggerTimeRef = useRef(triggerTime);
  const datesRef = useRef(dates);
  triggerTimeRef.current = triggerTime;
  datesRef.current = dates;

  // Update global states of stay dates and comparison dates based on the report type to trigger Power BI filter
  const updateStore = (item) => {
    const stayDate = {
      key: 'selection',
      startDate: new Date(item?.selection.startDate).toISOString(),
      endDate: new Date(item?.selection.endDate).toISOString(),
    };
    dispatch(transactionActions.setTargetDate(stayDate));
  };
  //
  const handleChange = (item, fromDatePicker = true) => {
    setDates((prev) => ({
      ...prev,
      startDate: item?.selection.startDate,
      endDate: item?.selection.endDate,
    }));
    // Apply power bi filters directly if the change triggered from the date picker
    if (fromDatePicker) {
      updateStore(item);
    } else {
      // Implemented 2s delay in applying Power BI filter for the date change triggered through scroll navigation
      setTimeout(() => {
        const currentTime = new Date();
        if (currentTime.getTime() - triggerTimeRef.current.getTime() > 999) {
          updateStore({
            selection: {
              startDate: datesRef.current?.startDate,
              endDate: datesRef.current?.endDate,
              key: 'selection',
            },
          });
        }
      }, Number(ENVIRONMENT.TIMEOUT_DELAY));
    }
  };

  // Move back by 1 month scroll navigation implementation
  const getPreviousMonth = () => {
    const newDates = getPreviousMonthStayDate(dates.startDate, dates.endDate);
    //
    setDates((prev) => ({
      ...prev,
      startDate: newDates.newStartDate,
      endDate: newDates.newEndDate,
    }));
    const item = {
      selection: {
        startDate: newDates.newStartDate,
        endDate: newDates.newEndDate,
        key: 'selection',
      },
    };
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false);
  };

  // Move forward by 1 month scroll navigation implementation
  const getNextMonth = () => {
    const newDates = getNextMonthStayDate(dates.startDate, dates.endDate);
    //
    setDates((prev) => ({
      ...prev,
      startDate: newDates.newStartDate,
      endDate: newDates.newEndDate,
    }));
    const item = {
      selection: {
        startDate: newDates.newStartDate,
        endDate: newDates.newEndDate,
        key: 'selection',
      },
    };
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false);
  };

  // Move back by 1 period scroll navigation implementation
  const getPreviousDate = () => {
    const newDates = getPreviousPeriodStayDate(dates.startDate, dates.endDate);
    setDates((prev) => ({
      ...prev,
      startDate: newDates.newStartDate,
      endDate: newDates.newEndDate,
    }));
    const item = {
      selection: {
        startDate: newDates.newStartDate,
        endDate: newDates.newEndDate,
        key: 'selection',
      },
    };
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false);
  };

  // Move forward by 1 period scroll navigation implementation
  const getNextDate = () => {
    const newDates = getNextPeriodStayDate(dates.startDate, dates.endDate);
    setDates((prev) => ({
      ...prev,
      startDate: newDates.newStartDate,
      endDate: newDates.newEndDate,
    }));
    const item = {
      selection: {
        startDate: newDates.newStartDate,
        endDate: newDates.newEndDate,
        key: 'selection',
      },
    };
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false);
  };

  // Triggers when dates state changes
  useEffect(() => {
    // set scroll icon disabled state
    setDisableGetPreviousMonth(
      differenceInMonths(new Date(dates?.startDate), new Date(STAY_DATE_START_DATE)) === 0
    );
    setDisableGetPreviousPeriod(
      differenceInDays(new Date(dates?.startDate), new Date(STAY_DATE_START_DATE)) === 0 ||
        differenceInDays(
          subDays(
            subDays(new Date(dates?.startDate), 1),
            differenceInDays(new Date(dates?.endDate), new Date(dates?.startDate))
          ),
          new Date(STAY_DATE_START_DATE)
        ) < 0
    );
    setDisableGetNextPeriod(
      differenceInDays(new Date(dates?.endDate), new Date(STAY_DATE_END_DATE)) === 0 ||
        differenceInDays(
          addDays(
            addDays(new Date(dates?.endDate), 1),
            differenceInDays(new Date(dates?.endDate), new Date(dates?.startDate))
          ),
          new Date(STAY_DATE_END_DATE)
        ) > 0
    );
    setDisableGetNextMonth(
      differenceInMonths(new Date(dates?.endDate), new Date(STAY_DATE_END_DATE)) === 0
    );
  }, [dates]);
  //
  useEffect(() => {
    setDates(targetDate);
  }, [targetDate]);
  //
  return (
    <Stack direction="row" spacing={1} xs={12} paddingTop={1}>
      <Tooltip title="Move back by 1 month." placement="top">
        <IconButton
          ref={prevMonthRef}
          onClick={getPreviousMonth}
          disabled={disableGetPreviousMonth}
        >
          <ChevronsLeft fontSize="large" strokeWidth={4} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Move back by 1 period." placement="top">
        <IconButton
          ref={prevRangeRef}
          onClick={getPreviousDate}
          disabled={disableGetPreviousPeriod}
        >
          <ChevronLeft fontSize="large" strokeWidth={4} />
        </IconButton>
      </Tooltip>
      <StayDateRangePicker
        id="auto-stay-date-picker"
        handleChange={handleChange}
        ranges={[targetDate]}
        label={label}
        dateValue={formatDateLabel(dates)}
        adornment={adornment}
        cssClassName={styles.calendarElement}
      />
      <Tooltip title="Move forward by 1 period." placement="top">
        <IconButton ref={nextRangeRef} onClick={getNextDate} disabled={disableGetNextPeriod}>
          <ChevronRight fontSize="large" strokeWidth={4} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Move forward by 1 month." placement="top">
        <IconButton ref={nextMonthRef} onClick={getNextMonth} disabled={disableGetNextMonth}>
          <ChevronsRight fontSize="large" strokeWidth={4} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
//
export default StayDatePicker;
