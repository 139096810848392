import { DATE_PATTERN } from 'modules/common/constants/date-range';
import { format } from 'date-fns';
import mapper from './mapper';
import { VISUAL_HEADER_TYPES } from './constants';

const withSingleHeader = (result, fileName) => {
  // Split data set by new line character and create a new array removing the first row
  const parsedData = result?.data.split('\r\n').slice(1);
  const metrics = mapper[fileName].metrics.filter((item) => result?.data.includes(item));
  let csv = '';

  // set the headers
  csv += metrics.join(',');
  const concatData = [];
  parsedData.forEach((item) => {
    let itemArr = item?.split(',');
    // Get the last two elements
    let lastTwo = itemArr.slice(-2);
    // Remove extra decimal points (let's say to 2 decimal places)
    lastTwo = lastTwo.map((num) => {
      if (num.includes('.') && num.split('.')[1]?.length > 2) {
        return `${num.split('.')[0]}.${num.split('.')[1].slice(0, 2)}`;
      }
      return num;
    });
    // Add the modified values back into the array
    itemArr = itemArr.slice(0, -2).concat(lastTwo)?.join(',');
    concatData.push(itemArr);
  });
  csv += '\r\n';
  csv += concatData.join('\r\n');
  return csv;
};

export const GenerateCSV = (result, fileName) => {
  console.log('CSV Export Started.....');
  switch (mapper[fileName].headertype) {
    case VISUAL_HEADER_TYPES.SINGLE:
      return withSingleHeader(result, fileName);
    default:
      return '';
  }
};

/**
 * Formatted date according to given pattern
 * @param {*} item
 * @param {*} pattern
 * @returns
 */
export const formatDate = (item, pattern = DATE_PATTERN) => format(new Date(item), pattern);
