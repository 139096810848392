import { ThemeProvider } from 'modules/common/contexts/theme-context';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import useTheme from 'modules/common/hooks/use-theme';
import createTheme from 'modules/common/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Router from './routes';
import store from './store';
/**
 * @returns Entry point in the react application. And also register the providers
 */
const App = () => {
  const { theme } = useTheme();
  //
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiThemeProvider theme={createTheme(theme)}>
              <Router />
              <ToastContainer />
            </MuiThemeProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
