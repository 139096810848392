/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'modules/sign-in/slice';
import {
  BOOKING_DATE,
  REPORT_TYPE,
  TRANSACTION_STAY_DATE,
  SUMMARY_DATE_SELECTION,
  TAGS,
} from 'modules/transactions/constants';
import { getHotelFocusOnValue } from 'modules/transactions/functions';
//
const bookingStartDate = new Date(BOOKING_DATE).toISOString();

/**
 * Initial states of transaction widget functions are defined here
 */
export const initialState = {
  transactionBookingDate: {
    startDate: new Date(bookingStartDate).toISOString(),
    endDate: new Date().toISOString(),
    key: 'selection',
  },
  embedTokenDetails: {},
  chartBreakdown: null,
  breakdown: [],
  transactionFilterList: [],
  transactionOtb: false,
  latestUpdatedHotelList: {},
  isDetailed: false,
  transactionSetIsDisplay: true,
  transactionVisuals: [],
  transactionDeAggregatedVisuals: [],
  transactionSegmentFocusOn: [],
  transactionDateType: SUMMARY_DATE_SELECTION[0],
  hotelName: null,
  allHotelList: [],
  targetDate: TRANSACTION_STAY_DATE,
  visualFilters: {},
  //
  isDeAggregate: false,
};
/**
 * All actions related to transaction widget feature are defined here
 */
export const transactionSlice = createSlice({
  name: 'feature/transaction',
  initialState,
  reducers: {
    getEmbedToken(state) {
      state.loading = true;
    },
    getEmbedTokenSucceeded(state, action) {
      state.loading = false;
      state.embedTokenDetails = action?.payload;
    },
    getEmbedTokenFailed(state) {
      state.loading = false;
    },
    getVisuals(state) {
      state.loading = true;
    },
    getVisualsSucceeded(state, action) {
      const visualDetails = action?.payload?.results;
      state.loading = false;
      state.visuals = action?.payload;
      state.transactionVisuals = visualDetails?.filter((visual) =>
        visual?.tags?.includes(REPORT_TYPE.ADMIN_TRANSACTION)
      );
      state.transactionDeAggregatedVisuals = visualDetails?.filter((visual) =>
        visual?.tags?.includes(REPORT_TYPE.ADMIN_TRANSACTION_DEAGGREGATE)
      );
    },
    getVisualsFailed(state) {
      state.loading = false;
    },
    getVisualFilters(state) {
      state.loading = true;
    },
    getVisualFiltersSucceeded(state, action) {
      state.loading = false;
      state.visualFilters = action?.payload;
      state.chartBreakdown = action?.payload?.results?.find(
        (filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN) && filter?.isDefault === true
      )?.id;
    },
    getVisualFiltersFailed(state) {
      state.loading = false;
    },
    getAllHotelList(state) {
      state.loading = true;
    },
    getAllHotelListSucceeded(state, action) {
      state.loading = false;
      const hotel = getHotelFocusOnValue(state.isDemoUser, action?.payload);
      state.allHotelList = hotel?.hotelDropdownList;
      state.hotelName =  hotel?.hotelDropdownList[0];
    },
    getAllHotelListFailed(state) {
      state.loading = false;
    },
    getLatestUpdatedHotelList(state) {
      state.loading = true;
    },
    getLatestUpdatedHotelListSucceeded(state, action) {
      state.loading = false;
      state.latestUpdatedHotelList = action?.payload;
    },
    getLatestUpdatedHotelListFailed(state) {
      state.loading = false;
    },
    getBreakdown(state) {
      state.loading = true;
    },
    getBreakdownSucceeded(state, action) {
      state.loading = false;
      state.breakdown = action?.payload?.results;
    },
    getBreakdownFailed(state) {
      state.loading = false;
    },
    setTargetDate(state, action) {
      state.targetDate = action?.payload;
    },
    setTransactionBookingDate(state, action) {
      state.transactionBookingDate = action?.payload;
    },
    setBreakdown(state, action) {
      state.chartBreakdown = action?.payload;
    },
    setHotelName(state, action) {
      state.hotelName = action?.payload;
      state.hotelFocusOn = action?.payload;
    },
    updateTransactionFilterList(state, action) {
      state.transactionFilterList = action?.payload;
    },
    setTransactionOtb(state, action) {
      state.transactionOtb = action?.payload;
    },
    setTransactionUrl(state, action) {
      state.transactionUrl = action.payload;
    },
    setIsDetailed(state, action) {
      state.isDetailed = action?.payload;
      state.transactionSegmentFocusOn = [];
    },
    setPaceVisualFilterList(state, action) {
      state.paceVisualFilterList = action?.payload;
    },
    setAlignment(state, action) {
      state.alignment = action?.payload;
    },
    setTransactionSetIsDisplay(state, action) {
      state.transactionSetIsDisplay = action?.payload;
    },
    setTransactionSegmentFocusOn(state, action) {
      state.transactionSegmentFocusOn = action?.payload;
    },
    setTransactionDateType(state, action) {
      state.transactionDateType = action?.payload;
    },
    setIsDeAggregate(state, action) {
      state.isDeAggregate = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});
//
export const { actions: transactionActions } = transactionSlice;
