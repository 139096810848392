/* eslint-disable react/no-array-index-key */
import { useState, useEffect, useRef } from 'react';
import {
  Button,
  Autocomplete,
  Switch,
  FormControlLabel,
  Stack,
  FormLabel,
  FormControl,
  Box,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { FieldArray, Formik } from 'formik';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'modules/common/helpers/object';
import { Modal } from 'modules/common/components';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { visualFilterActions } from 'modules/visual-filter/slice';
import { TAG_LIST } from 'modules/visual-filter/constants';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import { notificationActions } from 'modules/common/notifications/slice';
import { toast } from 'react-toastify';
import { selectVisualFilterByID } from 'modules/visual-filter/selectors';
import { updateVisualFilterValidation } from 'modules/visual-filter/validation/visual-filter-form-validation';
import { Save, Trash, X } from 'react-feather';
import { FormStyles, Alert, TextField } from '../style';
import '../index.scss';
/**
 * Form to update visual filter
 * @param {*} param0
 * @returns
 */
const UpdateVisualFilterForm = ({ show, isShow, visualFilterId }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const visualFilter = useSelector(selectVisualFilterByID);
  //
  const [isDelete, setDelete] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [initialValues, setInitialValues] = useState({
    type: 'NAME',
    tableName: '',
    columnName: '',
    startDate: new Date(),
    endDate: new Date(),
    isDefault: false,
    tags: [],
    values: [],
  });
  const formElement = useRef(null);
  //
  useEffect(() => {
    if (!show) {
      dispatch(notificationActions.resetNotification());
    }
  }, [show]);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      isShow(false);
    }
  }, [notification]);
  //
  useEffect(() => {
    if (!isEmpty(visualFilter)) {
      setInitialValues({
        type: visualFilter.type,
        tableName: visualFilter.tableName,
        columnName: visualFilter.columnName,
        startDate: visualFilter.startDate,
        endDate: visualFilter.endDate,
        isDefault: visualFilter.isDefault,
        tags: visualFilter.tags,
        values: visualFilter.values,
      });
    }
  }, [visualFilter]);
  //
  const deleteVisualFilter = (payload) => {
    dispatch(visualFilterActions.deleteVisualFilter(payload));
    setDelete(false);
  };
  //
  const onSubmitUpdateVisual = async (values) => {
    let valueObj;
    let value;
    if (typeof values?.values === 'string') value = [values?.values];
    else value = values?.values;
    switch (values?.type) {
      case 'DATE':
        valueObj = {
          type: values?.type,
          tableName: values?.tableName,
          columnName: values?.columnName,
          startDate: values?.startDate,
          endDate: values?.endDate,
          tags: values?.tags,
        };
        break;
      case 'ALL':
        valueObj = {
          type: values?.type,
          tableName: values?.tableName,
          columnName: values?.columnName,
          tags: values?.tags,
        };
        break;
      case 'NAME':
        valueObj = {
          type: values?.type,
          tableName: values?.tableName,
          columnName: values?.columnName,
          isDefault: values?.isDefault,
          tags: values?.tags,
          values: value,
        };
        break;
      default:
        valueObj = {
          type: values?.type,
          tableName: values?.tableName,
          columnName: values?.columnName,
          isDefault: values?.isDefault,
          tags: values?.tags,
          values: values?.values,
        };
        break;
    }
    dispatch(
      visualFilterActions.updateVisualFilter({ ...valueObj, organizationId, visualFilterId })
    );
  };
  //
  const handleAddMore = (e, values, setFieldValue) => {
    const subValueList = [...values.values];
    if (values.type === 'ALIGNED' || values.type === 'UNALIGNED') {
      subValueList.push({
        label: '',
        value: '',
        isDefault: false,
        columnName: '',
      });
    } else {
      subValueList.push({
        label: '',
        value: '',
        columnName: '',
      });
    }
    setFieldValue('values', subValueList);
  };
  //
  return (
    <Box
      xs={12}
      md={4}
      ml={4}
      mt={2}
      component={Grid}
      container
      item
      display="flex"
      direction="column"
      sx={{ backgroundColor: 'white', flexShrink: 0, display: show ? 'flex' : 'none' }}
    >
      Update Visual Filter
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={updateVisualFilterValidation}
        onSubmit={onSubmitUpdateVisual}
      >
        {({
          errors,
          dirty,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleReset,
          isSubmitting,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container direction="row" justifyContent="space-between" px={2} mb={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                {visualFilter?.type}
              </Typography>
              <Grid>
                <Tooltip title="Update">
                  <IconButton
                    disabled={isSubmitting}
                    onClick={() =>
                      errors.values ||
                      errors.tags ||
                      errors.endDate ||
                      errors.tableName ||
                      errors.columnName ||
                      errors.startDate
                        ? ''
                        : setUpdate(true)
                    }
                    color="inherit"
                    size="small"
                  >
                    <Save />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton color="error" size="small" onClick={() => setDelete(true)}>
                    <Trash />
                  </IconButton>
                </Tooltip>
                <IconButton color="inherit" size="small" onClick={() => isShow(false)}>
                  <X />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={2} direction="column">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <Grid justifyContent="space-around" alignItems="center" px={4}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="tableName"
                    value={values.type}
                    fullWidth
                    my={2}
                    label="Type"
                    size="type"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="tableName"
                    value={values.tableName}
                    error={Boolean(errors.tableName)}
                    helperText={errors.tableName}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                    label="Table Name"
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4}>
                <FormControl sx={FormStyles} fullWidth>
                  {' '}
                  <TextField
                    name="columnName"
                    value={values.columnName}
                    error={Boolean(errors.columnName)}
                    helperText={errors.columnName}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                    label="column Name"
                    size="small"
                  />
                </FormControl>
              </Grid>
              {values.type === 'DATE' && (
                <>
                  <Grid justifyContent="space-around" alignItems="center" px={4}>
                    <FormControl sx={FormStyles} fullWidth>
                      <DesktopDatePicker
                        name="startDate"
                        inputFormat="MM/dd/yyyy"
                        label="Start Date"
                        onBlur={handleBlur}
                        value={new Date(values.startDate)}
                        maxDate={new Date(values.endDate)}
                        onChange={(value) => setFieldValue('startDate', value)}
                        renderInput={(params) => (
                          <TextField
                            name="startDate"
                            label="Start Date"
                            {...params}
                            fullWidth
                            my={2}
                            error={Boolean(errors.startDate)}
                            helperText={errors.startDate}
                            size="small"
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid justifyContent="space-around" alignItems="center" px={4}>
                    <FormControl sx={FormStyles} fullWidth>
                      <DesktopDatePicker
                        name="endDate"
                        inputFormat="MM/dd/yyyy"
                        minDate={new Date(values.startDate)}
                        label="End Date"
                        onBlur={handleBlur}
                        value={new Date(values.endDate)}
                        onChange={(value) => setFieldValue('endDate', value)}
                        renderInput={(params) => (
                          <TextField
                            name="endDate"
                            label="End Date"
                            {...params}
                            fullWidth
                            my={2}
                            error={Boolean(errors.endDate)}
                            helperText={errors.endDate}
                            size="small"
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              {values.type !== 'DATE' && values.type !== 'ALL' && (
                <Grid justifyContent="space-around" alignItems="center" px={4}>
                  <FormControl sx={FormStyles} fullWidth>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      paddingTop={2}
                      paddingLeft={1}
                    >
                      Default
                      <FormControlLabel
                        control={
                          <Switch
                            name="isDefault"
                            checked={values.isDefault}
                            onChange={(event) => setFieldValue('isDefault', event.target.checked)}
                          />
                        }
                      />
                    </Stack>
                  </FormControl>
                </Grid>
              )}
              <Grid justifyContent="space-around" alignItems="center" px={4}>
                <FormControl sx={FormStyles} fullWidth>
                  {!isEmpty(visualFilter) && (
                    <Autocomplete
                      size="small"
                      value={values.tags}
                      fullWidth
                      multiple
                      disableClearable
                      disableCloseOnSelect
                      name="tags"
                      limitTags={3}
                      isOptionEqualToValue={(option, value) => option === value}
                      options={TAG_LIST || []}
                      onChange={(e, value) => setFieldValue('tags', value)}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          name="tags"
                          error={Boolean(errors.tags)}
                          helperText={errors.tags}
                          label="Tags"
                          size="small"
                          {...params}
                          fullWidth
                          my={2}
                        />
                      )}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          padding: 0,
                          height: 'fit-content',
                        },
                        marginY: 2,
                      }}
                    />
                  )}
                </FormControl>
              </Grid>
              {values.type === 'NAME' && (
                <Grid justifyContent="space-around" alignItems="center" px={4}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="values"
                      value={values.values}
                      error={Boolean(errors.values)}
                      helperText={errors.values}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      label="Values"
                      my={2}
                      size="small"
                    />
                  </FormControl>
                </Grid>
              )}
              {values.type !== 'DATE' && values.type !== 'ALL' && values.type !== 'NAME' && (
                <Grid justifyContent="space-around" alignItems="center" px={4}>
                  <FormControl sx={FormStyles} fullWidth>
                    <Stack direction="row" justifyContent="flex-end" paddingTop={2} paddingLeft={1}>
                      <Button
                        onClick={(e) => {
                          handleAddMore(e, values, setFieldValue);
                        }}
                      >
                        Add Values
                      </Button>
                    </Stack>
                    <FieldArray name="values">
                      {(arrayHelpers) =>
                        values.values &&
                        values.values.length > 0 &&
                        values?.values?.map((val, i) => {
                          const valueErrors = (errors.values?.length && errors.values[i]) || {};
                          return (
                            <div key={i}>
                              <FormLabel>Value {i + 1}</FormLabel>
                              <TextField
                                name={`values.${i}.label`}
                                value={values.values[i].label || ''}
                                error={Boolean(valueErrors.label)}
                                helperText={valueErrors.label}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                                label="Label"
                                my={2}
                                size="small"
                              />
                              <TextField
                                name={`values.${i}.value`}
                                value={values.values[i].value || ''}
                                error={Boolean(valueErrors.value)}
                                helperText={valueErrors.value}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                                label="Value"
                                my={2}
                                size="small"
                              />
                              <TextField
                                name={`values.${i}.columnName`}
                                value={values.values[i].columnName || ''}
                                error={Boolean(valueErrors.columnName)}
                                helperText={valueErrors.columnName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                                label="Column Name"
                                my={2}
                                size="small"
                              />
                              {(values.type === 'ALIGNED' || values.type === 'UNALIGNED') && (
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  paddingTop={2}
                                  paddingLeft={1}
                                >
                                  Default Sub Value
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name={`values.${i}.isDefault`}
                                        checked={values.values[i].isDefault}
                                        onChange={(event) =>
                                          setFieldValue(
                                            `values.${i}.isDefault`,
                                            event.target.checked
                                          )
                                        }
                                      />
                                    }
                                  />
                                </Stack>
                              )}
                              <Stack
                                direction="row"
                                justifyContent="flex-end"
                                paddingTop={2}
                                paddingLeft={1}
                              >
                                <Button
                                  onClick={() => {
                                    arrayHelpers.remove(i);
                                  }}
                                  disabled={values.values.length === 1}
                                >
                                  Remove Value
                                </Button>
                              </Stack>
                            </div>
                          );
                        })
                      }
                    </FieldArray>
                  </FormControl>
                </Grid>
              )}
              <Modal
                open={isDelete}
                handleClose={() => setDelete(false)}
                title="Delete Visual Filter"
                content="Are you sure you want to delete the visual filter?"
                handleSuccess={() => deleteVisualFilter({ organizationId, id: visualFilter?.id })}
                closeLabel="Cancel"
                successLabel="Delete"
                variant="contained"
                color="error"
              />
              <Modal
                open={isUpdate}
                handleClose={() => {
                  if (dirty) {
                    handleReset();
                  }
                  setUpdate(false);
                }}
                title="Update Visual Filter"
                content="Are you sure you want to update the visual filter?"
                handleSuccess={() => {
                  formElement.current?.click();
                  setUpdate(false);
                }}
                closeLabel="Cancel"
                successLabel="Update"
                variant="contained"
                color="primary"
              />
            </Grid>
            <input ref={formElement} style={{ visibility: 'hidden' }} type="submit" />
          </form>
        )}
      </Formik>
    </Box>
  );
};
//
export default UpdateVisualFilterForm;
