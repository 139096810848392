/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
//
const selectDomain = (state) => state['feature/configure-event-categories'] || initialState;
/**
 * Getting the loading state
 */
export const selectLoader = createSelector(
  [selectDomain],
  (configureEventCategoryState) => configureEventCategoryState.loading
);
/**
 * Retrieving the major event categories
 */
export const selectEventCategoryConfigurations = createSelector(
  [selectDomain],
  (configureEventCategoryState) => configureEventCategoryState.eventCategories
);
/**
 * Retrieving the minor event categories of a major event
 */
export const selectMinorEventCategories = createSelector(
  [selectDomain],
  (configureEventCategoryState) => configureEventCategoryState.minorEventCategories
);
/**
 * Getting the updated minor event state into the component
 */
export const selectMinorEventUpdateState = createSelector(
  [selectDomain],
  (configureEventCategoryState) => configureEventCategoryState.updatedMinorEvent
);
/**
 * Getting the  hotels selection list for dropdown  state into the component
 */
export const selectHotelsSelectionList = createSelector([selectDomain], (usersState) =>
  usersState.hotelSelectionList?.results?.map((data) => ({
    label: data?.name,
    id: data?.id,
    pmsDate: data?.pmsStartDate,
  }))
);
/**
 * Getting the filtered minor category by  id state into the component
 */
const getFirstParam = (_, param) => param;
export const selectMinorCategoryById = createSelector(
  [selectMinorEventCategories, getFirstParam],
  (selectedMinorCategories, Id) =>
    selectedMinorCategories?.results?.filter((category) => category.id === Id)[0]
);
