import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { isEmpty } from 'modules/common/helpers/object';
import styles from 'modules/transactions/components/inner-filter/inner-filter.module.scss';
/**
 * Compare hotels window component common for the dashboard component
 * @param {Array} hotelList - hotel list
 * @param {*} focusOn - selected compared hotels
 * @param {Function} handleCompareHotelsChange - Function to set compare hotels value
 * @returns
 */
const CompareHotelsWindow = ({ focusOn, hotelList, handleCompareHotelsChange }) => {
  //
  const [allHotelObj, setAllHotelObj] = useState({});
  useEffect(() => {
    if (isEmpty(allHotelObj) && hotelList.length > 0) {
      const allHotelObject = {};
      Object.keys(hotelList[0]).forEach((key) => {
        allHotelObject[key] = 'All';
      });
      setAllHotelObj([allHotelObject]);
    }
  }, [hotelList]);
  //
  return (
    <Autocomplete
      sx={{ width: { sm: 350 }, pb: { sm: 2, md: 0, lg: 0 } }}
      size="small"
      id="tags-outlined"
      className={styles.headerDropdown}
      options={!isEmpty(allHotelObj) ? hotelList?.concat(allHotelObj) : hotelList ?? []}
      getOptionLabel={(option) => (option.label ? option.label : '')}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(e, value) => {
        handleCompareHotelsChange(e, value);
      }}
      disableClearable
      value={focusOn ?? null}
      renderInput={(params) => (
        <TextField label="Select Hotel" {...params} size="small" color="primary" />
      )}
      fullWidth
      componentsProps={{
        paper: {
          sx: {
            minWidth: 350,
            whiteSpace: 'unset',
            wordBreak: 'break-all',
          },
        },
      }}
    />
  );
};
//
export default CompareHotelsWindow;
