import { useState, useRef, useEffect } from 'react';
import { Grid, IconButton, Stack } from '@mui/material';
import { ChevronsLeft, ChevronsRight, ChevronLeft, ChevronRight } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { transactionActions } from 'modules/transactions/slice';
import { ENVIRONMENT } from 'config';
import { BookingDateRangePicker } from 'modules/common/components';
import {
  formatDateLabel,
  getNextMonthStayDate,
  getPreviousMonthStayDate,
} from 'modules/transactions/functions';
import {
  differenceInDays,
  addDays,
  subDays,
  addSeconds,
  differenceInCalendarMonths,
} from 'date-fns';
import { BOOKING_DATE_START_DATE } from 'modules/common/constants/date-range';
import styles from 'modules/transactions/components/inner-filter/inner-filter.module.scss';
import { selectTargetDate, selectTransactionBookingDate } from 'modules/transactions/selectors';
/**
 * Booking date-range picker for each widget with scrollable date navigation
 * @param {String} latestDate - Latest booking date of selected hotel
 * @returns
 */
const BookingDatePicker = ({ latestDate, id = '' }) => {
  const dispatch = useDispatch();

  // Current booking date values for each tab is selected here. Refer each selector function for details
  const transactionBookingDate = useSelector(selectTransactionBookingDate);
  const targetDate = useSelector(selectTargetDate);

  // Local states to maintain selected date values and to disable scroll navigation icons are defined here
  const [dates, setDates] = useState(transactionBookingDate);
  const [triggerTime, setTriggerTime] = useState();
  const [disableGetPreviousMonth, setDisableGetPreviousMonth] = useState(false);
  const [disableGetNextMonth, setDisableGetNextMonth] = useState(false);
  const [disableGetPreviousPeriod, setDisableGetPreviousPeriod] = useState(false);
  const [disableGetNextPeriod, setDisableGetNextPeriod] = useState(false);
  //
  const triggerTimeRef = useRef(triggerTime);
  const datesRef = useRef(dates);
  triggerTimeRef.current = triggerTime;
  datesRef.current = dates;

  // Update global states of booking dates based on the report type to trigger Power BI filter
  const updateStore = (item) => {
    const bookingDate = {
      startDate: new Date(item.selection.startDate).toISOString(),
      endDate: new Date(item.selection.endDate).toISOString(),
      key: 'selection',
    };
    dispatch(transactionActions.setTransactionBookingDate(bookingDate));
  };
  //
  const handleChange = (item, fromDatePicker = true) => {
    setDates(item.selection);
    // Apply power bi filters directly if the change triggered from the date picker
    if (fromDatePicker) {
      updateStore(item);
    } else {
      // Implemented 2s delay in applying Power BI filter for the date change triggered through scroll navigation
      setTimeout(() => {
        const currentTime = new Date();
        if (currentTime.getTime() - triggerTimeRef.current.getTime() > 999) {
          updateStore(item);
        }
      }, Number(ENVIRONMENT.TIMEOUT_DELAY));
    }
  };

  // Move back by 1 month scroll navigation implementation
  const getPreviousMonth = () => {
    const newDates = getPreviousMonthStayDate(dates.startDate, dates.endDate);
    let { newStartDate, newEndDate } = newDates;
    //
    if (differenceInDays(new Date(BOOKING_DATE_START_DATE), newStartDate) >= 0)
      newStartDate = new Date(BOOKING_DATE_START_DATE);
    if (differenceInDays(new Date(BOOKING_DATE_START_DATE), newEndDate) >= 0)
      newEndDate = new Date(BOOKING_DATE_START_DATE);
    //
    setDates((prev) => ({
      ...prev,
      startDate: newStartDate,
      endDate: newEndDate,
    }));
    const item = {
      selection: {
        startDate: newStartDate,
        endDate: newEndDate,
        key: 'selection',
      },
    };
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false);
  };

  // Move forward by 1 month scroll navigation implementation
  const getNextMonth = () => {
    const newDates = getNextMonthStayDate(dates.startDate, dates.endDate);
    let { newStartDate, newEndDate } = newDates;
    // To prevent selected booking date from going beyond current date
    if (differenceInDays(new Date(), newStartDate) <= 0) newStartDate = new Date();
    if (differenceInDays(new Date(), newEndDate) <= 0) newEndDate = new Date();
    //
    setDates((prev) => ({
      ...prev,
      startDate: newStartDate,
      endDate: newEndDate,
    }));
    const item = {
      selection: {
        startDate: newStartDate,
        endDate: newEndDate,
        key: 'selection',
      },
    };
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false);
  };

  // Move back by 1 period scroll navigation implementation
  const getPreviousDate = () => {
    const range = differenceInDays(new Date(dates.endDate), new Date(dates.startDate));
    const newEndDate = subDays(new Date(dates.startDate), 1);
    let newStartDate;
    newStartDate = subDays(newEndDate, range);
    // To check if new start date is less than default booking start date
    if (
      differenceInDays(
        subDays(
          subDays(new Date(dates?.startDate), 1),
          differenceInDays(new Date(dates?.endDate), new Date(dates?.startDate))
        ),
        new Date(BOOKING_DATE_START_DATE)
      ) <= 0
    ) {
      newStartDate = new Date(BOOKING_DATE_START_DATE);
    } else {
      newStartDate = subDays(newEndDate, range);
    }
    //
    setDates((prev) => ({
      ...prev,
      startDate: newStartDate,
      endDate: newEndDate,
    }));
    const item = {
      selection: {
        startDate: newStartDate,
        endDate: newEndDate,
        key: 'selection',
      },
    };
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false);
  };

  // Move forward by 1 period scroll navigation implementation
  const getNextDate = () => {
    const range = differenceInDays(new Date(dates.endDate), new Date(dates.startDate));
    const newStartDate = addDays(new Date(dates.endDate), 1);
    let newEndDate;
    newEndDate = addDays(newStartDate, range);
    // To check if new end date is greater than today's date
    if (
      differenceInDays(
        addDays(
          addDays(new Date(dates?.endDate), 1),
          differenceInDays(new Date(dates?.endDate), new Date(dates?.startDate))
        ),
        new Date()
      ) >= 0
    ) {
      newEndDate = new Date();
    } else {
      newEndDate = addDays(newStartDate, range);
    }
    setDates((prev) => ({
      ...prev,
      startDate: newStartDate,
      endDate: newEndDate,
    }));
    const item = {
      selection: {
        startDate: newStartDate,
        endDate: newEndDate,
        key: 'selection',
      },
    };
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false);
  };

  //
  useEffect(() => {
    // Get previous month icon disabled if the selected start date is less than default booking start date
    setDisableGetPreviousMonth(
      differenceInCalendarMonths(new Date(BOOKING_DATE_START_DATE), new Date(dates?.startDate)) >= 0
    );
    //  Get previous period icon disabled if the selected start date is less than default booking start date
    setDisableGetPreviousPeriod(
      differenceInDays(new Date(BOOKING_DATE_START_DATE), new Date(dates?.startDate)) >= 0
    );
    // Get next period icon disabled if the selected end date is greater than today
    setDisableGetNextPeriod(differenceInDays(new Date(), new Date(dates?.endDate)) <= 0);

    //  Get next month icon disabled if the selected end date is greater than today
    setDisableGetNextMonth(differenceInCalendarMonths(new Date(), new Date(dates?.endDate)) <= 0);
  }, [dates]);
  //
  return (
    <Grid container direction="row" alignItems="center" justifyContent="center" paddingTop={0}>
      <Stack direction="row" xs={12} paddingTop={1}>
        <IconButton
          sx={{
            padding: 0,
            marginRight: 1,
          }}
          size="small"
          onClick={getPreviousMonth}
          className={styles.scrollIcon}
          disabled={disableGetPreviousMonth}
        >
          <ChevronsLeft fontSize="small" strokeWidth={4} />
        </IconButton>

        <IconButton
          sx={{
            padding: 0,
            marginRight: 0.5,
          }}
          className={styles.scrollIcon}
          size="small"
          onClick={getPreviousDate}
          disabled={disableGetPreviousPeriod}
        >
          <ChevronLeft fontSize="small" strokeWidth={4} />
        </IconButton>
        <BookingDateRangePicker
          id={id}
          handleChange={(item) => handleChange(item)}
          ranges={[transactionBookingDate]}
          dateValue={formatDateLabel(dates)}
          cssClassName={styles.bookingCalendarElement}
          maxDate={new Date()}
          targetDate={targetDate}
          dataFeedDate={latestDate}
        />
        <IconButton
          sx={{
            padding: 0,
            marginLeft: 1,
          }}
          className={styles.scrollIcon}
          onClick={getNextDate}
          size="small"
          disabled={disableGetNextPeriod}
        >
          <ChevronRight fontSize="small" strokeWidth={4} />
        </IconButton>
        <IconButton
          sx={{
            padding: 0,
            marginLeft: 0.5,
          }}
          size="small"
          className={styles.scrollIcon}
          onClick={getNextMonth}
          disabled={disableGetNextMonth}
        >
          <ChevronsRight fontSize="small" strokeWidth={4} />
        </IconButton>
      </Stack>
    </Grid>
  );
};
//
export default BookingDatePicker;
