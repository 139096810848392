import { withTheme } from '@emotion/react';
import { Grid, Toolbar, Typography } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import NavbarUserDropdown from './navbar-user-dropdown';
import { AppBar, IconButton, SmallAvatar } from './style';
//
const Navbar = ({ onDrawerToggle }) => (
  <AppBar
    position="sticky"
    elevation={0}
    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: 64 }}
  >
    <Toolbar>
      <Grid container alignItems="center" justifyContent="space-between" direction="row">
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <SmallAvatar variant="square" src="/static/assets/logo-light.png" />
            </Grid>
            <Grid item>
              <Typography
                variant="h3"
                sx={{
                  color: '#e5e5e5',
                }}
              >
                Admin Portal
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <NavbarUserDropdown />
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
);
//
export default withTheme(Navbar);
