import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  Autocomplete,
  TextField,
  Grid,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from 'modules/common/notifications/slice';
import { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { selectHotelGroups } from 'modules/hotel-set/selectors';
import { hotelSetActions } from 'modules/hotel-set/slice';
import CustomToggleSwitch from 'modules/common/components/toggle-switch';
import AggregationTransferList from '../add-hotel-group-transfer-list';
import QueryBox from '../query-box';
import SaveFilterDialog from '../save-filter';

/**
 * Custom aggregation filter component is implemented
 * @param {Boolean} open - To open/ close dialog box
 * @param {Function} onClose - Function to trigger on close event
 * @returns
 */
const AddHotelGroup = ({
  open,
  onClose,
  hotelList,
  selectedHotelGroupName,
  isEditable = false,
  setIsEditable = () => {},
}) => {
  const dispatch = useDispatch();
  //
  const [groupHotelList, setGroupHotelList] = useState([]);
  const [isQueryEnabled, setIsQueryEnabled] = useState(false);
  const [loadFilter, setLoadFilter] = useState(false);
  const [temGroupList, setTemGroupList] = useState([]);
  const filters = useSelector(selectHotelGroups);
  //
  const [loadList, setLoadList] = useState([]);

  useEffect(() => {
    if (filters?.results?.length > 0) {
      const list = filters?.results?.map((a) => ({
        id: a?.hotelGroups?.hotelGroupName,
        label: a?.hotelGroups?.hotelGroupName,
        query: a?.hotelGroups?.hotelQuery,
      }));

      setLoadList(list);
    }
  }, [filters]);
  //
  useEffect(() => {
    if (isEditable && loadList?.length > 0 && open) {
      const selectedGroup = loadList?.find((a) => a.label === selectedHotelGroupName);
      setLoadFilter(true);
      dispatch(hotelSetActions.setHotelGroupQuery(selectedGroup?.query?.trim()));
      dispatch(hotelSetActions.setSelectedHotelGroupName(selectedGroup?.label));
    }
  }, [isEditable, loadList, selectedHotelGroupName, open]);
  // Triggered once when the component mounted
  useEffect(() => {
    dispatch(notificationActions.resetNotification());
  }, []);
  //
  useEffect(() => {
    // Update global and local states
    if (open && !isEditable) {
      setGroupHotelList([]);
      setTemGroupList([]);
      dispatch(hotelSetActions.setHotelGroupQuery(''));
      dispatch(hotelSetActions.setSelectedHotelGroupName(''));
    }
  }, [open, isEditable]);
  //
  const modalAction = (reason, action) => {
    if (reason && reason === 'backdropClick') return;
    onClose(action);
  };
  //
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => modalAction(reason, false)}
      aria-labelledby="form-dialog-title"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <DialogTitle sx={{ paddingBottom: 2 }}>
        <Grid container item flexDirection="row" justifyContent="space-between" xs={12}>
          <Grid item xs={3.5}>
            <Typography variant="h5">{isEditable ? 'Update' : 'Add'} Hotel Set</Typography>
          </Grid>

          <Grid item xs={5}>
            <Autocomplete
              sx={{
                minWidth: 150,
              }}
              options={loadList ?? []}
              getOptionLabel={(option) => (option.label ? option.label : '')}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              filterSelectedOptions
              onChange={(event, value) => {
                setLoadFilter(true);
                dispatch(hotelSetActions.setHotelGroupQuery(value?.query));
                dispatch(hotelSetActions.setSelectedHotelGroupName(value?.label));
              }}
              disableClearable
              disabled={loadList?.length < 1}
              renderInput={(params) => (
                <TextField {...params} label="Hotel Sets" size="small" color="primary" />
              )}
            />
          </Grid>
          <Grid item xs={3.5}>
            <IconButton
              size="small"
              aria-label="close"
              onClick={() => {
                modalAction(false);
                setIsEditable(false);
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <X />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            borderRadius: '8px',
            borderColor: '#8c8787 !important',
            border: 1,
            padding: 4,
          }}
        >
          <AggregationTransferList
            focusOnList={hotelList}
            groupList={groupHotelList}
            setGroupList={(value) => setGroupHotelList(value)}
            isQueryEnabled={isQueryEnabled}
            temGroupList={temGroupList}
            setTemGroupList={setTemGroupList}
          />
        </Box>
        <Box
          sx={{
            borderRadius: '8px',
            borderColor: '#8c8787 !important',
            border: 1,
            padding: 2,
            marginBottom: 4,
            marginTop: 4,
          }}
        >
          <CustomToggleSwitch
            label="Advanced Querying"
            title=""
            onChange={(event) => setIsQueryEnabled(event.target.checked)}
            checked={isQueryEnabled}
          />

          <QueryBox
            setGroupList={setGroupHotelList}
            onClose={() => {
              modalAction(false);
            }}
            open={open}
            isQueryEnabled={isQueryEnabled}
            loadFilter={loadFilter}
            setLoadFilter={setLoadFilter}
            focusOnList={hotelList}
            setTemGroupList={setTemGroupList}
          />
        </Box>
        <SaveFilterDialog
          groupList={groupHotelList}
          setGroupList={setGroupHotelList}
          setTemGroupList={setTemGroupList}
          onClose={() => {
            modalAction(false);
            setIsEditable(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
//
export default AddHotelGroup;
