import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'modules/common/components';
import { selectHotels, selectLoader } from 'modules/hotels/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { withTheme } from '@emotion/react';
import { hotelActions } from 'modules/hotels/slice';
import { isEmpty } from 'modules/common/helpers/object';
import HotelTableView from '../hotel-table';
import UpdateVisualFilterForm from '../update-hotel';
import AddHotelForm from '../add-hotel';

/**
 * Hotel view to add, edit and delete hotels
 * @param {*} param0
 * @returns
 */
const HotelView = ({ theme }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const loading = useSelector(selectLoader);
  const hotels = useSelector(selectHotels);
  //
  const [selectedHotelId, setSelectedHotelId] = useState({});
  const [open, setOpen] = useState(false);
  const [show, isShow] = useState(false);
  //
  useEffect(() => {
    const query = `limit=${PAGINATION.VISUAL_FILTERS_PER_PAGE_COUNT}&forceFetch=true`;
    dispatch(
      hotelActions.getHotels({
        organizationId,
        query,
      })
    );
    const currencyQuery = `limit=${PAGINATION.VISUALS_PER_PAGE_COUNT}&sortBy=name:asc`;
    dispatch(
      hotelActions.getCurrencyData({
        organizationId,
        query: currencyQuery,
      })
    );
  }, [organizationId]);
  //
  return (
    <Loader loading={loading}>
      <Card>
        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
          <Grid container direction="row" justifyContent="space-between" pb={4}>
            <Typography variant="h3">Configure Hotels</Typography>
            <Button
              disabled={show}
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Add Hotel
            </Button>
          </Grid>
          <Grid container direction="row" spacing={2} justifyContent="space-between">
            <Grid item sx={{ flexGrow: 1, flexBasis: 1 }}>
              {!isEmpty(hotels) && (
                <HotelTableView isShow={isShow} setSelectedHotelId={setSelectedHotelId} />
              )}
            </Grid>
            <UpdateVisualFilterForm show={show} isShow={isShow} userId={selectedHotelId} />
          </Grid>
        </CardContent>
        <AddHotelForm open={open} onClose={() => setOpen(false)} />
      </Card>
    </Loader>
  );
};
//
export default withTheme(HotelView);
