import React from 'react';
import styled from '@emotion/styled';
import { Power } from 'react-feather';

import { Tooltip, Menu, MenuItem, IconButton as MuiIconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { authActions } from 'modules/common/auth/slice';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const NavbarUserDropdown = () => {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const dispatch = useDispatch();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    dispatch(authActions.signOut());
  };
  return (
    <>
      <Tooltip title="Account">
        <IconButton
          aria-owns={anchorMenu ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </>
  );
};

export default NavbarUserDropdown;
