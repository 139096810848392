import { BOOKING_DATE } from 'modules/transactions/constants';
/**
 * Common date change patterns are defined here
 */
export const DATE_PATTERN = 'MM-dd-yyyy';
//
export const YEAR_MONTH_DAY_PATTERN = 'yyyy-MM-dd';
//
export const DATE_TIME_PATTERN = 'MM-dd-yyyy H:m:s';
//
export const TIME_PATTERN = 'hh:mm:ss a';
//
export const ISO_YEAR_MONTH_DAY_PATTERN = 'yyyyMMdd';
/**
 * Default stay date min date is defined here
 */
export const STAY_DATE_START_DATE = '2015/01/01';
/**
 * Default stay date max date is defined here
 */
export const STAY_DATE_END_DATE = '2025/12/31';
/**
 * Default booking date min date is defined here
 */
export const BOOKING_DATE_START_DATE = BOOKING_DATE;
