import {
  FormControl,
  Grid,
  Box,
  IconButton,
  Alert,
  Tooltip,
  Autocomplete,
  Typography,
  TextField,
} from '@mui/material';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { selectNotification } from 'modules/common/notifications/selectors';
import { Trash, X, Save } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { Modal } from 'modules/common/components';
import { visualActions } from 'modules/visual/slice';
import { TAG_LIST } from 'modules/visual/constants';
import { updateVisualValidation } from 'modules/visual/validation/visual-form-validation';
import { selectVisualByID } from 'modules/visual/selectors';
import { isEmpty } from 'modules/common/helpers/object';
//
const FormStyles = { borderRadius: 10 };
/**
 * Form to update visual
 * @param {*} param0
 * @returns
 */
const UpdateVisualForm = ({ show, isShow, visualId }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const visual = useSelector(selectVisualByID);
  //
  const [isDelete, setDelete] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: '',
    pageName: '',
    pageDisplayName: '',
    reportId: '',
    reportName: '',
    tags: [],
    order: '',
    datasetId: '',
  });
  const formElement = useRef(null);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      isShow(false);
    }
  }, [notification]);
  //
  useEffect(() => {
    if (!isEmpty(visual)) {
      setInitialValues({
        name: visual.name,
        pageName: visual.pageName,
        pageDisplayName: visual.pageDisplayName,
        reportId: visual.reportId,
        reportName: visual.reportName,
        tags: visual.tags,
        order: visual.order,
        datasetId: visual.datasetId,
      });
    }
  }, [visual]);
  //
  const deleteVisual = (payload) => {
    dispatch(visualActions.deleteVisual(payload));
    setDelete(false);
  };
  //
  const onSubmitUpdateVisual = async (values) => {
    const valueObj = {
      name: values.name,
      order: values.order,
      reportId: values.reportId,
      pageName: values.pageName,
      tags: values.tags,
    };
    dispatch(visualActions.updateVisual({ ...valueObj, organizationId, visualId }));
  };
  //
  return (
    <Box
      xs={12}
      md={4}
      ml={4}
      mt={2}
      component={Grid}
      container
      item
      display="flex"
      direction="column"
      sx={{ backgroundColor: 'white', flexShrink: 0, display: show ? 'flex' : 'none' }}
    >
      Update Visual
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={updateVisualValidation}
        onSubmit={onSubmitUpdateVisual}
      >
        {({
          errors,
          dirty,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleReset,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container direction="row" justifyContent="space-between" px={2} mb={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                {visual?.name}
              </Typography>
              <Grid>
                <Tooltip title="Update">
                  <IconButton
                    disabled={isSubmitting}
                    onClick={() =>
                      errors.name || errors.order || errors.tags ? '' : setUpdate(true)
                    }
                    color="inherit"
                    size="small"
                  >
                    <Save />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton color="error" size="small" onClick={() => setDelete(true)}>
                    <Trash />
                  </IconButton>
                </Tooltip>
                <IconButton color="inherit" size="small" onClick={() => isShow(false)}>
                  <X />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={2} direction="column">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="name"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                    label="Visual Name"
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="reportId"
                    value={values.reportId}
                    error={Boolean(touched.reportId && errors.reportId)}
                    helperText={touched.reportId && errors.reportId}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                    label="Report ID"
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="reportName"
                    value={values.reportName}
                    error={Boolean(touched.reportName && errors.reportName)}
                    helperText={touched.reportName && errors.reportName}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                    label="Report Name"
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="pageName"
                    value={values.pageName}
                    error={Boolean(touched.pageName && errors.pageName)}
                    helperText={touched.pageName && errors.pageName}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                    label="Page Name"
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="pageDisplayName"
                    value={values.pageDisplayName}
                    error={Boolean(touched.pageDisplayName && errors.pageDisplayName)}
                    helperText={touched.pageDisplayName && errors.pageDisplayName}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                    label="Page Display Name"
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4}>
                <FormControl sx={FormStyles} fullWidth>
                  {!isEmpty(visual) && (
                    <Autocomplete
                      size="small"
                      value={values.tags}
                      fullWidth
                      multiple
                      disableClearable
                      disableCloseOnSelect
                      name="tags"
                      limitTags={3}
                      isOptionEqualToValue={(option, value) => option === value}
                      options={TAG_LIST || []}
                      onChange={(e, value) => setFieldValue('tags', value)}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          name="tags"
                          error={Boolean(errors.tags)}
                          helperText={errors.tags}
                          label="Tags"
                          size="small"
                          {...params}
                          fullWidth
                        />
                      )}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          padding: 0,
                          height: 'fit-content',
                        },
                        marginY: 2,
                      }}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4}>
                <TextField
                  type="number"
                  name="order"
                  value={values.order}
                  error={Boolean(touched.order && errors.order)}
                  helperText={touched.order && errors.order}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                  label="Order"
                  my={2}
                  size="small"
                />
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="datasetId"
                    value={values.datasetId}
                    error={Boolean(touched.datasetId && errors.datasetId)}
                    helperText={touched.datasetId && errors.datasetId}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                    label="Dataset ID"
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Modal
                open={isDelete}
                handleClose={() => setDelete(false)}
                title="Delete Visual"
                content="Are you sure you want to delete the visual?"
                handleSuccess={() => deleteVisual({ organizationId, id: visual?.id })}
                closeLabel="Cancel"
                successLabel="Delete"
                variant="contained"
                color="error"
              />
              <Modal
                open={isUpdate}
                handleClose={() => {
                  if (dirty) {
                    handleReset();
                  }
                  setUpdate(false);
                }}
                title="Update Visual"
                content="Are you sure you want to update the visual ?"
                handleSuccess={() => {
                  formElement.current?.click();
                  setUpdate(false);
                }}
                closeLabel="Cancel"
                successLabel="Update"
                variant="contained"
                color="primary"
              />
            </Grid>
            <input ref={formElement} style={{ visibility: 'hidden' }} type="submit" />
          </form>
        )}
      </Formik>
    </Box>
  );
};
//
export default UpdateVisualForm;