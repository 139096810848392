import * as yup from 'yup';

/**
 * Here mentioned users feature change password validations
 */
const changePasswordValidation = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required('New password is a required field')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'New password should contain at least 8 characters with One Uppercase, One Lowercase and One Number'
    ),
  confirmPassword: yup
    .string()
    .trim()
    .required('Confirm Password is a required field')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});
//
export default changePasswordValidation;
