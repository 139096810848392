import { useEffect, useState } from 'react';
import { withTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Card, CardContent, Button } from '@mui/material';
import { isEmpty } from 'modules/common/helpers/object';
import { grey } from '@mui/material/colors';
import { Loader } from 'modules/common/components';
import { configureEventCategoriesActions } from 'modules/event-configuration/slice';
import {
  selectEventCategoryConfigurations,
  selectLoader,
} from 'modules/event-configuration/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import ORDER_BY from 'modules/common/constants/order-by';
import PAGINATION from 'modules/common/constants/pagination-limits';
import MinorEventDataDrawer from '../minor-event-data-drawer';
import MajorEventsTableView from '../major-events-table';
import AddMajorCategoryDialog from '../add-major-category';
/**
 * Main view for event category configuration layout
 */
const EventCategoryConfigurationView = ({ theme }) => {
  const dispatch = useDispatch();
  //
  const loading = useSelector(selectLoader);
  const organizationId = useSelector(selectOrganizationId);
  const eventCategories = useSelector(selectEventCategoryConfigurations);
  //
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedEventCategoryId, setSelectedEventCategoryId] = useState({});
  //
  useEffect(() => {
    const query = `limit=${PAGINATION.MAJOR_EVENT_CATEGORIES_PER_PAGE}`;
    dispatch(
      configureEventCategoriesActions.configureEventCategories({
        organizationId,
        query,
      })
    );
  }, []);
  //
  useEffect(() => {
    const query = `limit=${PAGINATION.MAJOR_EVENT_CATEGORIES_PER_PAGE}&sortBy=name:${ORDER_BY.ASCENDING}&forceFetch=true`;
    dispatch(configureEventCategoriesActions.getSelectionHotelsList({ organizationId, query }));
  }, []);
  //
  return (
    <Loader loading={loading}>
      <Card>
        <CardContent
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Grid container direction="row" justifyContent="space-between" pb={4}>
            <Grid>
              <Typography variant="h2">Configure event categories</Typography>
              <Grid container direction="row">
                <Typography variant="h4" sx={{ marginRight: 5 }}>
                  Major event categories{' '}
                </Typography>
                <Typography variant="h6" sx={{ color: grey[500] }}>
                  ( {eventCategories?.totalResults} categories )
                </Typography>
              </Grid>
            </Grid>
            <Button
              disabled={show}
              sx={{ m: 2 }}
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Add Event
            </Button>
          </Grid>
          <Grid container direction="row" spacing={2} justifyContent="space-between">
            <Grid item sx={{ flexGrow: 1, flexBasis: 1 }}>
              {!isEmpty(eventCategories) && (
                <MajorEventsTableView
                  isShow={setShow}
                  setSelectedMajorEventId={setSelectedEventCategoryId}
                />
              )}
            </Grid>
            <MinorEventDataDrawer
              show={show}
              isShow={setShow}
              majorEventCategoryId={selectedEventCategoryId}
            />
          </Grid>
        </CardContent>
      </Card>
      <AddMajorCategoryDialog open={open} onClose={() => setOpen(false)} />
    </Loader>
  );
};
//
export default withTheme(EventCategoryConfigurationView);
