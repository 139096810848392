/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { forgotPasswordActions } from 'modules/forgot-password/slice';
import { hotelActions } from 'modules/hotels/slice';
import { resetPasswordActions } from 'modules/reset-password/slice';
import { signInActions } from 'modules/sign-in/slice';
import { userInvitesActions } from 'modules/user-invitation/slice';
import { usersActions } from 'modules/users/slice';
import { verifyCallbackActions } from 'modules/verify-callback/slice';
import { visualFilterActions } from 'modules/visual-filter/slice';
import { visualActions } from 'modules/visual/slice';
/**
 * Initial states of notification function are defined here
 */
export const initialState = {
  isEnabled: false,
  message: '',
  type: ERROR_TYPES.SUCCESS,
};
/**
 * All actions related to notification are defined here
 */
export const notificationSlice = createSlice({
  name: 'feature/notification',
  initialState,
  reducers: {
    resetNotification(state) {
      state.isEnabled = false;
      state.message = '';
      state.type = ERROR_TYPES.SUCCESS;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(signInActions.signInFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.WARNING,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(forgotPasswordActions.forgotPasswordSucceeded, (state, action) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: action?.payload,
        isEnabled: true,
      }))
      .addCase(verifyCallbackActions.verifyTokenFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(resetPasswordActions.resetPasswordSucceeded, (state, action) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: action?.payload,
        isEnabled: true,
      }))
      .addCase(resetPasswordActions.resetPasswordFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(visualActions.createVisualsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(visualActions.createVisualsSucceeded, (state, action) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: action?.payload,
        isEnabled: true,
      }))
      .addCase(visualActions.deleteVisualFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(visualActions.deleteVisualSucceeded, (state, action) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: action?.payload,
        isEnabled: true,
      }))
      .addCase(visualActions.updateVisualFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(visualActions.updateVisualSucceeded, (state, action) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: action?.payload,
        isEnabled: true,
      }))
      .addCase(visualFilterActions.createVisualFilterSucceeded, (state, action) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: action?.payload,
        isEnabled: true,
      }))
      .addCase(visualFilterActions.createVisualFilterFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(visualFilterActions.updateVisualFilterFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(visualFilterActions.updateVisualFilterSucceeded, (state, action) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: action?.payload,
        isEnabled: true,
      }))
      .addCase(visualFilterActions.deleteVisualFilterSucceeded, (state, action) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: action?.payload,
        isEnabled: true,
      }))
      .addCase(visualFilterActions.deleteVisualFilterFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(usersActions.inviteUserSucceeded, (state, action) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: action?.payload,
        isEnabled: true,
      }))
      .addCase(usersActions.inviteUserFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(userInvitesActions.resendInviteSucceeded, (state, action) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: action?.payload,
        isEnabled: true,
      }))
      .addCase(userInvitesActions.resendInviteFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(usersActions.changeUserPasswordSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Password Changed Successfully',
        isEnabled: true,
      }))
      .addCase(usersActions.changeUserPasswordFailed, (state) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: 'Password Change Failed!',
        isEnabled: true,
      }))
      .addCase(usersActions.createUsersFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(usersActions.createUsersSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully created',
        isEnabled: true,
      }))
      .addCase(usersActions.createGeneralUsersFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(usersActions.createGeneralUsersSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully created',
        isEnabled: true,
      }))
      .addCase(usersActions.createAdminUsersFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(usersActions.createAdminUsersSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully created',
        isEnabled: true,
      }))
      .addCase(usersActions.updateUserFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(usersActions.updateUserSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully updated',
        isEnabled: true,
      }))
      .addCase(usersActions.deleteUserFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(usersActions.deleteUserSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Hotel successfully deleted',
        isEnabled: true,
      }))
      .addCase(hotelActions.updateHotelFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(hotelActions.updateHotelSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Hotel successfully updated',
        isEnabled: true,
      }))
      .addCase(hotelActions.createHotelFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(hotelActions.createHotelSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Hotel successfully created',
        isEnabled: true,
      }));
  },
});
//
export const { actions: notificationActions } = notificationSlice;
