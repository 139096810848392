import { withTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { selectAuditLogs } from 'modules/audit-logs/selectors';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TABLE_HEADER_FONT_COLOR } from 'modules/common/constants/style';
import { Grid } from '@mui/material';
import { isEmpty } from 'modules/common/helpers/object';
import './index.scss';
/**
 * Data grid to display all user invitations
 * @returns
 */
const AuditLogsTable = () => {
  //
  const auditLogs = useSelector(selectAuditLogs);
  const [filteredAuditLogs, setFilteredAuditLogs] = useState([]);
  //
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      hide: true,
      flex: 1,
    },
    {
      field: 'requestId',
      headerName: 'Request ID',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'username',
      headerName: 'Username',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'eventName',
      headerName: 'Event Name',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'component',
      headerName: 'Component',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'eventStartTime',
      headerName: 'Event Start Time',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'eventEndTime',
      headerName: 'Event End Time',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
  ];
  //
  useEffect(() => {
    if (!isEmpty(auditLogs)) {
      const formattedAuditLogs = [];
      auditLogs?.forEach((auditLog) => {
        auditLog?.events.forEach((auditEvent) => {
          formattedAuditLogs.push({
            id: Math.random().toString(16).slice(2),
            requestId: auditLog.requestId,
            username: auditLog.username || 'N/A',
            eventName: auditEvent.eventName,
            component: auditEvent.component || 'N/A',
            eventStartTime: auditLog.createdAt,
            eventEndTime: auditLog.updatedAt,
          });
        });
      });
      setFilteredAuditLogs(formattedAuditLogs);
    } else {
      setFilteredAuditLogs([]);
    }
  }, [auditLogs]);
  //
  return (
    <Grid container sx={{ height: 600 }}>
      <DataGrid
        sx={{
          overflowWrap: 'break-word',
          '& .super-app-theme--header': {
            backgroundColor: 'white',
            color: TABLE_HEADER_FONT_COLOR,
          },
          '& .MuiDataGrid-main': {
            backgroundColor: 'white',
          },
          '.MuiDataGrid-footerContainer': {
            backgroundColor: 'white',
          },
        }}
        rowHeight={50}
        rows={filteredAuditLogs || []}
        columns={columns}
      />
    </Grid>
  );
};
//
export default withTheme(AuditLogsTable);
