import { VISUAL_HEADER_TYPES } from './constants';

const mapper = {
  Transactions_Agg: {
    groupBy: '',
    headertype: VISUAL_HEADER_TYPES.SINGLE,
    transformColumns: true,
    metrics: [
      'TransactionID',
      'HotelCode',
      'Arrival Date',
      'Departure Date',
      'Confirmation Date',
      'Status',
      'Channel',
      'Sub Channel',
      'Assigned Segment',
      'Market Segment',
      'Rate Category',
      'Sub Rate Category',
      'Region',
      'Country',
      'Total Nights',
      'Room Count for Date Range',
      'Revenue for Date Range',
      'ADR for Date Range',
    ],
  },
  Transactions_DeAgg: {
    groupBy: '',
    headertype: VISUAL_HEADER_TYPES.SINGLE,
    transformColumns: true,
    metrics: [
      'TransactionID',
      'HotelCode',
      'Arrival Date',
      'Departure Date',
      'Confirmation Date',
      'Stay Date',
      'Status',
      'Channel',
      'Sub Channel',
      'Assigned Segment',
      'Market Segment',
      'Rate Category',
      'Sub Rate Category',
      'Region',
      'Country',
      'Total Nights',
      'Room Count for Date Range',
      'Revenue for Date Range',
      'ADR for Date Range',
    ],
  },
};

export default mapper;
