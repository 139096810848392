import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'modules/common/components';
import { selectLoader } from 'modules/hotels/selectors';
import { selectAuthUser, selectOrganizationId } from 'modules/common/auth/selectors';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { withTheme } from '@emotion/react';
import TransactionWidget from 'modules/transactions/components/widget-pane';
import ORDER_BY from 'modules/common/constants/order-by';
import DashboardHeader from 'modules/common/powerbi/components/dashboard-header';
import {
  selectHotelName,
  selectLatestUpdatedHotelsList,
  selectTransactionDateType,
} from 'modules/transactions/selectors';
import { transactionActions } from 'modules/transactions/slice';
import { VISUAL_DATE_TYPES } from 'modules/transactions/constants';
import { format } from 'date-fns';
/**
 * Hotel view to add, edit and delete hotels
 * @param {*} param0
 * @returns
 */
const TransactionsView = ({ theme }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const selectedHotel = useSelector(selectHotelName);
  const loading = useSelector(selectLoader);
  const hotelList = useSelector(selectLatestUpdatedHotelsList);
  const authUser = useSelector(selectAuthUser);
  const transactionDateType = useSelector(selectTransactionDateType);
  //
  const [latestBookingDate, setLatestBookingDate] = useState(
    hotelList?.find((hotel) => hotel?.id === selectedHotel?.id)?.latestBookingDate
  );
  const [selectedDateType, setSelectedDateType] = useState(transactionDateType?.id);
  // Triggered when transactionDateType changes
  useEffect(() => {
    switch (transactionDateType?.id) {
      case VISUAL_DATE_TYPES.STAY:
        setSelectedDateType('Stay Dates');
        break;
      case VISUAL_DATE_TYPES.ARRIVAL:
        setSelectedDateType('Arrival Dates');
        break;
      case VISUAL_DATE_TYPES.DEPARTURE:
        setSelectedDateType('Dep. Dates');
        break;
      default:
        setSelectedDateType('Stay Dates');
        break;
    }
  }, [transactionDateType]);
  // Triggered when selectedHotel, hotelList value changes
  useEffect(() => {
    // Get breakdown data, set latest booking date and timezone of selected hotel
    if (selectedHotel && hotelList) {
      if (selectedHotel?.id === 'All') {
        setLatestBookingDate(format(Date.now(), 'yyyy-MM-dd HH:mm:ss'));
      } else {
        const earliestBookedHotel = hotelList
          ?.filter((hotel) =>
            [selectedHotel]?.some((selectedHotelItem) => selectedHotelItem?.id === hotel?.id)
          )
          .reduce((earliest, hotel) => {
            const selectedLatestBookingDate = hotel?.latestBookingDate;
            if (
              !earliest ||
              (selectedLatestBookingDate && selectedLatestBookingDate < earliest.latestBookingDate)
            ) {
              return hotel;
            }
            return earliest;
          }, null);
        setLatestBookingDate(earliestBookedHotel?.latestBookingDate);
      }
    }
  }, [selectedHotel, hotelList]);
  //
  useEffect(() => {
    if (authUser && organizationId) {
      let query = '';
      // Get hotel breakdown sub categories data
      if (selectedHotel?.id) {
        if (selectedHotel?.id === 'All') {
          // when all the hotels option is selected
          query = `limit=${PAGINATION.BREAKDOWN_DATA}`;
        } else {
          query = `hotelId=${selectedHotel?.id}&limit=${PAGINATION.BREAKDOWN_DATA}`;
        }
        dispatch(transactionActions.getBreakdown({ organizationId, query }));
      }
    }
  }, [organizationId, selectedHotel]);
  //
  useEffect(() => {
    const query = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&sortBy=name:${ORDER_BY.ASCENDING}`;
    dispatch(
      transactionActions.getVisuals({
        organizationId,
        query,
      })
    );
    dispatch(
      transactionActions.getVisualFilters({
        organizationId,
        query,
      })
    );
    const forceQuery = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&sortBy=name:${ORDER_BY.ASCENDING}&forceFetch=true`;
    dispatch(
      transactionActions.getAllHotelList({
        organizationId,
        query: forceQuery,
      })
    );
  }, [organizationId]);
  //
  return (
    <Loader loading={loading}>
      <Card>
        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
          <Grid container direction="row" justifyContent="space-between" pb={4}>
            <Typography variant="h3">Transactions</Typography>
          </Grid>
          <Grid container direction="row" pb={4}>
            <DashboardHeader adornment={selectedDateType} />
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item sx={{ flexGrow: 1, flexBasis: 1 }}>
              <TransactionWidget latestDate={latestBookingDate} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Loader>
  );
};
//
export default withTheme(TransactionsView);
