import { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Paper,
  ListSubheader,
  InputAdornment,
  TextField,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { isEmpty } from 'modules/common/helpers/object';
import { useDispatch } from 'react-redux';
import { Search } from 'react-feather';
import { hotelSetActions } from 'modules/hotel-set/slice';
import { generateQuery } from 'modules/hotel-set/functions';
import { FIELD, LABELS } from '../../constants';

/**
 * Transfer list implementation for custom aggregation
 * @param {Array} focusOnList - List of focus on dropdown values
 * @param {Array} groupList - Hotel group list  values
 * @param {Function} setGroupList - Function to set Hotel group list values
 * @param {Boolean} isQueryEnabled - To enable/disable advanced querying feature
 */
const AggregationTransferList = ({
  focusOnList,
  groupList,
  setGroupList,
  isQueryEnabled,
  temGroupList,
  setTemGroupList,
}) => {
  //
  const dispatch = useDispatch();
  const [detailedBreakdown, setDetailedBreakdown] = useState(focusOnList);
  const [checked, setChecked] = useState([]);
  // Helper functions for array operations
  const not = (array1, array2) =>
    array1?.filter((itemA) => !array2?.some((itemB) => itemB?.label === itemA?.label));
  const intersection = (array1, array2) => array1.filter((value) => array2.indexOf(value) !== -1);

  // Determine checked items in both lists
  const leftChecked = intersection(checked, groupList);
  const middleChecked = intersection(checked, detailedBreakdown);

  // Handle checkbox toggle
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  // Move selected items from detailed breakdown to group list
  const handleCheckedMiddleFromLeft = () => {
    dispatch(
      hotelSetActions.setHotelGroupQuery(generateQuery(FIELD[0], not(groupList, leftChecked)))
    );
    setDetailedBreakdown(detailedBreakdown.concat(leftChecked));
    setGroupList(not(groupList, leftChecked));
    setTemGroupList(not(temGroupList, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  // Move selected items from detailed breakdown to group list
  const handleCheckedLeft = () => {
    dispatch(
      hotelSetActions.setHotelGroupQuery(generateQuery(FIELD[0], groupList.concat(middleChecked)))
    );
    setGroupList(groupList.concat(middleChecked));
    setTemGroupList(temGroupList.concat(middleChecked));
    setDetailedBreakdown(not(detailedBreakdown, middleChecked));
    setChecked(not(checked, middleChecked));
  };

  // Update detailed breakdown when focusOnList or groupList changes
  useEffect(() => {
    if (isEmpty(groupList)) {
      setDetailedBreakdown(focusOnList);
    }
    if (groupList.length) {
      setDetailedBreakdown(not(focusOnList, groupList));
    }
  }, [focusOnList, groupList]);

  const filterHotelList = useCallback(
    (event) => {
      const idsToRemove = new Set(temGroupList.map((item) => item.id));
      const filteredArray = focusOnList.filter((item) => !idsToRemove.has(item.id));
      const { value } = event.target;
      if (!value) {
        setDetailedBreakdown(filteredArray);
        return;
      }
      const filtered = filteredArray.filter(
        (item) =>
          item.label.toLowerCase().includes(value.toLowerCase()) ||
          item.altName.toLowerCase().includes(value.toLowerCase())
      );
      setDetailedBreakdown(filtered);
    },
    [temGroupList, focusOnList]
  );
  const filterHotelSets = useCallback(
    (event) => {
      const { value } = event.target;
      if (!value) {
        setGroupList(temGroupList);
        return;
      }
      const filtered = temGroupList.filter(
        (item) =>
          item.label.toLowerCase().includes(value.toLowerCase()) ||
          item.altName.toLowerCase().includes(value.toLowerCase())
      );
      setGroupList(filtered);
    },
    [temGroupList]
  );

  // Custom list component
  const customList = (items, header) => {
    if (items?.length > 0) {
      const listItems = [...items];
      let sortArray = [];

      sortArray =
        !isEmpty(listItems) &&
        listItems?.sort((element1, element2) => element1?.label?.localeCompare(element2?.label));

      return (
        <Paper
          sx={{ maxWidth: 250, minWidth: 250, height: 350, overflow: 'auto' }}
          variant="outlined"
        >
          <List
            dense
            component="div"
            role="list"
            subheader={<ListSubheader component="div">{header}</ListSubheader>}
          >
            {!isEmpty(sortArray) &&
              sortArray?.map((value) => (
                <ListItem
                  key={value.id}
                  role="listitem"
                  onClick={handleToggle(value)}
                  sx={{ padding: 0 }}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': value?.id,
                      }}
                      disabled={isQueryEnabled}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={value?.id}
                    primary={value?.label}
                    sx={{ width: 200, fontStyle: value?.pmsDate ? 'inherit' : 'italic' }}
                  />
                </ListItem>
              ))}
          </List>
        </Paper>
      );
    }
    return (
      <Paper
        sx={{ maxWidth: 250, minWidth: 250, height: 350, overflow: 'auto' }}
        variant="outlined"
      />
    );
  };

  // Return the JSX for the component
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" wrap="nowrap">
      <Grid item>
        <TextField
          placeholder="Search"
          fullWidth
          sx={{ mb: 2 }}
          size="small"
          onChange={(event) => filterHotelList(event)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search size={16} />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
        {customList(detailedBreakdown, 'Hotels')}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={middleChecked?.length === 0 || isQueryEnabled}
            aria-label="move selected left"
          >
            <KeyboardArrowRightIcon fontSize="small" />
          </Button>
          <br />
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedMiddleFromLeft}
            disabled={leftChecked?.length === 0 || isQueryEnabled}
            aria-label="move selected left"
          >
            <KeyboardArrowLeftIcon fontSize="small" />
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          placeholder="Search"
          fullWidth
          size="small"
          sx={{ mb: 2 }}
          onChange={(event) => filterHotelSets(event)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search size={16} />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
        {customList(groupList, LABELS.GROUP_HOTEL)}
      </Grid>
    </Grid>
  );
};
//
export default AggregationTransferList;
