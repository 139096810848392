import {
  TAGS,
  TRANSACTION_SEGMENT_FILTER,
  TRANSACTION_DATES_FILTER,
  VISUAL_DATE_TYPES,
} from 'modules/transactions/constants';
import {
  allFilter,
  dateFilter,
  getSelectedBreakdown,
  nameFilter,
} from 'modules/transactions/functions';
import { capitalizeFirst } from '../../../common/helpers/strings';
/**
 * To generate power bi filter schema for stay/arrival/departure date
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Date} startDate - Start date of stay/arrival/departure date
 * @param {Date} endDate - End date of  stay/arrival/departure date
 * @returns
 */
export const getTargetDateSchema = (visualFilters, type, startDate, endDate) => {
  let targetVisualFilter = {};
  let targetDateFilter;
  switch (type) {
    case VISUAL_DATE_TYPES.STAY:
      targetVisualFilter = visualFilters?.find((filter) =>
        filter?.tags?.includes(TAGS.TARGET_DATE)
      );
      if (targetVisualFilter) {
        targetDateFilter = dateFilter(startDate, endDate, targetVisualFilter?.tableName);
      }
      break;
    case VISUAL_DATE_TYPES.ARRIVAL:
      targetDateFilter = dateFilter(
        startDate,
        endDate,
        TRANSACTION_SEGMENT_FILTER.TABLE_NAME,
        TRANSACTION_DATES_FILTER.ARRIVAL_DATE
      );
      break;
    case VISUAL_DATE_TYPES.DEPARTURE:
      targetDateFilter = dateFilter(
        startDate,
        endDate,
        TRANSACTION_SEGMENT_FILTER.TABLE_NAME,
        TRANSACTION_DATES_FILTER.DEPARTURE_DATE
      );
      break;
    default:
      break;
  }
  return targetDateFilter;
};

/**
 * To generate power bi filter schema for hotel id
 * @param {Array} visualFilters -  List of registered visual filters
 * @param {String} hotelId - Selected hotel id
 * @returns
 */
export const getHotelIdSchema = (hotelId, visualFilters) => {
  const hotelVisualFilter = visualFilters?.find((filter) => filter?.tags?.includes(TAGS.HOTEL_ID));
  let hotelIdFilter;
  if (hotelVisualFilter) {
    if (hotelId !== 'All') {
      hotelIdFilter = nameFilter(
        hotelVisualFilter?.tableName,
        hotelVisualFilter?.columnName,
        Array.isArray(hotelId) ? hotelId : [hotelId]
      );
    } else {
      hotelIdFilter = allFilter(hotelVisualFilter?.tableName, hotelVisualFilter?.columnName);
    }
  }
  return hotelIdFilter;
};
/**
 * To generate power bi filter schema for booking date
 * @param {Array} visualFilters - List of registered visual filters
 * @param {Date} startDate - Start date of booking date
 * @param {Date} endDate - End date of booking date
 * @returns
 */
export const getBookingDateSchema = (visualFilters, startDate, endDate) => {
  const bookingVisual = visualFilters?.filter((filter) =>
    filter?.tags?.includes(TAGS.BOOKING_DATE)
  );
  let bookingDateFilter;
  if (bookingVisual?.length) {
    bookingDateFilter = dateFilter(startDate, endDate, bookingVisual[0]?.tableName);
  }
  return bookingDateFilter;
};

/**
 * Get selected focus on filter
 * @param {Array} breakdownData - list of breakdown data
 * @param {String} selectedBreakdown - selected breakdown vlaue
 * @returns
 */
export const getFocusOnFilter = (breakdownData, selectedBreakdown) => {
  const focusOnFilter = [];
  breakdownData
    ?.filter(
      (filter) => capitalizeFirst(filter?.breakdownType) === capitalizeFirst(selectedBreakdown)
    )
    .forEach((breakdown) =>
      breakdown?.subCategory?.forEach((data) =>
        focusOnFilter.push({
          label: data,
          id: focusOnFilter.length,
        })
      )
    );
  const focusOnFilterValues = focusOnFilter.filter(
    (obj, index, array) => array.findIndex((o) => o.label === obj.label) === index
  );
  //
  return focusOnFilterValues;
};
/**
 * Generate power bi filter schema for breakdown filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} chartBreakdown - Selected chart breakdown id
 * @param {Boolean} isDetailed - chart breakdown details toggle state
 * @returns
 */
export const getChartBreakdownFilterList = (visualFilters, chartBreakdown, isDetailed) => {
  const selectedBreakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
  const summaryChartVisual = visualFilters?.find((filter) =>
    filter?.tags?.includes(TAGS.SUMMARY_SEGMENT_BREAKDOWN)
  );
  let chartBreakdownFilter;
  if (summaryChartVisual && selectedBreakdown) {
    chartBreakdownFilter = nameFilter(
      summaryChartVisual?.tableName,
      summaryChartVisual?.columnName,
      [selectedBreakdown]
    );
  }
  return chartBreakdownFilter;
};

/**
 * Get segment filter based on selected breakdown
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} timeFrame - time frame value
 * @returns
 */
export const getSegmentFocusOnSchema = (selectedBreakdown, focusOn) => {
  let column;
  switch (selectedBreakdown) {
    case 'Channel':
      column = TRANSACTION_SEGMENT_FILTER.CHANNEL;
      break;
    case 'Channel (raw)':
      column = TRANSACTION_SEGMENT_FILTER.CHANNEL_RAW;
      break;
    case 'Market Segment':
      column = TRANSACTION_SEGMENT_FILTER.MARKET_SEGMENT;
      break;
    case 'Market Segment (raw)':
      column = TRANSACTION_SEGMENT_FILTER.MARKET_SEGMENT_RAW;
      break;
    case 'Rate Category':
      column = TRANSACTION_SEGMENT_FILTER.RATE_CATEGORY;
      break;
    case 'Rate Category (raw)':
      column = TRANSACTION_SEGMENT_FILTER.RATE_CATEGORY_RAW;
      break;
    case 'Region':
      column = TRANSACTION_SEGMENT_FILTER.REGION;
      break;
    case 'Region (raw)':
      column = TRANSACTION_SEGMENT_FILTER.REGION_RAW;
      break;
    default:
      break;
  }
  return focusOn?.length !== 0
    ? nameFilter(TRANSACTION_SEGMENT_FILTER.TABLE_NAME, column, focusOn)
    : allFilter(TRANSACTION_SEGMENT_FILTER.TABLE_NAME, column);
};
/**
 * Get selected sub breakdown filter
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} tag - tag
 * @returns
 */
export const getSubBreakdownFilter = (visualFilters, tag = null) => {
  let subBreakdownFilter;
  if (tag !== null) {
    subBreakdownFilter = visualFilters
      ?.filter((filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN && tag))
      .map((data) => ({
        id: data?.id,
        label: data?.values[0]?.label,
      }));
  } else {
    subBreakdownFilter = visualFilters
      ?.filter((filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN))
      .map((data) => ({
        label: data?.values[0]?.label,
        id: data?.id,
      }));
  }
  //
  return subBreakdownFilter;
};
