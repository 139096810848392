import styled from '@emotion/styled';
import { Avatar, Paper } from '@mui/material';

export const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
//
export const BigAvatar = styled(Avatar)`
  margin: 15px;
  width: 200px;
  height: 70px;
`;
