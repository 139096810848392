import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
} from '@mui/material';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { selectNotification } from 'modules/common/notifications/selectors';
import {
  selectHotelsSelectionList,
  selectMinorCategoryById,
} from 'modules/event-configuration/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { notificationActions } from 'modules/common/notifications/slice';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import { configureEventCategoriesActions } from 'modules/event-configuration/slice';
import { addMinorCategoryValidation } from '../../validation/add-event-category-form-validation';
import { Alert, TextField } from './style';
/**
 * Modal component to edit a minor event category
 */
const EditMinorCategoryDialog = ({ open, onClose, majorEventCategoryId, minorCategoryId }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const hotelList = useSelector(selectHotelsSelectionList);
  const minorCategory = useSelector((state) => selectMinorCategoryById(state, minorCategoryId));
  //
  const modalAction = (action) => {
    if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
    onClose(action);
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      modalAction(false);
    }
  }, [notification]);
  //
  const editableValues = {
    minorName: minorCategory?.name,
    hotels: minorCategory?.applicableHotels
      ? minorCategory?.applicableHotels?.map((category) => ({
          id: category,
          label: hotelList.filter((a) => a.id === category).map((b) => b.label)[0],
        }))
      : [],
  };
  const onSubmitAddMinorEvent = async (values) => {
    dispatch(
      configureEventCategoriesActions.updateMinorEventCategories({
        organizationId,
        majorEventCategoryId,
        minor_events: [
          {
            id: minorCategory?.id,
            name: values?.minorName,
            applicableHotels: values?.hotels ? values?.hotels.map((h) => h.id) : [],
          },
        ],
      })
    );
    modalAction(false);
  };
  //
  return (
    <Dialog open={open} onClose={() => modalAction(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Minor Category</DialogTitle>
      <DialogContent sx={{ minWidth: 400, maxWidth: 400 }}>
        <Formik
          initialValues={editableValues}
          validationSchema={addMinorCategoryValidation}
          onSubmit={onSubmitAddMinorEvent}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <TextField
                name="minorName"
                label="Minor category name"
                value={values.minorName}
                error={Boolean(touched.minorName && errors.minorName)}
                helperText={touched.minorName && errors.minorName}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
              />
              <Autocomplete
                multiple
                disableClearable
                name="hotels"
                limitTags={2}
                componentsProps={{
                  paper: {
                    sx: {
                      width: '90%',
                      marginLeft: '10%',
                    },
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: 0,
                    height: 'fit-content',
                  },
                  marginY: 2,
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={hotelList || []}
                filterSelectedOptions
                onChange={(e, value) => setFieldValue('hotels', value)}
                onBlur={handleBlur}
                value={values.hotels}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    label="Hotels"
                    size="medium"
                    error={Boolean(touched.hotels && errors.hotels)}
                    helperText={touched.hotels && errors.hotels}
                    {...params}
                  />
                )}
              />
              <DialogActions sx={{ justifyContent: 'space-between', px: 0 }}>
                <Button
                  sx={{ px: 10 }}
                  type="submit"
                  variant="contained"
                  color="success"
                  disabled={isSubmitting}
                >
                  Update
                </Button>
                <Button
                  sx={{ px: 10 }}
                  onClick={() => modalAction(false)}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
//
export default EditMinorCategoryDialog;
