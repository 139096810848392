/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';
/**
 * Add visual filter form validation rules are mentioned here
 */
export const addVisualFilterValidation = yup.object().shape({
  type: yup.string().nullable().required('Type is a required field'),
  tableName: yup
    .string()
    .trim()
    .required('Table Name is a required field')
    .matches(/^\w+( +\w+)*$/, 'Table Name cannot contain special characters'),
  columnName: yup.string().required('Column Name is a required field'),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
  isDefault: yup.boolean().required('Default is a required field'),
  tags: yup.array().required().min(1, 'At least one tag is required'),
  values: yup.mixed().when('type', (type) => {
    if (type === 'ALL' || type === 'DATE') {
      return yup.mixed().nullable();
    }
    if (type === 'NAME' || type === '') {
      return yup.mixed().required('Values is a required field');
    }
    return yup.array().of(
      yup.object().shape({
        label: yup.string().required('Label is required'),
        value: yup.mixed().required('Value is a required field'),
        isDefault: yup.boolean().nullable(),
        columnName: yup.string().required('Column Name is a required field'),
      })
    );
  }),
});
/**
 * Update visual filter form validation rules are mentioned here
 */
export const updateVisualFilterValidation = yup.object().shape({
  type: yup.string().nullable().required('Type is a required field'),
  tableName: yup
    .string()
    .trim()
    .required('Table Name is a required field')
    .matches(/^\w+( +\w+)*$/, 'Table Name cannot contain special characters'),
  columnName: yup.string().required('Column Name is a required field'),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
  isDefault: yup.boolean().required('Default is a required field'),
  tags: yup.array().min(1, 'At least one tag is required'),
  values: yup.mixed().when('type', (type) => {
    if (type === 'ALL' || type === 'DATE') {
      return yup.mixed().nullable();
    }
    if (type === 'NAME') {
      return yup.mixed().required('Values is a required field');
    }
    return yup.array().of(
      yup.object().shape({
        label: yup.string().required('Label is required'),
        value: yup.mixed().required('Value is a required field'),
        isDefault: yup.boolean().nullable(),
        columnName: yup.string().required('Column Name is a required field'),
      })
    );
  }),
});
