import { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { isEmpty } from 'modules/common/helpers/object';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { notificationActions } from 'modules/common/notifications/slice';
import { Modal } from 'modules/common/components';
import ERROR_TYPES from 'modules/common/constants/error-types';
import {
  selectExistedHotelGroup,
  selectHotelGroupName,
  selectHotelGroupQuery,
  selectIsConfigurationNotExist,
} from 'modules/hotel-set/selectors';
import { hotelSetActions } from 'modules/hotel-set/slice';
import addFilterFormValidation from 'modules/hotel-set/validation/add-filter-form-validation';
import { Alert, TextField } from 'modules/hotel-set/style';

/**
 * used to inject formik context in-order to control form field from outside of formik context
 * @param {String} selectedFilterName - Name of the loaded hotel group
 * @constructor
 */
const FormikContextInjector = ({ selectedFilterName }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  useEffect(() => {
    if (selectedFilterName) {
      setFieldValue('filterName', selectedFilterName);
      setFieldTouched('filterName', false);
    } else {
      setFieldValue('filterName', '');
      setFieldTouched('filterName', false);
    }
  }, [selectedFilterName]);
};

/** Implementation to save hotel group
 * @param {Boolean} open - To open/ close dialog box
 * @param {Function} onClose - Function to trigger on close event
 * @returns
 */
const SaveFilterDialog = ({ groupList, onClose, setGroupList, setTemGroupList }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const selectedHotelName = useSelector(selectHotelGroupName);
  const existedFilter = useSelector(selectExistedHotelGroup);
  const isConfigurationNotExist = useSelector(selectIsConfigurationNotExist);
  const hotelQuery = useSelector(selectHotelGroupQuery);
  //
  const [openOverwriteModal, setOpenOverwriteModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [saveValueObj, setSaveValueObj] = useState(null);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      dispatch(notificationActions.resetNotification());
      setTemGroupList([]);
      setGroupList([]);
      dispatch(hotelSetActions.setHotelGroupQuery(''));
      dispatch(hotelSetActions.setSelectedHotelGroupName(''));
      onClose();
    }
  }, [notification]);
  // create save filter object and dispatch validate filter name
  const createFilterAndValidateFilterName = async (values) => {
    dispatch(hotelSetActions.resetHotelGroupNameValidationStatus());
    const valueObj = {
      organizationId,
      hotelGroups: [
        {
          hotelGroupName: values?.filterName.trim(),
          hotelQuery,
        },
      ],
    };
    //
    setSaveValueObj(valueObj);

    const query = `hotelGroupName=${values.filterName.trim()}&searchEnabled=false`;
    dispatch(
      hotelSetActions.getHotelGroupNameValidation({
        organizationId,
        query,
      })
    );
  };
  // dispatch save hotel group , reset save object, filter validation state and close update warning modal
  const saveSubmit = (filterObject) => {
    setOpenOverwriteModal(false);
    dispatch(hotelSetActions.createHotelGroup(filterObject));
    setSaveValueObj(null);
    dispatch(hotelSetActions.resetHotelGroupNameValidationStatus());
    dispatch(hotelSetActions.resetConfigurationStatus());
    onClose();
  };
  // delete selected hotel set
  const deleteFilters = (filterId) => {
    const query = `hotel_group_name=${filterId}`;
    dispatch(
      hotelSetActions.deleteHotelGroup({
        organizationId,
        query,
      })
    );
  };
  // checks hotel group name already exists and open update warning modal
  useEffect(() => {
    if (saveValueObj !== null && existedFilter !== null && !isConfigurationNotExist) {
      if (existedFilter) {
        // check whether existing hotel set is modified or not
        const isHotelSetNotModified = existedFilter?.hotelGroups?.find(
          (group) =>
            group?.hotelGroupName === saveValueObj?.hotelGroups?.[0]?.hotelGroupName &&
            group?.hotelQuery === saveValueObj?.hotelGroups?.[0]?.hotelQuery
        );
        if (!isHotelSetNotModified) {
          setOpenOverwriteModal(true);
        } else {
          onClose();
        }
      } else {
        saveSubmit(saveValueObj);
      }
    }
    // if user configuration is not exist, save the hotel group
    if (isConfigurationNotExist && saveValueObj !== null && existedFilter === null) {
      saveSubmit(saveValueObj);
    }
  }, [saveValueObj, existedFilter, isConfigurationNotExist]);
  //
  useEffect(() => {
    dispatch(notificationActions.resetNotification());
  }, []);
  //
  return (
    <Formik
      initialValues={{
        filterName: selectedHotelName ?? '',
      }}
      validationSchema={addFilterFormValidation}
      onSubmit={(values) => createFilterAndValidateFilterName(values)}
    >
      {({ errors, handleBlur, setFieldValue, handleSubmit, values, touched, isSubmitting }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item paddingRight={1}>
              <TextField
                name="filterName"
                value={values.filterName}
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched.filterName && errors.filterName)}
                fullWidth
                onBlur={handleBlur}
                onChange={(event) => {
                  setFieldValue('filterName', event.target.value);
                  dispatch(hotelSetActions.setSelectedHotelGroupName(event.target.value));
                }}
                label="Hotel Set Name"
                size="small"
              />
              <span>
                <Typography
                  sx={{ whiteSpace: 'pre-line', fontSize: 11, color: 'red', minHeight: 15 }}
                >
                  {(touched.filterName && errors.filterName) ?? ' '}
                </Typography>
              </span>
            </Grid>

            <Grid item container flexDirection="row" xs={8} justifyContent="flex-end">
              <Grid item paddingRight={1}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenDeleteModal(true);
                  }}
                  disabled={!selectedHotelName}
                  sx={{ maxWidth: 50 }}
                  variant="contained"
                >
                  Delete
                </Button>
              </Grid>
              <Grid item paddingRight={1}>
                <Button
                  onClick={() => {
                    setGroupList([]);
                    setTemGroupList([]);
                    dispatch(hotelSetActions.setHotelGroupQuery(''));
                    dispatch(hotelSetActions.setSelectedHotelGroupName(''));
                    onClose();
                  }}
                  sx={{ maxWidth: 50 }}
                  variant="contained"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  onClick={() => {}}
                  disabled={isSubmitting || isEmpty(groupList)}
                >
                  Save
                </Button>
              </Grid>
            </Grid>

            <FormikContextInjector selectedFilterName={selectedHotelName} />
          </Grid>
          <Grid>
            {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
              <Alert severity={notification?.type}>{notification?.message}</Alert>
            )}
          </Grid>
          <Modal
            open={openOverwriteModal}
            handleClose={() => {
              dispatch(hotelSetActions.resetHotelGroupNameValidationStatus());
              setOpenOverwriteModal(false);
            }}
            title="Update Hotel Set"
            content="Hotel Set Name already exists. Are you sure you want to overwrite the hotel set?"
            handleSuccess={() => saveSubmit(saveValueObj)}
            closeLabel="Cancel"
            successLabel="Update"
            variant="contained"
            color="error"
          />
          <Modal
            open={openDeleteModal}
            handleClose={() => setOpenDeleteModal(false)}
            title="Delete Hotel Set"
            content="Are you sure you want to delete the hotel set?"
            handleSuccess={() => {
              deleteFilters(selectedHotelName);
              onClose();
            }}
            closeLabel="Cancel"
            successLabel="Delete"
            variant="contained"
            color="error"
          />
        </form>
      )}
    </Formik>
  );
};
//
export default SaveFilterDialog;
